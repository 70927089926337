import {Component, Input, OnInit} from '@angular/core';
import {AlertDetailsGQL, AlertDetailsQuery} from '../../../generated/graphql';

@Component({
  selector: 'app-alert-details',
  templateUrl: './alert-details.component.html',
  styleUrls: ['./alert-details.component.scss']
})
export class AlertDetailsComponent implements OnInit {


  audio: HTMLAudioElement;

  playing = false;


  @Input()
  alert: AlertDetailsQuery['alert'];


  constructor(private service: AlertDetailsGQL) {
  }

  ngOnInit(): void {


    this.service.fetch({id: this.alert.id}).subscribe(s => this.alert = s.data.alert);


  }




}
