<mat-toolbar class="mat-elevation-z4">

  <span fxFlex>
    Perfiles
  </span>



  <span>
    <button mat-icon-button (click)="new()">
      <mat-icon svgIcon="plus"></mat-icon>
    </button>

  </span>


</mat-toolbar>


<p-table
  responsiveLayout="scroll"
  [value]="profiles.content" #table
  [lazy]="true"
  [totalRecords]="profiles.totalElements"
  (onLazyLoad)="onLazyEvent($event)"
  rows="20"
  paginator="true"
  tableStyleClass="profiles mat-elevation-z4"
  sortField="description"
  [sortOrder]="1"
>


  <ng-template pTemplate="header">

    <tr>

      <th pSortableColumn="id">
        <p-sortIcon field="id">
        </p-sortIcon>
        ID
      </th>
      <th pSortableColumn="description">
        <p-sortIcon field="description"></p-sortIcon>
        Nombre
      </th>


      <th style="width: 70px"></th>
    </tr>


  </ng-template>


  <ng-template pTemplate="body" let-profile>


    <tr>

      <td class="sort-padding">{{ profile.id }}</td>

      <td class="sort-padding">{{profile.description}}</td>


      <td>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon svgIcon="dots-vertical"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="edit(profile.id)">
            <mat-icon svgIcon="pencil"></mat-icon>
            <span>Editar</span>
          </button>
        </mat-menu>
      </td>
    </tr>
  </ng-template>


</p-table>
