<p-table responsiveLayout="scroll" [value]="events"  tableStyleClass="transparent-table size-s">

  <ng-template pTemplate="header">

    <tr>

      <th style="width: 60px">
        Estado
      </th>

      <th style="width: 60px">
        Tipo
      </th>


      <th>
        Abogado/a
      </th>

      <th>
        Fecha
      </th>

      <th>
        Duración
      </th>


      <th>
        Teléfono
      </th>

      <th></th>
      <th></th>

    </tr>

  </ng-template>


  <ng-template pTemplate="body" let-event>

    <tr [ngClass]="{'substitute': event?.callType == 'SUBSTITUTE'}">
      <td>


        <app-call-state [event]="event"></app-call-state>


      </td>

      <td>
        <mat-icon [svgIcon]="callTypeIcon(event)" [matTooltip]="callTypeText(event)"></mat-icon>
      </td>

      <td>

        {{ event.lawyer.name }} {{ event.lawyer.lastName }}
      </td>


      <td>

        {{ event.date | date:'dd/MM/yy HH:mm' }}

      </td>

      <td>
        <ng-container *ngIf="event.duration">
          {{ event.duration }}s
        </ng-container>
      </td>

      <td>
        {{ event.phone | phone }}
      </td>

      <td>
        <app-play-audio [file]="event.audio" [name]=""></app-play-audio>
      </td>

      <td>

        <button *ngIf="notifyNotAnswered(event)" mat-icon-button matTooltip="Recordad justificar" (click)="sendNotificationNotAnswered(event)">
          <mat-icon svgIcon="email-alert"></mat-icon>
        </button>

      </td>


    </tr>



  </ng-template>

</p-table>
