import {Component, Inject} from '@angular/core';
import {GraphQLError} from "graphql";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-popup-error',
  templateUrl: './popup-error.component.html',
  styleUrls: ['./popup-error.component.scss']
})
export class PopupErrorComponent {


  title = '';
  errors: readonly GraphQLError[];


  constructor(
    @Inject(MAT_DIALOG_DATA) data
  ) {

    this.title = data.title;
    this.errors = data.errors;

  }


}
