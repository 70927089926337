import {Injectable} from '@angular/core';
import {UiConfig, UiConfigGQL} from "../../../generated/graphql";
import {Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  data: UiConfig = {
    updateLawyers: false,
    importWatches: false,
    importLawyers: false,
    importList: false,
    logo: '',
    title: 'zAbogados',
    showWatchesOrder: false,
    demoMode: false,
  };

  constructor(
    private uiService: UiConfigGQL,
    private titleService: Title,
  ) {

    this.uiService.fetch().subscribe(s =>{
      this.data = s.data.uiConfig;
      this.titleService.setTitle(this.data.title);
    });
  }








}
