import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): Array<string> {

    if (!value) return [];

    return value.split(",").map(s => s.trim());
  }

}
