import {Pipe, PipeTransform} from '@angular/core';
import {Alert, AlertStatus} from "../../../generated/graphql";

@Pipe({
  name: 'alert'
})
export class AlertPipe implements PipeTransform {

  transform(value: Alert, ...args: string[]): string {


    const type = args[0];

    switch (type) {
      case 'status-icon':

        if (Date.parse(`${value.start}`) > Date.now())
          return 'clipboard-clock';

        switch (value.status) {
          case AlertStatus.Resolved:
            return 'clipboard-check';
          case AlertStatus.OnGoing:
            return 'clipboard-pulse';
          case AlertStatus.Incomplete:
            return 'clipboard-alert';
          case AlertStatus.Cancelled:
            return 'clipboard-minus';
          case AlertStatus.Interrupted:
            return 'clipboard-remove';
          case AlertStatus.Enqueued:
            return 'clock-outline';
        }

        break;
      case 'status-class':
        switch (value.status) {
          case AlertStatus.Resolved:
            return 'success';
          case AlertStatus.OnGoing:
            return 'info';
          case AlertStatus.Incomplete:
            return 'warning';
          case AlertStatus.Cancelled:
            return 'danger';
          case AlertStatus.Interrupted:
            return 'danger';
          case AlertStatus.Enqueued:
            return 'info';
        }
        break;
      case 'status-text':
        switch (value.status) {
          case AlertStatus.Resolved:
            return 'Resuelta';
          case AlertStatus.OnGoing:
            return 'En curso';
          case AlertStatus.Incomplete:
            return 'Incompleta';
          case AlertStatus.Cancelled:
            return 'Cancelada';
          case AlertStatus.Interrupted:
            return 'Interrumpida';
          case AlertStatus.Enqueued:
            return 'En cola';
        }
        break;
      case 'status-color':
        switch (status) {
          case AlertStatus.Resolved:
            return '#339a1e';
          case AlertStatus.OnGoing:
            return '#3389c6';
          case AlertStatus.Incomplete:
            return '#ec8809';
          case AlertStatus.Cancelled:
            return '#c7d006';
          case AlertStatus.Interrupted:
            return '#dc1212';
          case AlertStatus.Enqueued:
            return '#3389c6';

        }
    }

    return '';
  }
}
