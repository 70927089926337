<h2 mat-dialog-title>Nuevo aviso</h2>


<mat-dialog-content>


  <form #form="ngForm" autocomplete="off" #formElement>

    <div class="p-grid p-fluid">


        <div class="p-md-4 p-float-label">
          <p-dropdown [autoDisplayFirst]="false" [options]="judicialDistricts" optionValue="id" optionLabel="name" required
                      (onChange)="getAlertsType($event.value); updateAudio()"></p-dropdown>
          <label>Partido judicial</label>
        </div>


        <div class="p-md-4 p-float-label">
          <p-dropdown name="menu" required ngModel [autoDisplayFirst]="false" [options]="alertsType" optionLabel="name" optionValue="id" (onChange)="updateAlertType($event)"></p-dropdown>
          <label>Materia</label>
        </div>



      <div class="p-md-4 p-float-label">
        <input pInputText name="diligence" [(ngModel)]="diligence" (keyup)="updateAudio()" required>
        <label>Número atestado</label>
      </div>

      <div class="p-md-4 p-float-label">
        <p-dropdown [options]="dates" name="date" [(ngModel)]="date" optionValue="value" optionLabel="label" required (onChange)="updateAudio()"></p-dropdown>

        <label>Fecha</label>
      </div>

      <div class="p-md-4 p-float-label">
        <p-calendar timeOnly="true" hourFormat="24" name="hour" [(ngModel)]="hour" (ngModelChange)="updateAudio()" required dataType="string" ></p-calendar>
        <label>Hora aproximada</label>
      </div>


      <div class="p-md-4 p-float-label">
        <p-inputNumber name="detained" [(ngModel)]="persons" (ngModelChange)="updateAudio()" [min]="1" [max]="100" [disabled]="!isRaid" [readonly]="!isRaid"></p-inputNumber>
        <label>Personas a asistir</label>
      </div>



      <div class="p-md-12 p-float-label">
          <textarea pInputTextarea rows="5" name="audio" [ngModel]="audio">
          </textarea>

        <label>Mensaje</label>
      </div>


    </div>

  </form>


  <app-graphql-errors [form]="formElement" [errors]="errors"></app-graphql-errors>


</mat-dialog-content>

<mat-dialog-actions>

  <ng-container *ngIf="!urgent">
    <button mat-raised-button color="primary" [disabled]="form.invalid || audio.length == 0" (click)="save(form.value, true)" [loading]="loading">
      Crear aviso
    </button>

    <button mat-dialog-close mat-button>Cerrar</button>

  </ng-container>

  <ng-container *ngIf="urgent">
    <button mat-raised-button color="primary" [disabled]="form.invalid || audio.length == 0" (click)="save(form.value, false)" [loading]="loading" matTooltip="Esperar a mañana">
      Crear aviso
    </button>

    <button mat-raised-button color="accent" [disabled]="form.invalid || audio.length == 0" (click)="save(form.value, false)" [loading]="loading" matTooltip="Empezar a llamar ahora">
      Crear aviso urgente
    </button>
    <button mat-dialog-close mat-button>Cerrar</button>

  </ng-container>



</mat-dialog-actions>
