<h2 mat-dialog-title>Guardia</h2>

<mat-dialog-content>

  <form #form="ngForm" autocomplete="off" #formElement>
    <div class="p-grid p-fluid">

      <div class="p-md-12 p-float-label">
        <p-dropdown optionValue="id"
                    [autoDisplayFirst]="false"
                    [options]="lawyers"
                    optionLabel="fullName"
                    [filter]="true"
                    appendTo="body"
                    virtualScroll="true" itemSize="30"
                    name="lawyer"
                    ngModel
                    required></p-dropdown>
        <label>Abogado/a</label>
      </div>

      <div class="p-md-6 p-float-label">
        <p-calendar name="start" [ngModel]="start" dataType="date" [showTime]="true" dateFormat="dd/mm/yy" appendTo="body" required>
        </p-calendar>
        <label>Inicio</label>
      </div>

      <div class="p-md-6 p-float-label">
        <p-calendar name="end" [ngModel]="end" dataType="date" [showTime]="true" dateFormat="dd/mm/yy" appendTo="body" required >
        </p-calendar>
        <label>Fin</label>
      </div>

      <div class="p-md-6 p-float-label">
        <p-dropdown optionValue="id"
                    [autoDisplayFirst]="false"
                    [options]="judicialDistricts"
                    optionLabel="name"
                    [filter]="true"
                    name="judicialDistrict"
                    ngModel
                    appendTo="body"
                    required></p-dropdown>
        <label>Partido Judicial</label>
      </div>

      <div class="p-md-6 p-float-label">

        <p-dropdown optionValue="id"
                    [autoDisplayFirst]="false"
                    [options]="alertType"
                    optionLabel="name"
                    [filter]="true"
                    name="alertType"
                    ngModel
                    appendTo="body"
                    required></p-dropdown>
        <label>Materia</label>

      </div>


      <app-graphql-errors [errors]="errors" [form]="formElement"></app-graphql-errors>
    </div>
  </form>


  <mat-dialog-actions>


    <button mat-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="save(form.value)">
      Guardar
    </button>

  </mat-dialog-actions>

</mat-dialog-content>

