<h2 mat-dialog-title>Configuración</h2>


<mat-dialog-content>


  <form #form="ngForm" autocomplete="off" #formElement>

    <div class="p-grid p-fluid">


      <div class="p-md-6 p-float-label">
        <input pInputText name="name" [ngModel]="setting.name" required>
        <label>Nombre</label>
      </div>


      <div class="p-md-6 p-float-label">
        <input pInputText type="text" name="category" [ngModel]="setting.category" required>
        <label>Categoría</label>
      </div>


      <div class="p-md-6 p-float-label">
        <input pInputText type="text" name="group" [ngModel]="setting.group" required>
        <label>Grupo</label>
      </div>

      <div class="p-md-6 p-float-label">
        <p-inputNumber type="text" name="position"
                       [ngModel]="setting.position" required [min]="0"
                       [maxFractionDigits]="0"></p-inputNumber>
        <label>Posición</label>
      </div>


      <div class="p-md-6 p-float-label">
        <input pInputText type="text" name="serverKey" [ngModel]="setting.serverKey">
        <label>Código para el zAbogados Server</label>
      </div>

      <div class="p-md-6 p-float-label">
        <input pInputText type="text" name="webKey" [ngModel]="setting.webKey">
        <label>Código para el zAbogados Web</label>
      </div>


      <div class="p-md-6 p-float-label">
        <p-dropdown #type [options]="types"  [ngModel]="setting.type" optionLabel="label" optionValue="value" required name="type"></p-dropdown>
        <label>Tipo</label>
      </div>


      <ng-container *ngIf="type.value == 'STRING'">

        <div class="p-md-6 p-float-label">
          <input pInputText type="text" name="default" [ngModel]="setting.default" [pattern]="pattern.value">
          <label>Valor por defecto</label>
        </div>


        <div class="p-md-12 p-float-label" ngModelGroup="options">
          <input pInputText type="text" name="pattern" #pattern [(ngModel)]="setting.options.pattern">
          <label>Patrón a cumplir (regex)</label>
        </div>

      </ng-container>



      <ng-container *ngIf="type.value == 'SELECT'">

        <div class="p-md-6 p-float-label">
          <p-dropdown name="default"  [ngModel]="setting.default" [options]="list.value"></p-dropdown>
          <label>Valor por defecto</label>
        </div>


        <div class="p-md-12 p-float-label" ngModelGroup="options">
          <p-chips [(ngModel)]="setting.options.list" name="list" #list></p-chips>
          <label>Opciones disponibles</label>
        </div>

      </ng-container>



      <ng-container *ngIf="type.value == 'NUMERIC'">

        <div class="p-md-6 p-float-label">
          <p-inputNumber name="default"  [ngModel]="setting.default" required [min]="min.value" [max]="max.value" [maxFractionDigits]="decimals.value"></p-inputNumber>
          <label>Valor por defecto</label>
        </div>


        <div class="p-md-4 p-float-label" ngModelGroup="options">
          <p-inputNumber [(ngModel)]="setting.options.min" #min name="min" [maxFractionDigits]="decimals.value"></p-inputNumber>
          <label>Valor mínimo</label>
        </div>

        <div class="p-md-4 p-float-label" ngModelGroup="options">
          <p-inputNumber [(ngModel)]="setting.options.max" #max name="max" [maxFractionDigits]="decimals.value"></p-inputNumber>
          <label>Valor máximo</label>
        </div>

        <div class="p-md-4 p-float-label" ngModelGroup="options">
          <p-inputNumber [(ngModel)]="setting.options.decimals" #decimals name="decimals"></p-inputNumber>
          <label>Número máximo de decimales</label>
        </div>

      </ng-container>


      <ng-container *ngIf="type.value == 'BOOLEAN'">

        <div class="p-md-6 p-float-label">
          <p-inputSwitch name="default" [ngModel]="setting.default"></p-inputSwitch>
          <label>Valor por defecto</label>
        </div>

        <div ngModelGroup="options">
          <input type="hidden" ngModel name="pattern" >
        </div>

      </ng-container>



      <ng-container *ngIf="type.value == 'TEXTAREA'">

        <div class="p-md-12 p-float-label">
          <textarea pInputTextarea name="default" [ngModel]="setting.default" rows="20"></textarea>
          <label>Valor por defecto</label>
        </div>

        <div ngModelGroup="options">
          <input type="hidden" ngModel name="pattern" >
        </div>

      </ng-container>


      <ng-container *ngIf="type.value == 'HTML'">

        <div class="p-md-12 p-float-label">
          <angular-editor name="default" [ngModel]="setting.default"></angular-editor>
        </div>

        <div ngModelGroup="options">
          <input type="hidden" ngModel name="pattern" >
        </div>


      </ng-container>


    </div>


    <app-graphql-errors [errors]="errors" [form]="formElement"></app-graphql-errors>

  </form>


</mat-dialog-content>



<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cerrar</button>
  <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="save(form.value)">Guardar</button>

</mat-dialog-actions>
