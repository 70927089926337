import {MatDialog} from "@angular/material/dialog";
import {Pagination} from './Pagination';
import {LazyLoadEvent} from 'primeng/api';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {filter} from 'rxjs/operators';

export abstract class TableList {

  pagination: Pagination;

  protected constructor(
    private component: any,
    protected dialog: MatDialog) {
  }


  onLazyEvent(event: LazyLoadEvent): void {
    this.pagination = new Pagination(event);
    this.updateElements();
  }


  abstract updateElements(): void;
  abstract delete(id: number): void;



  edit(id: number) {

    this.dialog.open(this.component, {
      width: '90%',
      data: {id}
    }).afterClosed().subscribe( _ => this.updateElements());

  }

  new() {
    this.edit(null);
  }


  confirmDelete(id: number) {

    this.dialog.open(ConfirmDialogComponent, {
      width: '90%',
      data: {
        title: 'Eliminar',
        content: '¿Confirmas que deseas eliminar el elemento? No se podrá recuperar después.'
      }
    }).afterClosed()
      .pipe(filter(r => r))
      .subscribe(s => this.delete(id));

  }

}
