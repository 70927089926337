<h2 mat-dialog-title>Añadir abogado/a</h2>


<mat-dialog-content>


  <form #form="ngForm" autocomplete="off">

    <div class="p-grid p-fluid">


      <div class="p-md-12 p-float-label">
        <p-dropdown [options]="lawyers"
                    dataKey="id"
                    optionLabel="name"
                    optionValue="id"
                    filter="true"
                    virtualScroll="true"
                    itemSize="30"
                    appendTo="body"
                    required
                    name="lawyer"
                    placeholder="----"
                    filterBy="name,lastName,lawyerLicense"
                    [(ngModel)]="lawyer">

          <ng-template pTemplate="selectedItem" let-element>
            {{element.lawyerLicense}} - {{element.name }} {{element.lastName}}
          </ng-template>

          <ng-template pTemplate="item" let-element>
            {{element.lawyerLicense}} - {{element.name }} {{element.lastName}}
          </ng-template>


        </p-dropdown>
        <label>Abogado/a</label>
      </div>

    </div>


  </form>


</mat-dialog-content>

<mat-dialog-actions>


  <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="save()">
    Guardar
  </button>

  <button mat-dialog-close mat-button>Cerrar</button>


</mat-dialog-actions>


<p-toast></p-toast>
