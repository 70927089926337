import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent  {

  title = '';
  content = '';
  yes = '';
  no = '';

  constructor(@Inject(MAT_DIALOG_DATA) data: any) {
    data = data || {};
    this.title = data.title;
    this.content = data.content;
    this.yes = data.yes || 'Sí';
    this.no = data.no || 'No';
  }

}
