<mat-toolbar class="mat-elevation-z4">
  <span fxFlex>Juzgados de Guardia</span>

  <input pInputText class="p-inputtext-sm" type="text" (input)="table.filterGlobal($event.target.value, 'contains')" placeholder="Filtrar">

</mat-toolbar>


<p-table
  responsiveLayout="scroll"
  #table
  [value]="courts.content"
  (onLazyLoad)="onLazyEvent($event)"
  [lazy]="true"
  [paginator]="true"
  [rows]="20"
  [totalRecords]="courts.totalElements"
  sortField="start"
  sortOrder="1"
  styleClass="mat-elevation-z4"
>

  <ng-template pTemplate="header">


    <tr>
      <th>Inicio</th>
      <th>Fin</th>
      <th>Juzgado</th>
      <th>Partido</th>
      <th>Perfil</th>
      <th>Nuevo</th>
    </tr>

  </ng-template>


  <ng-template pTemplate="body" let-element >

    <tr>

      <td>
        {{ element.start | date: 'dd/MM/yy' }}
      </td>

      <td>
        {{ element.end | date: 'dd/MM/yy' }}
      </td>

      <td>
        {{element.court.name }}
      </td>

      <td>
        {{ element.court.judicialDistricts[0].name }}
      </td>

      <td>
        {{ element.court.profile.description }}
      </td>

      <td>
        {{ element.profile.description }}
      </td>


    </tr>


  </ng-template>


</p-table>
