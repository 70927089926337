import {Pipe, PipeTransform} from '@angular/core';
import {ConfigService} from "../settings/services/config.service";

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  constructor(private config: ConfigService) {
  }

  transform(value: string, ...args: unknown[]): unknown {

    if (this.config.data.demoMode && value)
      return 'xxxxxx' + value.substr(6);


    return value;
  }

}
