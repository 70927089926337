<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content>
  {{content}}
</mat-dialog-content>

<mat-dialog-actions align="end">

  <button mat-raised-button color="accent" [mat-dialog-close]="true">{{yes}}</button>
  <button mat-button [mat-dialog-close]="false">{{no}}</button>

</mat-dialog-actions>
