import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {Alert, AlertsFiltersGQL, AlertsFiltersQuery, AlertsTableGQL, AlertsTableQuery, AlertsTableQueryVariables, AlertStatus, ExportAlertExcelGQL, InterruptAlertGQL, ReplacementStatus} from '../../../generated/graphql';
import {LazyLoadEvent} from 'primeng/api';
import {Pagination} from '../../utils/Pagination';
import {MatDialog} from "@angular/material/dialog";
import {QueryRef} from 'apollo-angular';
import {AlertPipe} from "../pipes/alert.pipe";
import {CreateAlertComponent} from "../create-alert/create-alert.component";
import {AuthService} from "../../users/auth/auth.service";
import {filter} from "rxjs/operators";
import {AlertsService} from "../services/alerts.service";
import {ActivatedRoute} from "@angular/router";
import {Table} from "primeng/table";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-alerts-table',
  templateUrl: './alerts-table.component.html',
  styleUrls: ['./alerts-table.component.scss'],
})
export class AlertsTableComponent implements AfterViewInit, OnDestroy {


  @ViewChild('table')
  table: Table;

  generatingExcel = false;

  filters: AlertsFiltersQuery;
  pagination: Pagination;

  alerts: AlertsTableQuery['alerts'] = {content: [], totalElements: 0};


  status = [];
  problems = [];

  private ready = false;
  private subscription: QueryRef<AlertsTableQuery, AlertsTableQueryVariables>;


  constructor(
    getFilters: AlertsFiltersGQL,
    private service: AlertsTableGQL,
    private alertsService: AlertsService,
    private excel: ExportAlertExcelGQL,
    private interruptAlertService: InterruptAlertGQL,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    public auth: AuthService,
    public route: ActivatedRoute,
  ) {

    getFilters.fetch().subscribe(s => this.filters = s.data);

    this.createStatus();
    this.createProblems();
  }

  ngAfterViewInit(): void {



    const id = this.route.snapshot.paramMap.get('id');

    if (id)
      this.table.filters['id'] = [{value: id, matchMode: 'equals', operator: 'and'}];



    this.subscription = this.service.watch({
      pagination: {
        size: 20,
        page: 0,
        columns: 'lastUpdate',
        direction: -1,

      }
    }, {pollInterval: 30 * 1000});

    this.subscription.valueChanges
      .pipe(filter( s => s.error == undefined))
      .subscribe(s => this.alerts = s.data.alerts);


    this.ready = true;

    this.onLazyLoad(this.table.createLazyLoadMetadata());


  }

  ngOnDestroy() {
    this.subscription.stopPolling();
  }


  async onLazyLoad(event: LazyLoadEvent) {

    this.pagination = new Pagination(event);
    await this.subscription.setVariables({pagination: this.pagination});
  }


  updateElements() {
    if (this.ready) this.subscription.refetch();
  }


  createStatus() {

    const pipe = new AlertPipe();

    this.status = Object.keys(AlertStatus)
      .map(s => AlertStatus[s])
      .map(s => Object({label: pipe.transform({status: s} as Alert, 'status-text'), value: s}));


  }


  createProblems() {

    this.problems = [
      {value: 'firstLost', label: 'Turno perdido'},
      {value: 'watchLost', label: 'Guardias perdidas'},
    ]


  }



  async exportExcel() {


    this.generatingExcel = true;


    const s = await this.excel.fetch({pagination: this.pagination}).toPromise();

    const a = document.createElement('a');
    a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${s.data.excel}`;
    a.download = 'Alertas.xlsx';
    a.click();

    this.generatingExcel = false;


  }

  replacementStatus(replacementStatus: ReplacementStatus) {
    switch (replacementStatus) {
      case ReplacementStatus.None:
        return '';
      case ReplacementStatus.Accepted:
        return 'check';
      case ReplacementStatus.RejectShift:
        return 'cancel';
      case ReplacementStatus.RejectAssistance:
        return 'bell-cancel';
      case ReplacementStatus.NotAnswers:
        return 'phone-missed';

    }
  }

  replacementStatusColor(replacementStatus: ReplacementStatus) {
    switch (replacementStatus) {
      case ReplacementStatus.None:
        break;
      case ReplacementStatus.Accepted:
        return 'success-text';
      case ReplacementStatus.RejectShift:
        return 'danger-text';
      case ReplacementStatus.RejectAssistance:
        return 'warning-text';
      case ReplacementStatus.NotAnswers:
        break;

    }
  }

  interruptAlert(alert: Alert) {

    this.interruptAlertService
      .mutate({id: alert.id})
      .subscribe(s => {
        if (s.data.interruptAlert) {
          this.updateElements();
        } else {
          this.snackbar.open('Error interrumpiendo la alerta. Inténtelo de nuevo.', 'Aceptar', {
            horizontalPosition: 'right',
            verticalPosition: 'top'
          });
        }
      });

  }



  continueAlert(alert: Alert) {
    this.alertsService
      .continueAlert(alert.id)
      .subscribe(s => {
        if (s.data.continueAlert) {
          this.updateElements();
        } else {
          this.snackbar.open('Error continuando la alerta. Inténtelo de nuevo.', 'Aceptar', {
            horizontalPosition: 'right',
            verticalPosition: 'top'
          });
        }
      });
  }

  exportExcelSingle(id: number) {

    const pagination = new Pagination({

      rows: 1,
      first: 0,
      sortField: 'id',
      sortOrder: 1,
      filters: {
        'id': {value: id, matchMode: 'equals', operator: 'and'},
      }
    });


    this.excel.fetch({pagination})
      .subscribe(s => {
          const a = document.createElement('a');
          a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,;base64,${s.data.excel}`;
          a.download = `Alerta ${id}.xlsx`;
          a.click();
        }
      );


  }

  new() {
    this.dialog.open(CreateAlertComponent, {
      maxWidth: '90%',
      width: '600px'
    }).afterClosed().subscribe( _ => this.updateElements());
  }


  get AlertStatus() {
    return AlertStatus;
  }

}
