<h2 mat-dialog-title>Lista</h2>


<mat-dialog-content>


  <form #form="ngForm" autocomplete="off">

    <div class="p-grid p-fluid">


      <div class="p-md-12 p-float-label">
        <input pInputText type="text" name="name" [(ngModel)]="lawyerList.name" required/>
        <label>Nombre</label>
      </div>



      <div class="p-md-6 p-float-label">
        <p-dropdown [options]="alertTypes" optionLabel="name" optionValue="id" filter="true"
                    placeholder="----" appendTo="body" name="alertType"  [(ngModel)]="lawyerList.alertType">

        </p-dropdown>
        <label>Materia</label>
      </div>


      <div class="p-md-6 p-float-label">
        <p-dropdown [options]="judicialDistricts" optionLabel="name" optionValue="id" filter="true"
                    placeholder="----" appendTo="body" name="judicialDistricts"  [(ngModel)]="lawyerList.judicialDistrict">
        </p-dropdown>
        <label>Partidos judicial</label>
      </div>



    </div>


  </form>


</mat-dialog-content>

<mat-dialog-actions>


  <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="save()">
    Guardar
  </button>

  <button mat-dialog-close mat-button>Cerrar</button>


</mat-dialog-actions>


<p-toast></p-toast>
