


<p-table
  responsiveLayout="scroll"

  [value]="calls.content"
  (onLazyLoad)="onLazyEvent($event)"
  [lazy]="true"
  [paginator]="true"
  [rows]="20"
  [totalRecords]="calls.totalElements"
  sortField="date"
  [sortOrder]="-1"
  #table
  styleClass="mat-elevation-z4">

  <ng-template pTemplate="header">


    <tr>

      <th>#</th>
      <th>Abogado/a</th>

      <th pSortableColumn="date">
        <p-sortIcon field="date"></p-sortIcon>
        Fecha
      </th>

      <th>Guardia</th>
      <th>Aviso</th>
    </tr>

  </ng-template>


  <ng-template pTemplate="body" let-call>

    <tr>

      <td>

        <app-call-state [event]="call"></app-call-state>

      </td>

      <td>
        {{ call.lawyer.fullName }}
      </td>

      <td class="sort-padding">
        {{ call.date | date:'dd/MM/yy HH:mm' }}
      </td>


      <td>
        <button mat-stroked-button *ngIf="call.watch" routerLink="/guardias/{{call.watch.id}}">{{call.watch.id}}</button>
      </td>

      <td>
        <button mat-stroked-button *ngIf="call.alert" routerLink="/avisos/{{call.alert.id}}">{{call.alert.id}}</button>
      </td>

    </tr>

  </ng-template>


</p-table>
