<h2 mat-dialog-title>
  Menú
</h2>


<form #form="ngForm" #formElement>

  <mat-dialog-content>

    <div class="p-grid p-fluid">


      <div class="p-md-2 p-float-label">
        <input pInputText name="id" [ngModel]="menu.id" disabled>
        <label>ID</label>
      </div>

      <div class="p-md-2 p-float-label">
        <input pInputText name="order" [ngModel]="menu.order" disabled>
        <label>Posición</label>
      </div>


      <div class="p-md-4 p-float-label">
        <input pInputText name="judicialDistrict" [ngModel]="menu.judicialDistrict?.name" disabled>
        <label>Partido Judicial</label>
      </div>


      <div class="p-md-4 p-float-label">
        <input pInputText name="profile" [ngModel]="menu.profile?.description" disabled>
        <label>Perfil</label>
      </div>


      <div class="p-md-3 p-float-label">

        <p-dropdown [options]="filters.audios" optionValue="code" optionLabel="name" name="audio"
                    required [ngModel]="menu.audio?.code"

                    filter="true"
                    (onChange)="audioChange($event)"

        >

        </p-dropdown>
        <label>Audio</label>
      </div>




      <div class="p-md-3 p-float-label">

        <p-dropdown [options]="filters.alertTypes" optionValue="id" optionLabel="name" name="alertType"
                    filter="true"
                    [ngModel]="menu.alertType?.id">

        </p-dropdown>
        <label>Guardia</label>
      </div>



      <div class="p-md-3 p-float-label">

        <p-dropdown [options]="filters.alertTypes" optionValue="id" optionLabel="name" name="alternativeWatch"
                    filter="true"
                    [ngModel]="menu.alternativeWatch?.id">

        </p-dropdown>
        <label>Guardia alternativa</label>
      </div>



      <div class="p-md-3 p-float-label">

        <p-dropdown [options]="filters.lists" optionValue="id" optionLabel="name" name="lawyerList"
                    [ngModel]="menu.lawyerList?.id">

        </p-dropdown>
        <label>Gestionar por lista</label>
      </div>


      <div class="p-md-3 p-float-label">

        <p-dropdown [options]="[{label: 'Sí', value: true}, {label: 'No', value: false }]"
                    optionValue="value" optionLabel="label" name="incompatible"
                    [ngModel]="menu.incompatible">

        </p-dropdown>

        <label>Defensa incompatible</label>
      </div>

      <div class="p-md-3 p-float-label">

        <p-dropdown [options]="[{label: 'Sí', value: true}, {label: 'No', value: false }]"
                    optionValue="value" optionLabel="label" name="askDetained"
                    [ngModel]="menu.askDetained">

        </p-dropdown>

        <label>Varios detenidos</label>
      </div>

      <div class="p-md-3 p-float-label">

        <p-inputNumber [ngModel]="menu.detainedPerLawyer" name="detainedPerLawyer" [min]="1" [maxFractionDigits]="0"></p-inputNumber>

        <label>Detenidos por abogado</label>
      </div>

      <div class="p-md-3 p-float-label">

        <p-dropdown [options]="filters.alertTypes"
                    optionValue="id" optionLabel="name" name="alertTypeMultiple"
                    [ngModel]="menu.alertTypeMultiple?.id">

        </p-dropdown>
        <label>Guardia para múltiple</label>
      </div>

      <div class="p-md-3 p-float-label">

        <p-dropdown [options]="filters.alertTypes"
                    optionValue="id" optionLabel="name" name="duplicate"
                    [ngModel]="menu.duplicate?.id">

        </p-dropdown>
        <label>Duplicar aviso otra materia</label>
      </div>


      <div class="p-md-3 p-float-label">

        <p-dropdown [options]="filters.alertTypes" optionValue="id" optionLabel="name" name="alias"
                    [ngModel]="menu.alias?.id">

        </p-dropdown>
        <label>Alias</label>
      </div>

      <div class="p-md-3 p-float-label">

        <p-calendar name="start"
                    [timeOnly]="true"
                    [showTime]="true"
                    hourFormat="h:m"
                    appendTo="body"
                    dataType="string"

                    [ngModel]="menu.start">

        </p-calendar>
        <label>Inicio alias</label>
      </div>


      <div class="p-md-3 p-float-label">

        <p-calendar name="end"
                    appendTo="body"
                    [timeOnly]="true"
                    [showTime]="true"
                    dataType="string"
                    hourFormat="h:m"
                    [ngModel]="menu.end">

        </p-calendar>
        <label>Fin alias</label>
      </div>








    </div>



    <app-graphql-errors [errors]="errors" [form]="formElement"></app-graphql-errors>

  </mat-dialog-content>


  <mat-dialog-actions>

    <button mat-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button color="primary" [disabled]="!form.valid || loading" (click)="save(form.value)"
            [class.spinner]="loading">
      Guardar
    </button>

  </mat-dialog-actions>


</form>
