<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">


    <img class="logo" src="/assets/{{config.data.logo}}" alt="Logo" >



    <mat-nav-list>
      <div mat-subheader>Menu</div>

      <a mat-list-item *ngIf="loginService.alerts || loginService.ownAlerts" routerLink="avisos">
        <mat-icon svgIcon="alert"></mat-icon> Avisos
      </a>
      <a mat-list-item *ngIf="loginService.shifts" routerLink="guardias">
        <mat-icon svgIcon="account-clock"></mat-icon> Guardias
      </a>

      <a mat-list-item *ngIf="loginService.informs" routerLink="informes">
        <mat-icon svgIcon="finance"></mat-icon> Informes
      </a>

      <a mat-list-item *ngIf="loginService.assists" routerLink="asistencias">
        <mat-icon svgIcon="handshake"></mat-icon> Asistencias
      </a>


      <a mat-list-item *ngIf="loginService.readLawyer" routerLink="abogados">
        <mat-icon svgIcon="account-tie"></mat-icon> Abogados/as
      </a>

      <a mat-list-item *ngIf="loginService.lawyers" routerLink="listas">
        <mat-icon svgIcon="format-list-numbered"></mat-icon> Listas
      </a>


      <a mat-list-item *ngIf="loginService.users" routerLink="usuarios">
        <mat-icon svgIcon="account"></mat-icon> Usuarios/as
      </a>



      <a mat-list-item *ngIf="loginService.courts" routerLink="juzgados">
        <mat-icon svgIcon="gavel"></mat-icon> J. de Guardia
      </a>



      <ng-container *ngIf="loginService.superAdmin">

        <mat-divider></mat-divider>

        <div mat-subheader>Administración</div>


        <a mat-list-item routerLink="perfiles">
          <mat-icon svgIcon="account-cog"></mat-icon> Perfiles
        </a>

        <a mat-list-item routerLink="menus">
          <mat-icon svgIcon="file-tree"></mat-icon> Menus
        </a>

        <a mat-list-item routerLink="audios">
          <mat-icon svgIcon="account-voice"></mat-icon> Audios
        </a>

        <a mat-list-item routerLink="configuracion">
          <mat-icon svgIcon="cog"></mat-icon> Configuración
        </a>

        <a mat-list-item routerLink="partidos-judiciales">
          <mat-icon svgIcon="gavel"></mat-icon> Partidos Judiciales
        </a>

      </ng-container>



    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon>menu</mat-icon>
      </button>


      <span fxFlex>{{config.data.title}}</span>




      <ng-container *ngIf="loginService.logged">

        <span>
        {{ loginService.auth.username }}
        </span>


        <button class="navigation-center" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon svgIcon="dots-vertical"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">

          <button mat-menu-item (click)="logout()">
            <mat-icon svgIcon="logout"></mat-icon>
            Salir

          </button>

          <button mat-menu-item (click)="changePassword()">
            <mat-icon svgIcon="form-textbox-password"></mat-icon>
            <span>Cambiar contraseñas</span>
          </button>

        </mat-menu>






      </ng-container>



    </mat-toolbar>


    <router-outlet></router-outlet>



  </mat-sidenav-content>
</mat-sidenav-container>
