import {Component, OnInit} from '@angular/core';
import {LoginGQL} from '../../../generated/graphql';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  username = '';
  password = '';

  error = false;

  constructor(private loginQuery: LoginGQL,
              private loginService: AuthService,
              private router: Router

              ) { }

  ngOnInit(): void {
  }

  login() {

    this.loginQuery
      .fetch({username: this.username, password: this.password})
      .subscribe(value => {

        this.error = value.errors != undefined;

        if (this.error == false) {
          this.loginService.newToken(value.data.login);
          this.router.navigate(['/avisos']);
        }
      });
  }

}
