export class Auth {

  id = 0;
  username = '';
  roles = '';




  static ADMIN = 'ROLE_ADMIN';
  static ALERTS = 'ROLE_ALERTS';
  static WATCHES = 'ROLE_WATCHES';
  static USERS = 'ROLE_USERS';
  static LAWYERS = 'ROLE_LAWYERS';
  static OWN_ALERTS = 'ROLE_OWN_ALERTS';
  static ASSISTS = 'ROLE_ASSISTS';
  static COURTS = 'ROLE_COURTS';
  static READ_LAWYERS = 'ROLE_READ_LAWYERS';
  static SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
  static CREATE_ALERT = 'ROLE_CREATE_ALERTS';

  static EXPORT_ALERTS = 'ROLE_EXPORT_ALERTS';
  static CREATE_ALERTS = 'ROLE_CREATE_ALERTS';

  static SUPPLANT = 'ROLE_SUPPLANT';
  static ALERT_NOT_ANSWERED = 'ROLE_NOTIFIER_ALERT_NOT_ANSWERED';
  static INFORMS = 'ROLE_INFORMS';
}
