import {Component} from '@angular/core';
import {TableList} from '../../utils/TableList';
import {MatDialog} from "@angular/material/dialog";
import {AssistsFiltersGQL, AssistsFiltersQuery, AssistsTableGQL, AssistsTableQuery, PageAssists} from '../../../generated/graphql';

@Component({
  selector: 'app-assists-table',
  templateUrl: './assists-table.component.html',
  styleUrls: ['./assists-table.component.scss']
})
export class AssistsTableComponent extends TableList{


  filters: AssistsFiltersQuery;
  assists: AssistsTableQuery['assists'] = new PageAssists();


  constructor(
    dialog: MatDialog,
    assistsFilter: AssistsFiltersGQL,
    private assistsService: AssistsTableGQL,
  ) {
    super(null, dialog)


    assistsFilter.fetch().subscribe( s => this.filters = s.data);

  }

  delete(id: number) {
  }

  updateElements() {
    this.assistsService
      .fetch({pagination: this.pagination})
      .subscribe( s => this.assists = s.data.assists);

  }



}
