<h2 mat-dialog-title>Cambiar guardia de {{watch.start | date:'dd/MM/yyyy' }}</h2>

<mat-dialog-content>

  <h2>Abogado/a actual: {{ watch.lawyer.fullName }}</h2>


  <form #form="ngForm">

    <div class="p-fluid p-grid">


      <div class="p-col-12 p-float-label">
        <p-dropdown optionValue="id"
                    optionLabel="fullName" [options]="lawyers.content"
                    appendTo="body"
                    ngModel
                    placeholder="----"
                    [virtualScroll]="true"
                    [filter]="true"
                    itemSize="30"
                    filterBy="fullName"
                    name="lawyer" required>

        </p-dropdown>

        <label>Sustituto/a</label>
      </div>
    </div>


    <app-graphql-errors [errors]="errors"></app-graphql-errors>

  </form>

</mat-dialog-content>

<mat-dialog-actions>

  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="save(form.value)">Guardar</button>

</mat-dialog-actions>
