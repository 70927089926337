import {Injectable} from '@angular/core';
import {FilterLawyerGQL, ImportLawyersGQL} from "../../../generated/graphql";
import {Pagination} from "../../utils/Pagination";

@Injectable({
  providedIn: 'root'
})
export class LawyersService {

  constructor(
    private filterService: FilterLawyerGQL,
    private importService: ImportLawyersGQL
  ) { }



  filter(pagination?: Pagination) {
    return this.filterService.fetch({pagination});
  }

  import(file: File) {
    return this.importService.mutate({file});
  }

}
