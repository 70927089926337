


<div class="alert p-grid size-s">

  <div class="p-md-3">
    <mat-icon svgIcon="calendar-clock"></mat-icon> {{ alert.start | date:'dd/MM/yy HH:mm':'Europe/Madrid' }}
  </div>


  <div class="p-md-3">
    <mat-icon svgIcon="account"></mat-icon> {{ alert.user.name }}
  </div>


  <div class="p-md-3">
    <mat-icon svgIcon="file-document"></mat-icon> {{alert.diligence }}
  </div>


  <div class="p-md-2">
    <ng-container *ngIf="alert.detained > 1">
      <mat-icon svgIcon="account-multiple"></mat-icon> {{alert.detained}}
    </ng-container>
  </div>


  <div class="p-md-1" *ngIf="alert.audio">
    <app-play-audio [file]="alert.audio" [name]="'Aviso ' + alert.id"></app-play-audio>
  </div>





  <ng-container *ngIf="alert.status == 'INTERRUPTED' || alert.status == 'CANCELLED' ">

    <div class="p-md-6" *ngIf="alert.interruptedBy != null">
      <mat-icon svgIcon="clipboard-remove" class="danger-text"></mat-icon> Interrumpida por {{alert.interruptedBy.name}}.
    </div>

    <div class="p-md-6" *ngIf="alert.interruptedBy == null">
      <mat-icon svgIcon="clipboard-remove" class="danger-text"></mat-icon> Interrumpida por el sistema.
    </div>

  </ng-container>


  <app-calls [events]="$any(alert.allEvents)"></app-calls>


</div>



