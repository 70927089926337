<mat-toolbar class="mat-elevation-z4">
  <span fxFlex>
    Avisos
  </span>

  <button mat-icon-button (click)="exportExcel()" *ngIf="auth.exportAlerts" [class.spinner]="generatingExcel" [disabled]="generatingExcel" spinner>
    <mat-icon svgIcon="file-excel"></mat-icon>
  </button>

  <button mat-icon-button (click)="new()" *ngIf="auth.createAlert">
    <mat-icon svgIcon="plus"></mat-icon>
  </button>


</mat-toolbar>


<p-table
  #table
  responsiveLayout="scroll"
  lazy="true"
  [rows]="20"
  [totalRecords]="alerts.totalElements"
  (onLazyLoad)="onLazyLoad($event)"
  paginator="true"
  [value]="alerts.content"
  dataKey="id"
  sortField="lastUpdate"
  [sortOrder]="-1"
  tableStyleClass="mat-elevation-z4 size-s"
>


  <ng-template pTemplate="header">

    <tr>
      <th style="width: 60px"></th>

      <th style="width: 60px;">
        <p-columnFilter field="status" matchMode="contains" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">

          <ng-template pTemplate="filter" let-value let-filter="filterCallback">

            <p-multiSelect [ngModel]="value" [options]="status" (onChange)="filter($event.value)" optionValue="value" optionLabel="label" [filter]="false">
            </p-multiSelect>

          </ng-template>

        </p-columnFilter>

      </th>

      <th style="width: 60px">


      </th>


      <th pSortableColumn="date">
        <p-sortIcon field="date"></p-sortIcon>
        Creación

        <p-columnFilter type="date" display="menu" field="date"></p-columnFilter>
      </th>

      <th pSortableColumn="start">
        <p-sortIcon field="start"></p-sortIcon>
        Fecha

        <p-columnFilter type="date" display="menu" field="start"></p-columnFilter>
      </th>

      <th pSortableColumn="judicialDistrict.name">
        <p-sortIcon field="judicialDistrict.name"></p-sortIcon>
        Partido

        <p-columnFilter field="judicialDistrict.id" matchMode="contains" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">

          <ng-template pTemplate="filter" let-value let-filter="filterCallback">

            <p-multiSelect [ngModel]="value" [options]="filters.judicialDistricts" (onChange)="filter($event.value)" optionValue="id" optionLabel="name"></p-multiSelect>

          </ng-template>

        </p-columnFilter>
      </th>

      <th pSortableColumn="alias.name">
        <p-sortIcon field="alias.name"></p-sortIcon>
        Materia

        <p-columnFilter field="alias.id" matchMode="contains" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">

          <ng-template pTemplate="filter" let-value let-filter="filterCallback">

            <p-multiSelect [ngModel]="value" [options]="filters.alertsType" (onChange)="filter($event.value)" optionValue="id" optionLabel="name"></p-multiSelect>

          </ng-template>

        </p-columnFilter>

      </th>

      <th pSortableColumn="user.name">
        <p-sortIcon field="user.name"></p-sortIcon>
        Solicitante

        <p-columnFilter field="user.name" type="text" display="menu"></p-columnFilter>

      </th>

      <th pSortableColumn="numCalls">
        <p-sortIcon field="numCalls"></p-sortIcon>
        Llamadas

      </th>

      <th pSortableColumn="id">
        <p-sortIcon field="id"></p-sortIcon>
        ID
        <p-columnFilter type="numeric" display="menu" field="id"></p-columnFilter>

      </th>

      <th style="width: 60px">

      </th>


    </tr>


  </ng-template>


  <ng-template pTemplate="body" let-alert let-expanded="expanded">
    <tr class="{{ alert | alert:'status-class'}}-background">

      <td>
        <button mat-icon-button [pRowToggler]="alert">
          <mat-icon [svgIcon]="expanded ? 'chevron-down' : 'chevron-right'"></mat-icon>
        </button>
      </td>

      <td>

        <mat-icon class="{{alert | alert:'status-class'}}-text" [svgIcon]="alert | alert:'status-icon'"></mat-icon>
      </td>
      <td>

        <mat-icon class="{{replacementStatusColor(alert.replacementStatus)}}" [svgIcon]="replacementStatus(alert.replacementStatus)"></mat-icon>
      </td>


      <td class="sort-padding">
        {{ alert.date | date: 'dd/MM/yy HH:mm' }}
      </td>

      <td class="sort-padding">
        {{ alert.start | date: 'dd/MM/yy HH:mm' }}
      </td>

      <td class="sort-padding">
        {{ alert.judicialDistrict.name }}
      </td>

      <td class="sort-padding">
        {{ alert.alias.name }}

        <ng-container *ngIf="alert.incompatible">
          (Def. Inc.)
        </ng-container>
      </td>


      <td class="sort-padding">
        {{ alert.user.name }}
      </td>

      <td class="p-text-center">
        {{ alert.numCalls }}
      </td>


      <td class="sort-padding">
        {{ alert.id }}
      </td>


      <td>


        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon svgIcon="dots-vertical"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">

          <button mat-menu-item (click)="exportExcelSingle(alert.id)">
            <mat-icon svgIcon="file-excel"></mat-icon>
            Exportar

          </button>

          <button mat-menu-item (click)="interruptAlert(alert)" [disabled]="alert.status == AlertStatus.Interrupted">
            <mat-icon svgIcon="clipboard-remove"></mat-icon>
            <span>Interrumpir</span>
          </button>

          <button mat-menu-item (click)="continueAlert(alert)" [disabled]="alert.status != AlertStatus.Interrupted  && alert.status != AlertStatus.Cancelled">
            <mat-icon svgIcon="play"></mat-icon>
            <span>Continuar</span>
          </button>

        </mat-menu>


      </td>


    </tr>


  </ng-template>

  <ng-template pTemplate="rowexpansion" let-alert>

    <td></td>
    <td colspan="8">

      <app-alert-details [alert]="alert"></app-alert-details>

    </td>


  </ng-template>
</p-table>


