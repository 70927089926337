<mat-toolbar class="mat-elevation-z4">


  <span fxFlex>
    Listas
  </span>


  <span>

     <input pInputText class="p-inputtext-sm" type="text" (input)="table.filterGlobal($event.target.value, 'contains')"  placeholder="Filtrar"/>

    <button mat-icon-button (click)="new()">
      <mat-icon svgIcon="plus"></mat-icon>
    </button>


  </span>




</mat-toolbar>


<p-table
  responsiveLayout="scroll"
  [value]="lists.content"
  dataKey="judicialDistrict.name"
  #table
  groupRowsBy="judicialDistrict.name"
  rowGroupMode="subheader"
  styleClass="mat-elevation-z4">

  <ng-template pTemplate="header">


    <tr>
      <th style="width: 60px"></th>
      <th>Nombre</th>
      <th>Materia</th>

      <th style="width: 120px"></th>

    </tr>

  </ng-template>


  <ng-template pTemplate="groupheader" let-list let-rowIndex="rowIndex" let-expanded="expanded">
    <tr>

      <td>
        <button mat-icon-button [pRowToggler]="list">
          <mat-icon [svgIcon]="expanded ? 'chevron-down' : 'chevron-right'"></mat-icon>
        </button>

      </td>

      <td colspan="3">

        {{ list.judicialDistrict.name }}

      </td>
    </tr>
  </ng-template>


  <ng-template pTemplate="rowexpansion" let-list >

    <tr>
      <td></td>

      <td>
        {{ list.name }}
      </td>


      <td>
        {{ list.alertType.name }}
      </td>


      <td>


        <a mat-icon-button routerLink="/listas/{{list.id}}">
          <mat-icon svgIcon="eye"></mat-icon>
        </a>

        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon svgIcon="dots-vertical"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="edit(list.id)">
            <mat-icon svgIcon="pencil"></mat-icon>
            <span>Editar</span>
          </button>
        </mat-menu>

      </td>


    </tr>


  </ng-template>


</p-table>
