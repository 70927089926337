import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An RFC-3339 compliant Full Date Scalar */
  Date: Date;
  /** An RFC-3339 compliant Full Date Scalar */
  DateTime: Date;
  /** Base64 file */
  File: any;
  /** A file part in a multipart request */
  Upload: any;
};

export class Alarm {
  __typename?: 'Alarm';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export class Alert {
  __typename?: 'Alert';
  alertType: AlertType;
  alias: AlertType;
  allEvents?: Maybe<Array<Maybe<SimpleEvent>>>;
  audio?: Maybe<Scalars['String']>;
  calls?: Maybe<Array<Maybe<Call>>>;
  date: Scalars['DateTime'];
  detained: Scalars['Int'];
  diligence: Scalars['String'];
  events?: Maybe<Array<Maybe<Event>>>;
  id: Scalars['Int'];
  incompatible: Scalars['Boolean'];
  interruptedBy?: Maybe<User>;
  judicialDistrict: JudicialDistrict;
  lastUpdate: Scalars['Date'];
  numCalls: Scalars['Int'];
  replacementStatus: ReplacementStatus;
  start: Scalars['DateTime'];
  status: AlertStatus;
  user?: Maybe<User>;
};

export class AlertInput {
  audio?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Int']>;
  detained?: InputMaybe<Scalars['Int']>;
  diligence?: InputMaybe<Scalars['String']>;
  incompatible?: InputMaybe<Scalars['Boolean']>;
  menu?: InputMaybe<Scalars['Int']>;
  urgent?: InputMaybe<Scalars['Boolean']>;
};

export enum AlertStatus {
  Cancelled = 'CANCELLED',
  Enqueued = 'ENQUEUED',
  Incomplete = 'INCOMPLETE',
  Interrupted = 'INTERRUPTED',
  OnGoing = 'ON_GOING',
  Resolved = 'RESOLVED'
}

export class AlertType {
  __typename?: 'AlertType';
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export class Assistance {
  __typename?: 'Assistance';
  alertType: AlertType;
  caller: Scalars['String'];
  date: Scalars['DateTime'];
  lawyer: Lawyer;
};

export class Audio {
  __typename?: 'Audio';
  code: Scalars['String'];
  file: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  text: Scalars['String'];
};

export class AudioInput {
  code: Scalars['String'];
  name: Scalars['String'];
  text: Scalars['String'];
};

export class Call {
  __typename?: 'Call';
  alert?: Maybe<Alert>;
  audio?: Maybe<Scalars['String']>;
  callType: CallType;
  date: Scalars['DateTime'];
  duration: Scalars['Int'];
  id: Scalars['Int'];
  lawyer: Lawyer;
  phone: Scalars['String'];
  result: CallResult;
  unattended: Scalars['Boolean'];
  watch?: Maybe<Watch>;
};

export enum CallResult {
  Absence = 'ABSENCE',
  Accepted = 'ACCEPTED',
  CallError = 'CALL_ERROR',
  Communicates = 'COMMUNICATES',
  Denied = 'DENIED',
  HungUp = 'HUNG_UP',
  HungUpBeforeConfirm = 'HUNG_UP_BEFORE_CONFIRM',
  NotAnswer = 'NOT_ANSWER',
  NotEstablishing = 'NOT_ESTABLISHING',
  Off = 'OFF',
  Rejected = 'REJECTED',
  RejectedAfterAccepted = 'REJECTED_AFTER_ACCEPTED'
}

export enum CallType {
  Interruption = 'INTERRUPTION',
  List = 'LIST',
  Normal = 'NORMAL',
  Remember = 'REMEMBER',
  Substitute = 'SUBSTITUTE'
}

export class CategorySettings {
  __typename?: 'CategorySettings';
  name?: Maybe<Scalars['String']>;
  settings: Array<Setting>;
};

export class CourtOnDuty {
  __typename?: 'CourtOnDuty';
  court?: Maybe<User>;
  end?: Maybe<Scalars['Date']>;
  profile?: Maybe<Profile>;
  start?: Maybe<Scalars['Date']>;
};

export class Event {
  __typename?: 'Event';
  alert?: Maybe<Alert>;
  audio?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  id: Scalars['Int'];
  lawyer: Lawyer;
  type: EventType;
  unattended: Scalars['Boolean'];
};

export enum EventType {
  Api = 'API',
  TelegramAcceptAssistance = 'TELEGRAM_ACCEPT_ASSISTANCE',
  TelegramConfirmShift = 'TELEGRAM_CONFIRM_SHIFT',
  TelegramRejectAssistance = 'TELEGRAM_REJECT_ASSISTANCE',
  TelegramRejectShift = 'TELEGRAM_REJECT_SHIFT',
  WebCancelled = 'WEB_CANCELLED',
  WebChange = 'WEB_CHANGE',
  WebSubstitution = 'WEB_SUBSTITUTION'
}

export class Filter {
  field: Scalars['String'];
  matchMode?: InputMaybe<Scalars['String']>;
  operator?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export class JudicialDistrict {
  __typename?: 'JudicialDistrict';
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  options?: Maybe<JudicialDistrictOptions>;
};

export class JudicialDistrictInput {
  code: Scalars['String'];
  name: Scalars['String'];
};

export class JudicialDistrictOptions {
  __typename?: 'JudicialDistrictOptions';
  absenceTime: Scalars['Int'];
  alertType?: Maybe<AlertType>;
  numberAttempts: Scalars['Int'];
};

export class JudicialDistrictPage {
  __typename?: 'JudicialDistrictPage';
  content: Array<JudicialDistrict>;
  totalElements?: Maybe<Scalars['Int']>;
};

export class Lawyer {
  __typename?: 'Lawyer';
  active: Scalars['Boolean'];
  blockUpdate: Scalars['Boolean'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['Int'];
  identification: Scalars['String'];
  judicialDistrict: JudicialDistrict;
  lastName: Scalars['String'];
  lawyerLicense: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  telegram: Scalars['String'];
};

export class LawyerInput {
  active?: InputMaybe<Scalars['Boolean']>;
  blockUpdate?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  identification?: InputMaybe<Scalars['String']>;
  judicialDistrict: Scalars['Int'];
  lastName: Scalars['String'];
  lawyerLicense: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  telegram?: InputMaybe<Scalars['String']>;
};

export class LawyerList {
  __typename?: 'LawyerList';
  alertType: AlertType;
  alertTypeId: Scalars['Int'];
  entries?: Maybe<Array<Maybe<LawyerListEntry>>>;
  id: Scalars['Int'];
  judicialDistrict: JudicialDistrict;
  judicialDistrictId: Scalars['Int'];
  name: Scalars['String'];
};

export class LawyerListEntry {
  __typename?: 'LawyerListEntry';
  active: Scalars['Boolean'];
  auxiliaries: Scalars['Int'];
  lawyer: Lawyer;
  lawyerId: Scalars['Int'];
  list: LawyerList;
  listId: Scalars['Int'];
  rankAuxiliaries: Scalars['Int'];
  rankSubstitutions: Scalars['Int'];
  substitutions: Scalars['Int'];
};

export class LawyerListInput {
  alertType: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  judicialDistrict: Scalars['Int'];
  name: Scalars['String'];
};

export class Menu {
  __typename?: 'Menu';
  alertType?: Maybe<AlertType>;
  alertTypeMultiple?: Maybe<AlertType>;
  alias?: Maybe<AlertType>;
  alternativeWatch?: Maybe<AlertType>;
  askDetained?: Maybe<Scalars['Boolean']>;
  audio: Audio;
  children?: Maybe<Array<Menu>>;
  detainedPerLawyer?: Maybe<Scalars['Int']>;
  duplicate?: Maybe<AlertType>;
  end?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  incompatible?: Maybe<Scalars['Boolean']>;
  isRaid: Scalars['Boolean'];
  judicialDistrict: JudicialDistrict;
  lawyerList?: Maybe<LawyerList>;
  order: Scalars['Int'];
  parent?: Maybe<Menu>;
  profile: Profile;
  start?: Maybe<Scalars['String']>;
};

export class MenuInput {
  alertType?: InputMaybe<Scalars['Int']>;
  alertTypeMultiple?: InputMaybe<Scalars['Int']>;
  alias?: InputMaybe<Scalars['Int']>;
  alternativeWatch?: InputMaybe<Scalars['Int']>;
  askDetained?: InputMaybe<Scalars['Boolean']>;
  audio: Scalars['String'];
  detainedPerLawyer?: InputMaybe<Scalars['Int']>;
  duplicate?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['String']>;
  incompatible?: InputMaybe<Scalars['Boolean']>;
  lawyerList?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['String']>;
};

export class Mutation {
  __typename?: 'Mutation';
  addLawyerListEntry?: Maybe<LawyerListEntry>;
  applySettings?: Maybe<Scalars['Boolean']>;
  cancelWatch?: Maybe<Scalars['Boolean']>;
  changeUserPassword?: Maybe<Scalars['Boolean']>;
  continueAlert?: Maybe<Scalars['Boolean']>;
  createAlert: Alert;
  createMenu: Menu;
  createUser: User;
  createWatch: Watch;
  deleteAudio: Audio;
  deleteLawyerList?: Maybe<LawyerList>;
  deleteLawyerListEntry?: Maybe<Scalars['Boolean']>;
  deleteMenu: Menu;
  downLawyerEntryValue?: Maybe<Scalars['Boolean']>;
  findWatchSubstitute?: Maybe<Scalars['Boolean']>;
  generateAudio: Scalars['Boolean'];
  importLawyers?: Maybe<Array<Maybe<Lawyer>>>;
  importList?: Maybe<Scalars['Boolean']>;
  importLists?: Maybe<Scalars['Boolean']>;
  importSevilla: Scalars['Boolean'];
  importUsers: Scalars['Boolean'];
  importWatches?: Maybe<Array<Maybe<Watch>>>;
  interruptAlert?: Maybe<Scalars['Boolean']>;
  menuPosition: Menu;
  saveAudio: Audio;
  saveJudicialDistrict: JudicialDistrict;
  saveLawyer: Lawyer;
  saveLawyersList?: Maybe<LawyerList>;
  saveMenu: Menu;
  saveProfile?: Maybe<Profile>;
  saveSetting: Setting;
  saveSettingValue: Setting;
  updateAudioFile: Scalars['Boolean'];
  updateUser: User;
  watchChange?: Maybe<Scalars['Boolean']>;
};


export class MutationAddLawyerListEntryArgs {
  lawyer?: InputMaybe<Scalars['Int']>;
  list?: InputMaybe<Scalars['Int']>;
};


export class MutationCancelWatchArgs {
  watch: Scalars['Int'];
};


export class MutationChangeUserPasswordArgs {
  old: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  pin?: InputMaybe<Scalars['String']>;
};


export class MutationContinueAlertArgs {
  id?: InputMaybe<Scalars['Int']>;
};


export class MutationCreateAlertArgs {
  alertInput: AlertInput;
};


export class MutationCreateMenuArgs {
  judicialDistrict: Scalars['Int'];
  parent?: InputMaybe<Scalars['Int']>;
  profile: Scalars['Int'];
};


export class MutationCreateUserArgs {
  user: UserInput;
};


export class MutationCreateWatchArgs {
  watch?: InputMaybe<WatchInput>;
};


export class MutationDeleteAudioArgs {
  id?: InputMaybe<Scalars['Int']>;
};


export class MutationDeleteLawyerListArgs {
  id: Scalars['Int'];
};


export class MutationDeleteLawyerListEntryArgs {
  lawyer?: InputMaybe<Scalars['Int']>;
  list?: InputMaybe<Scalars['Int']>;
};


export class MutationDeleteMenuArgs {
  id?: InputMaybe<Scalars['Int']>;
};


export class MutationDownLawyerEntryValueArgs {
  assistance?: InputMaybe<Scalars['Int']>;
  lawyer?: InputMaybe<Scalars['Int']>;
  list?: InputMaybe<Scalars['Int']>;
  substitution?: InputMaybe<Scalars['Int']>;
};


export class MutationFindWatchSubstituteArgs {
  watch: Scalars['Int'];
};


export class MutationGenerateAudioArgs {
  id: Scalars['Int'];
};


export class MutationImportLawyersArgs {
  excel?: InputMaybe<Scalars['Upload']>;
};


export class MutationImportListArgs {
  excel?: InputMaybe<Scalars['Upload']>;
  list?: InputMaybe<Scalars['Int']>;
};


export class MutationImportListsArgs {
  excel?: InputMaybe<Scalars['Upload']>;
};


export class MutationImportSevillaArgs {
  end: Scalars['Date'];
  judicialDistrict: Scalars['Int'];
  password: Scalars['String'];
  start: Scalars['Date'];
};


export class MutationImportUsersArgs {
  file: Scalars['Upload'];
};


export class MutationImportWatchesArgs {
  excel?: InputMaybe<Scalars['Upload']>;
};


export class MutationInterruptAlertArgs {
  id?: InputMaybe<Scalars['Int']>;
};


export class MutationMenuPositionArgs {
  id?: InputMaybe<Scalars['Int']>;
  position?: InputMaybe<Scalars['Int']>;
};


export class MutationSaveAudioArgs {
  audio: AudioInput;
  id?: InputMaybe<Scalars['Int']>;
};


export class MutationSaveJudicialDistrictArgs {
  id?: InputMaybe<Scalars['Int']>;
  judicialDistrict?: InputMaybe<JudicialDistrictInput>;
};


export class MutationSaveLawyerArgs {
  id?: InputMaybe<Scalars['Int']>;
  lawyer: LawyerInput;
};


export class MutationSaveLawyersListArgs {
  lawyerList?: InputMaybe<LawyerListInput>;
};


export class MutationSaveMenuArgs {
  id: Scalars['Int'];
  menu: MenuInput;
};


export class MutationSaveProfileArgs {
  id?: InputMaybe<Scalars['Int']>;
  profile: ProfileInput;
};


export class MutationSaveSettingArgs {
  id?: InputMaybe<Scalars['Int']>;
  setting: SettingInput;
};


export class MutationSaveSettingValueArgs {
  id: Scalars['Int'];
  value: Scalars['String'];
};


export class MutationUpdateAudioFileArgs {
  file: Scalars['Upload'];
  id: Scalars['Int'];
};


export class MutationUpdateUserArgs {
  id: Scalars['Int'];
  user: UserInput;
};


export class MutationWatchChangeArgs {
  lawyer?: InputMaybe<Scalars['Int']>;
  watch: Scalars['Int'];
};

export class PageAlarms {
  __typename?: 'PageAlarms';
  content: Array<Alarm>;
  totalElements: Scalars['Int'];
};

export class PageAlertTypes {
  __typename?: 'PageAlertTypes';
  content: Array<AlertType>;
  totalElements: Scalars['Int'];
};

export class PageAlerts {
  __typename?: 'PageAlerts';
  content: Array<Alert>;
  totalElements?: Maybe<Scalars['Int']>;
};

export class PageAssists {
  __typename?: 'PageAssists';
  content: Array<Assistance>;
  totalElements: Scalars['Int'];
};

export class PageAudios {
  __typename?: 'PageAudios';
  content: Array<Audio>;
  totalElements?: Maybe<Scalars['Int']>;
};

export class PageCall {
  __typename?: 'PageCall';
  content: Array<Call>;
  totalElements: Scalars['Int'];
};

export class PageCourtOnDuty {
  __typename?: 'PageCourtOnDuty';
  content?: Maybe<Array<Maybe<CourtOnDuty>>>;
  totalElements?: Maybe<Scalars['Int']>;
};

export class PageLawyer {
  __typename?: 'PageLawyer';
  content: Array<Lawyer>;
  totalElements?: Maybe<Scalars['Int']>;
};

export class PageLawyerList {
  __typename?: 'PageLawyerList';
  content?: Maybe<Array<Maybe<LawyerList>>>;
  totalElements: Scalars['Int'];
};

export class PageLawyerListEntry {
  __typename?: 'PageLawyerListEntry';
  content?: Maybe<Array<Maybe<LawyerListEntry>>>;
  totalElements: Scalars['Int'];
};

export class PageProfiles {
  __typename?: 'PageProfiles';
  content: Array<Profile>;
  totalElements: Scalars['Int'];
};

export class PageUsers {
  __typename?: 'PageUsers';
  content: Array<User>;
  totalElements: Scalars['Int'];
};

export class PageWatches {
  __typename?: 'PageWatches';
  content: Array<Watch>;
  totalElements: Scalars['Int'];
};

export class Pagination {
  columns?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

export class Profile {
  __typename?: 'Profile';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  permissions: Scalars['String'];
};

export class ProfileInput {
  description: Scalars['String'];
  name: Scalars['String'];
  permissions: Scalars['String'];
};

export class Query {
  __typename?: 'Query';
  emailJustify?: Maybe<Scalars['Boolean']>;
  emailReminder?: Maybe<Scalars['Boolean']>;
  exportAlertExcel?: Maybe<Scalars['File']>;
  exportList: Scalars['File'];
  exportWatchesExcel: Scalars['File'];
  findAlarms: PageAlarms;
  findAlert?: Maybe<Alert>;
  findAlertType?: Maybe<AlertType>;
  findAlertTypes: PageAlertTypes;
  findAlerts: PageAlerts;
  findAssists: PageAssists;
  findAudios: PageAudios;
  findAvailableLawyers: Array<Lawyer>;
  findCourts?: Maybe<PageCourtOnDuty>;
  findJudicialDistrict?: Maybe<JudicialDistrict>;
  findJudicialDistricts: JudicialDistrictPage;
  findLawyer?: Maybe<Lawyer>;
  findLawyers: PageLawyer;
  findLawyersByQuery: Array<Lawyer>;
  findLawyersListEntry?: Maybe<PageLawyerListEntry>;
  findLawyersLists?: Maybe<PageLawyerList>;
  findListCalls?: Maybe<PageCall>;
  findMenus?: Maybe<Array<Maybe<Menu>>>;
  findMenusOptions: Array<Menu>;
  findMissingAudios: Scalars['Boolean'];
  findProfiles?: Maybe<PageProfiles>;
  findSettings: Array<Maybe<CategorySettings>>;
  findUsers: PageUsers;
  findWatch: Watch;
  findWatches: PageWatches;
  getAlertTypes: Array<AlertType>;
  getAudio: Audio;
  getAudios?: Maybe<Array<Maybe<Audio>>>;
  getJudicialDistricts: Array<JudicialDistrict>;
  getLawyers: Array<Lawyer>;
  getLawyersByJudicialDistrict: Array<Lawyer>;
  getLawyersList?: Maybe<LawyerList>;
  getLawyersLists?: Maybe<Array<Maybe<LawyerList>>>;
  getMe: User;
  getMenu: Menu;
  getProfile: Profile;
  getProfiles: Array<Profile>;
  getSetting: Setting;
  informEmptyWatches: Scalars['String'];
  login?: Maybe<Scalars['String']>;
  renovateToken: Scalars['String'];
  sendNotificationCallNotAnswered: Scalars['Boolean'];
  sendNotificationEventNotAnswered: Scalars['Boolean'];
  showIsUrgent: Scalars['Boolean'];
  supplant?: Maybe<Scalars['String']>;
  testTypes?: Maybe<Scalars['Boolean']>;
  uiConfig?: Maybe<UiConfig>;
  user?: Maybe<User>;
};


export class QueryEmailJustifyArgs {
  emails: Array<Scalars['String']>;
  type: Scalars['Int'];
};


export class QueryEmailReminderArgs {
  emails: Array<Scalars['String']>;
  type: Scalars['Int'];
};


export class QueryExportAlertExcelArgs {
  pagination?: InputMaybe<Pagination>;
};


export class QueryExportListArgs {
  list: Scalars['Int'];
};


export class QueryExportWatchesExcelArgs {
  pagination: Pagination;
};


export class QueryFindAlarmsArgs {
  pagination?: InputMaybe<Pagination>;
};


export class QueryFindAlertArgs {
  id: Scalars['Int'];
};


export class QueryFindAlertTypeArgs {
  id?: InputMaybe<Scalars['Int']>;
};


export class QueryFindAlertTypesArgs {
  direction?: InputMaybe<Scalars['Int']>;
  field?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};


export class QueryFindAlertsArgs {
  pagination?: InputMaybe<Pagination>;
};


export class QueryFindAssistsArgs {
  pagination: Pagination;
};


export class QueryFindAudiosArgs {
  pagination?: InputMaybe<Pagination>;
};


export class QueryFindAvailableLawyersArgs {
  list?: InputMaybe<Scalars['Int']>;
};


export class QueryFindCourtsArgs {
  pagination?: InputMaybe<Pagination>;
};


export class QueryFindJudicialDistrictArgs {
  id?: InputMaybe<Scalars['Int']>;
};


export class QueryFindJudicialDistrictsArgs {
  pagination?: InputMaybe<Pagination>;
};


export class QueryFindLawyerArgs {
  id?: InputMaybe<Scalars['Int']>;
};


export class QueryFindLawyersArgs {
  pagination?: InputMaybe<Pagination>;
};


export class QueryFindLawyersByQueryArgs {
  query?: InputMaybe<Scalars['String']>;
};


export class QueryFindLawyersListEntryArgs {
  pagination?: InputMaybe<Pagination>;
};


export class QueryFindLawyersListsArgs {
  pagination?: InputMaybe<Pagination>;
};


export class QueryFindListCallsArgs {
  list: Scalars['Int'];
  pagination: Pagination;
  type: CallType;
};


export class QueryFindMenusArgs {
  pagination?: InputMaybe<Pagination>;
};


export class QueryFindProfilesArgs {
  pagination?: InputMaybe<Pagination>;
};


export class QueryFindSettingsArgs {
  pagination: Pagination;
};


export class QueryFindUsersArgs {
  pagination: Pagination;
};


export class QueryFindWatchArgs {
  id?: InputMaybe<Scalars['Int']>;
};


export class QueryFindWatchesArgs {
  pagination?: InputMaybe<Pagination>;
};


export class QueryGetAudioArgs {
  id?: InputMaybe<Scalars['Int']>;
};


export class QueryGetLawyersByJudicialDistrictArgs {
  judicialDistrict?: InputMaybe<Scalars['Int']>;
};


export class QueryGetLawyersListArgs {
  id: Scalars['Int'];
};


export class QueryGetMenuArgs {
  id?: InputMaybe<Scalars['Int']>;
};


export class QueryGetProfileArgs {
  id: Scalars['Int'];
};


export class QueryGetSettingArgs {
  id: Scalars['Int'];
};


export class QueryInformEmptyWatchesArgs {
  end?: InputMaybe<Scalars['DateTime']>;
  start?: InputMaybe<Scalars['DateTime']>;
};


export class QueryLoginArgs {
  password: Scalars['String'];
  username: Scalars['String'];
};


export class QuerySendNotificationCallNotAnsweredArgs {
  id: Scalars['Int'];
};


export class QuerySendNotificationEventNotAnsweredArgs {
  id: Scalars['Int'];
};


export class QuerySupplantArgs {
  id?: InputMaybe<Scalars['Int']>;
};


export class QueryTestTypesArgs {
  filter?: InputMaybe<Filter>;
  options?: InputMaybe<SettingsOptionsInput>;
  userAlarm?: InputMaybe<UserAlarmInput>;
};


export class QueryUserArgs {
  id: Scalars['Int'];
};

export enum ReplacementStatus {
  Accepted = 'ACCEPTED',
  None = 'NONE',
  NotAnswers = 'NOT_ANSWERS',
  RejectAssistance = 'REJECT_ASSISTANCE',
  RejectShift = 'REJECT_SHIFT'
}

export class Setting {
  __typename?: 'Setting';
  category: Scalars['String'];
  default: Scalars['String'];
  editable: Scalars['Boolean'];
  group: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  options: SettingsOptions;
  position: Scalars['Int'];
  serverKey: Scalars['String'];
  type: SettingsType;
  value?: Maybe<Scalars['String']>;
  webKey: Scalars['String'];
};

export class SettingInput {
  category: Scalars['String'];
  default?: InputMaybe<Scalars['String']>;
  group: Scalars['String'];
  name: Scalars['String'];
  options: SettingsOptionsInput;
  position: Scalars['Int'];
  serverKey?: InputMaybe<Scalars['String']>;
  type: SettingsType;
  webKey?: InputMaybe<Scalars['String']>;
};

export class SettingsOptions {
  __typename?: 'SettingsOptions';
  decimals?: Maybe<Scalars['Int']>;
  list?: Maybe<Array<Maybe<Scalars['String']>>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  pattern?: Maybe<Scalars['String']>;
};

export class SettingsOptionsInput {
  decimals?: InputMaybe<Scalars['Int']>;
  list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  pattern?: InputMaybe<Scalars['String']>;
};

export enum SettingsType {
  Boolean = 'BOOLEAN',
  Html = 'HTML',
  Numeric = 'NUMERIC',
  Select = 'SELECT',
  String = 'STRING',
  Textarea = 'TEXTAREA'
}

export type SimpleEvent = Call | Event;

export class UiConfig {
  __typename?: 'UIConfig';
  demoMode: Scalars['Boolean'];
  importLawyers: Scalars['Boolean'];
  importList: Scalars['Boolean'];
  importWatches: Scalars['Boolean'];
  logo: Scalars['String'];
  showWatchesOrder: Scalars['Boolean'];
  title: Scalars['String'];
  updateLawyers: Scalars['Boolean'];
};

export class User {
  __typename?: 'User';
  alarms?: Maybe<Array<UserAlarm>>;
  contactLastName?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['Int'];
  identification: Scalars['String'];
  judicialDistricts: Array<JudicialDistrict>;
  judicialDistrictsIds: Array<Scalars['Int']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  profile: Profile;
  telegram: Scalars['String'];
  username: Scalars['String'];
};

export class UserAlarm {
  __typename?: 'UserAlarm';
  alarm: Scalars['Int'];
  email: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  sms: Scalars['Boolean'];
  telegram: Scalars['Boolean'];
};

export class UserAlarmInput {
  alarm: Scalars['Int'];
  email?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['Boolean']>;
  sms?: InputMaybe<Scalars['Boolean']>;
  telegram?: InputMaybe<Scalars['Boolean']>;
};

export class UserInput {
  alarms: Array<UserAlarmInput>;
  contactLastName?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  identification: Scalars['String'];
  judicialDistricts: Array<Scalars['Int']>;
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  pin?: InputMaybe<Scalars['String']>;
  profile: Scalars['Int'];
  telegram?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export class Watch {
  __typename?: 'Watch';
  alertType: AlertType;
  alias: AlertType;
  allEvents: Array<SimpleEvent>;
  calls: Array<Call>;
  id: Scalars['Int'];
  judicialDistrict: JudicialDistrict;
  lawyer: Lawyer;
  order?: Maybe<Scalars['Int']>;
  start: Scalars['DateTime'];
  status: WatchStatus;
  substitution: Scalars['Boolean'];
};

export class WatchInput {
  alertType: Scalars['Int'];
  end: Scalars['DateTime'];
  judicialDistrict: Scalars['Int'];
  lawyer: Scalars['Int'];
  start: Scalars['DateTime'];
};

export enum WatchStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Ok = 'OK',
  SearchingSubstitute = 'SEARCHING_SUBSTITUTE'
}

export type AlertsTableQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;


export type AlertsTableQuery = { __typename?: 'Query', alerts: { __typename?: 'PageAlerts', totalElements?: number | null, content: Array<{ __typename?: 'Alert', id: number, status: AlertStatus, date: Date, numCalls: number, replacementStatus: ReplacementStatus, incompatible: boolean, start: Date, alertType: { __typename?: 'AlertType', name: string }, alias: { __typename?: 'AlertType', name: string }, judicialDistrict: { __typename?: 'JudicialDistrict', name: string }, user?: { __typename?: 'User', name: string } | null }> } };

export type AlertComponentQueryVariables = Exact<{ [key: string]: never; }>;


export type AlertComponentQuery = { __typename?: 'Query', alertTypes: Array<{ __typename?: 'AlertType', id: number, name: string }>, judicialDistricts: Array<{ __typename?: 'JudicialDistrict', id: number, name: string }> };

export type AlertDetailsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AlertDetailsQuery = { __typename?: 'Query', alert?: { __typename?: 'Alert', id: number, status: AlertStatus, date: Date, audio?: string | null, diligence: string, start: Date, detained: number, alertType: { __typename?: 'AlertType', name: string }, judicialDistrict: { __typename?: 'JudicialDistrict', name: string }, user?: { __typename?: 'User', name: string } | null, interruptedBy?: { __typename?: 'User', name: string } | null, allEvents?: Array<{ __typename: 'Call', id: number, date: Date, duration: number, phone: string, result: CallResult, audio?: string | null, callType: CallType, unattended: boolean, lawyer: { __typename?: 'Lawyer', name: string, lastName: string, lawyerLicense: string } } | { __typename: 'Event', id: number, date: Date, type: EventType, audio?: string | null, unattended: boolean, lawyer: { __typename?: 'Lawyer', name: string, lastName: string, lawyerLicense: string } } | null> | null } | null };

export type AlertsFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type AlertsFiltersQuery = { __typename?: 'Query', judicialDistricts: Array<{ __typename?: 'JudicialDistrict', id: number, name: string }>, alertsType: Array<{ __typename?: 'AlertType', id: number, name: string }> };

export type ExportAlertExcelQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type ExportAlertExcelQuery = { __typename?: 'Query', excel?: any | null };

export type InterruptAlertMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type InterruptAlertMutation = { __typename?: 'Mutation', interruptAlert?: boolean | null };

export type FindOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindOptionsQuery = { __typename?: 'Query', showIsUrgent: boolean, options: Array<{ __typename?: 'Menu', id: number, incompatible?: boolean | null, askDetained?: boolean | null, isRaid: boolean, alertType?: { __typename?: 'AlertType', id: number, name: string } | null, judicialDistrict: { __typename?: 'JudicialDistrict', id: number, name: string } }> };

export type CreateNewAlertMutationVariables = Exact<{
  alert: AlertInput;
}>;


export type CreateNewAlertMutation = { __typename?: 'Mutation', createAlert: { __typename?: 'Alert', id: number } };

export type ContinueAlertMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type ContinueAlertMutation = { __typename?: 'Mutation', continueAlert?: boolean | null };

export type AssistsTableQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type AssistsTableQuery = { __typename?: 'Query', assists: { __typename?: 'PageAssists', totalElements: number, content: Array<{ __typename?: 'Assistance', date: Date, caller: string, alertType: { __typename?: 'AlertType', name: string }, lawyer: { __typename?: 'Lawyer', fullName: string } }> } };

export type AssistsFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type AssistsFiltersQuery = { __typename?: 'Query', alertTypes: Array<{ __typename?: 'AlertType', name: string, id: number }> };

export type AudiosTableQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;


export type AudiosTableQuery = { __typename?: 'Query', audios: { __typename?: 'PageAudios', totalElements?: number | null, content: Array<{ __typename?: 'Audio', id: number, name: string, code: string, text: string, file: string }> } };

export type GetAudioQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetAudioQuery = { __typename?: 'Query', audio: { __typename?: 'Audio', id: number, name: string, code: string, text: string, file: string } };

export type SaveAudioMutationVariables = Exact<{
  audio: AudioInput;
  id?: InputMaybe<Scalars['Int']>;
}>;


export type SaveAudioMutation = { __typename?: 'Mutation', saveAudio: { __typename?: 'Audio', id: number } };

export type UpdateAudioFileMutationVariables = Exact<{
  file: Scalars['Upload'];
  id: Scalars['Int'];
}>;


export type UpdateAudioFileMutation = { __typename?: 'Mutation', updateAudioFile: boolean };

export type GenerateAudioMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GenerateAudioMutation = { __typename?: 'Mutation', generateAudio: boolean };

export type DeleteAudioMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAudioMutation = { __typename?: 'Mutation', deleteAudio: { __typename?: 'Audio', id: number } };

export type ListCourtsQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type ListCourtsQuery = { __typename?: 'Query', courts?: { __typename?: 'PageCourtOnDuty', totalElements?: number | null, content?: Array<{ __typename?: 'CourtOnDuty', start?: Date | null, end?: Date | null, court?: { __typename?: 'User', name: string, email: string, phone: string, profile: { __typename?: 'Profile', description: string }, judicialDistricts: Array<{ __typename?: 'JudicialDistrict', name: string }> } | null, profile?: { __typename?: 'Profile', description: string } | null } | null> | null } | null };

export type DistrictsTableQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type DistrictsTableQuery = { __typename?: 'Query', districts: { __typename?: 'JudicialDistrictPage', totalElements?: number | null, content: Array<{ __typename?: 'JudicialDistrict', id: number, name: string }> } };

export type InformEmptyWatchesQueryVariables = Exact<{
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;


export type InformEmptyWatchesQuery = { __typename?: 'Query', informEmptyWatches: string };

export type LawyersTableQueryVariables = Exact<{
  page?: InputMaybe<Pagination>;
}>;


export type LawyersTableQuery = { __typename?: 'Query', lawyers: { __typename?: 'PageLawyer', totalElements?: number | null, content: Array<{ __typename?: 'Lawyer', id: number, name: string, lastName: string, lawyerLicense: string, email: string, phoneNumber: string, fullName: string, judicialDistrict: { __typename?: 'JudicialDistrict', name: string } }> } };

export type SaveLawyerMutationVariables = Exact<{
  lawyer: LawyerInput;
  id?: InputMaybe<Scalars['Int']>;
}>;


export type SaveLawyerMutation = { __typename?: 'Mutation', saveLawyer: { __typename?: 'Lawyer', id: number } };

export type LawyerComponentQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type LawyerComponentQuery = { __typename?: 'Query', lawyer?: { __typename?: 'Lawyer', id: number, name: string, email: string, lastName: string, telegram: string, phoneNumber: string, lawyerLicense: string, identification: string, blockUpdate: boolean, judicialDistrict: { __typename?: 'JudicialDistrict', id: number } } | null, judicialDistricts: Array<{ __typename?: 'JudicialDistrict', name: string, id: number }> };

export type ImportLawyersMutationVariables = Exact<{
  file?: InputMaybe<Scalars['Upload']>;
}>;


export type ImportLawyersMutation = { __typename?: 'Mutation', importLawyers?: Array<{ __typename?: 'Lawyer', lawyerLicense: string } | null> | null };

export type FilterLawyerQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;


export type FilterLawyerQuery = { __typename?: 'Query', lawyers: { __typename?: 'PageLawyer', totalElements?: number | null, content: Array<{ __typename?: 'Lawyer', id: number, fullName: string }> } };

export type ListsTableQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;


export type ListsTableQuery = { __typename?: 'Query', lists?: { __typename?: 'PageLawyerList', totalElements: number, content?: Array<{ __typename?: 'LawyerList', name: string, id: number, alertType: { __typename?: 'AlertType', name: string }, judicialDistrict: { __typename?: 'JudicialDistrict', name: string } } | null> | null } | null };

export type LawyerListComponentQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type LawyerListComponentQuery = { __typename?: 'Query', lawyerList?: { __typename?: 'LawyerList', id: number, name: string, alertType: number, judicialDistrict: number } | null, judicialDistricts: Array<{ __typename?: 'JudicialDistrict', id: number, name: string }>, alertTypes: Array<{ __typename?: 'AlertType', id: number, name: string }> };

export type SaveListMutationVariables = Exact<{
  lawyerList?: InputMaybe<LawyerListInput>;
}>;


export type SaveListMutation = { __typename?: 'Mutation', saveLawyersList?: { __typename?: 'LawyerList', id: number } | null };

export type DeleteListMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteListMutation = { __typename?: 'Mutation', deleteLawyerList?: { __typename?: 'LawyerList', id: number } | null };

export type GetLawyerListQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetLawyerListQuery = { __typename?: 'Query', list?: { __typename?: 'LawyerList', id: number, name: string, alertType: { __typename?: 'AlertType', name: string }, judicialDistrict: { __typename?: 'JudicialDistrict', name: string } } | null };

export type ListEntriesTableQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;


export type ListEntriesTableQuery = { __typename?: 'Query', entries?: { __typename?: 'PageLawyerListEntry', totalElements: number, content?: Array<{ __typename?: 'LawyerListEntry', rankSubstitutions: number, rankAuxiliaries: number, lawyer: { __typename?: 'Lawyer', id: number, name: string, lastName: string, lawyerLicense: string } } | null> | null } | null };

export type ListEntryComponentQueryVariables = Exact<{
  list: Scalars['Int'];
}>;


export type ListEntryComponentQuery = { __typename?: 'Query', list?: { __typename?: 'LawyerList', id: number, name: string } | null, lawyers: Array<{ __typename?: 'Lawyer', name: string, lastName: string, id: number, lawyerLicense: string }> };

export type ListEntrySaveMutationVariables = Exact<{
  lawyer?: InputMaybe<Scalars['Int']>;
  list?: InputMaybe<Scalars['Int']>;
}>;


export type ListEntrySaveMutation = { __typename?: 'Mutation', addLawyerListEntry?: { __typename?: 'LawyerListEntry', lawyerId: number } | null };

export type DeleteListEntryMutationVariables = Exact<{
  lawyer: Scalars['Int'];
  list?: InputMaybe<Scalars['Int']>;
}>;


export type DeleteListEntryMutation = { __typename?: 'Mutation', deleteLawyerListEntry?: boolean | null };

export type ImportListsMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type ImportListsMutation = { __typename?: 'Mutation', importLists?: boolean | null };

export type ImportListMutationVariables = Exact<{
  file?: InputMaybe<Scalars['Upload']>;
  id: Scalars['Int'];
}>;


export type ImportListMutation = { __typename?: 'Mutation', importList?: boolean | null };

export type DownLawyerEntryValueMutationVariables = Exact<{
  lawyer: Scalars['Int'];
  list: Scalars['Int'];
  substitution: Scalars['Int'];
  assistance: Scalars['Int'];
}>;


export type DownLawyerEntryValueMutation = { __typename?: 'Mutation', downLawyerEntryValue?: boolean | null };

export type ExportListQueryVariables = Exact<{
  list: Scalars['Int'];
}>;


export type ExportListQuery = { __typename?: 'Query', file: any };

export type FindListCallsQueryVariables = Exact<{
  id: Scalars['Int'];
  type: CallType;
  pagination: Pagination;
}>;


export type FindListCallsQuery = { __typename?: 'Query', calls?: { __typename?: 'PageCall', totalElements: number, content: Array<{ __typename?: 'Call', date: Date, phone: string, result: CallResult, callType: CallType, duration: number, lawyer: { __typename?: 'Lawyer', fullName: string }, alert?: { __typename?: 'Alert', id: number } | null, watch?: { __typename?: 'Watch', id: number, start: Date } | null }> } | null };

export type FindMenusQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;


export type FindMenusQuery = { __typename?: 'Query', menus?: Array<{ __typename?: 'Menu', id: number, order: number, incompatible?: boolean | null, parent?: { __typename?: 'Menu', id: number } | null, alertType?: { __typename?: 'AlertType', name: string } | null, judicialDistrict: { __typename?: 'JudicialDistrict', id: number, name: string }, lawyerList?: { __typename?: 'LawyerList', name: string } | null, profile: { __typename?: 'Profile', id: number, description: string }, audio: { __typename?: 'Audio', id: number, name: string, file: string } } | null> | null };

export type GetMenuQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetMenuQuery = { __typename?: 'Query', menu: { __typename?: 'Menu', id: number, order: number, incompatible?: boolean | null, start?: string | null, end?: string | null, isRaid: boolean, askDetained?: boolean | null, detainedPerLawyer?: number | null, alertType?: { __typename?: 'AlertType', name: string, id: number } | null, judicialDistrict: { __typename?: 'JudicialDistrict', name: string, id: number }, audio: { __typename?: 'Audio', id: number, name: string, code: string }, parent?: { __typename?: 'Menu', id: number } | null, profile: { __typename?: 'Profile', id: number, description: string }, lawyerList?: { __typename?: 'LawyerList', id: number, name: string } | null, alternativeWatch?: { __typename?: 'AlertType', id: number, name: string } | null, alias?: { __typename?: 'AlertType', id: number, name: string } | null, alertTypeMultiple?: { __typename?: 'AlertType', id: number, name: string } | null, duplicate?: { __typename?: 'AlertType', id: number, name: string } | null } };

export type MenusFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type MenusFiltersQuery = { __typename?: 'Query', judicialDistricts: Array<{ __typename?: 'JudicialDistrict', name: string, id: number }>, profiles: Array<{ __typename?: 'Profile', id: number, description: string }>, audios?: Array<{ __typename?: 'Audio', id: number, code: string, name: string } | null> | null, alertTypes: Array<{ __typename?: 'AlertType', id: number, name: string }>, lists?: Array<{ __typename?: 'LawyerList', id: number, name: string } | null> | null };

export type MenuPositionMutationVariables = Exact<{
  id: Scalars['Int'];
  position: Scalars['Int'];
}>;


export type MenuPositionMutation = { __typename?: 'Mutation', menuPosition: { __typename?: 'Menu', id: number } };

export type MenuSaveMutationVariables = Exact<{
  menu: MenuInput;
  id: Scalars['Int'];
}>;


export type MenuSaveMutation = { __typename?: 'Mutation', saveMenu: { __typename?: 'Menu', id: number } };

export type MenuCreateMutationVariables = Exact<{
  judicialDistrict: Scalars['Int'];
  profile: Scalars['Int'];
  parent?: InputMaybe<Scalars['Int']>;
}>;


export type MenuCreateMutation = { __typename?: 'Mutation', createMenu: { __typename?: 'Menu', id: number } };

export type DeleteMenuMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteMenuMutation = { __typename?: 'Mutation', deleteMenu: { __typename?: 'Menu', id: number } };

export type SendNotificationCallNotAnsweredQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SendNotificationCallNotAnsweredQuery = { __typename?: 'Query', sendNotificationCallNotAnswered: boolean };

export type SendNotificationEventNotAnsweredQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SendNotificationEventNotAnsweredQuery = { __typename?: 'Query', sendNotificationEventNotAnswered: boolean };

export type FindSettingsQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type FindSettingsQuery = { __typename?: 'Query', settings: Array<{ __typename?: 'CategorySettings', name?: string | null, settings: Array<{ __typename?: 'Setting', id: number, category: string, group: string, name: string, type: SettingsType, position: number, value?: string | null, default: string, options: { __typename?: 'SettingsOptions', decimals?: number | null, list?: Array<string | null> | null, max?: number | null, min?: number | null, pattern?: string | null } }> } | null> };

export type GetSettingQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSettingQuery = { __typename?: 'Query', setting: { __typename?: 'Setting', id: number, name: string, value?: string | null, webKey: string, serverKey: string, group: string, category: string, editable: boolean, type: SettingsType, position: number, default: string, options: { __typename?: 'SettingsOptions', decimals?: number | null, list?: Array<string | null> | null, max?: number | null, min?: number | null, pattern?: string | null } } };

export type SaveSettingsMutationVariables = Exact<{
  setting: SettingInput;
  id?: InputMaybe<Scalars['Int']>;
}>;


export type SaveSettingsMutation = { __typename?: 'Mutation', saveSetting: { __typename?: 'Setting', id: number } };

export type SaveSettingValueMutationVariables = Exact<{
  value: Scalars['String'];
  id: Scalars['Int'];
}>;


export type SaveSettingValueMutation = { __typename?: 'Mutation', saveSettingValue: { __typename?: 'Setting', id: number } };

export type ApplySettingsMutationVariables = Exact<{ [key: string]: never; }>;


export type ApplySettingsMutation = { __typename?: 'Mutation', applySettings?: boolean | null };

export type UiConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type UiConfigQuery = { __typename?: 'Query', uiConfig?: { __typename?: 'UIConfig', importLawyers: boolean, importList: boolean, importWatches: boolean, logo: string, showWatchesOrder: boolean, title: string, updateLawyers: boolean, demoMode: boolean } | null };

export type FindAlarmsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;


export type FindAlarmsQuery = { __typename?: 'Query', alarms: { __typename?: 'PageAlarms', totalElements: number, content: Array<{ __typename?: 'Alarm', id: number, name: string }> } };

export type FindProfilesQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type FindProfilesQuery = { __typename?: 'Query', profiles?: { __typename?: 'PageProfiles', totalElements: number, content: Array<{ __typename?: 'Profile', id: number, name: string, permissions: string, description: string }> } | null };

export type SaveProfileMutationVariables = Exact<{
  profile: ProfileInput;
  id?: InputMaybe<Scalars['Int']>;
}>;


export type SaveProfileMutation = { __typename?: 'Mutation', saveProfile?: { __typename?: 'Profile', id: number } | null };

export type GetProfileQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetProfileQuery = { __typename?: 'Query', profile: { __typename?: 'Profile', id: number, name: string, description: string, permissions: string } };

export type UsersTableQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type UsersTableQuery = { __typename?: 'Query', users: { __typename?: 'PageUsers', totalElements: number, content: Array<{ __typename?: 'User', id: number, name: string, contactName?: string | null, contactLastName?: string | null, username: string, email: string, identification: string, phone: string }> } };

export type SupplantQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type SupplantQuery = { __typename?: 'Query', supplant?: string | null };

export type LoginQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginQuery = { __typename?: 'Query', login?: string | null };

export type RenovateTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type RenovateTokenQuery = { __typename?: 'Query', token: string };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', user: { __typename?: 'User', name: string, email: string, phone: string } };

export type ChangePasswordMutationVariables = Exact<{
  old: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  pin?: InputMaybe<Scalars['String']>;
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changeUserPassword?: boolean | null };

export type ImportUsersMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type ImportUsersMutation = { __typename?: 'Mutation', importUsers: boolean };

export type CreateUserMutationVariables = Exact<{
  user: UserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: number } };

export type UpdateUserMutationVariables = Exact<{
  user: UserInput;
  id: Scalars['Int'];
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: number } };

export type GetUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetUserQuery = { __typename?: 'Query', user?: { __typename?: 'User', name: string, contactLastName?: string | null, contactName?: string | null, email: string, phone: string, telegram: string, username: string, identification: string, profile: { __typename?: 'Profile', id: number }, judicialDistricts: Array<{ __typename?: 'JudicialDistrict', id: number }>, alarms?: Array<{ __typename?: 'UserAlarm', telegram: boolean, phone: boolean, email: boolean, sms: boolean, alarm: number }> | null } | null, profiles: Array<{ __typename?: 'Profile', id: number, description: string }>, districts: Array<{ __typename?: 'JudicialDistrict', id: number, name: string }>, alarms: { __typename?: 'PageAlarms', totalElements: number, content: Array<{ __typename?: 'Alarm', id: number, name: string }> } };

export type WatchesTableQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;


export type WatchesTableQuery = { __typename?: 'Query', watches: { __typename?: 'PageWatches', totalElements: number, content: Array<{ __typename?: 'Watch', start: Date, id: number, order?: number | null, status: WatchStatus, substitution: boolean, alias: { __typename?: 'AlertType', id: number, name: string }, judicialDistrict: { __typename?: 'JudicialDistrict', name: string, id: number }, lawyer: { __typename?: 'Lawyer', fullName: string }, alertType: { __typename?: 'AlertType', id: number, name: string }, allEvents: Array<{ __typename: 'Call', date: Date, duration: number, phone: string, result: CallResult, callType: CallType, lawyer: { __typename?: 'Lawyer', name: string, lastName: string, lawyerLicense: string } } | { __typename: 'Event', date: Date, type: EventType, lawyer: { __typename?: 'Lawyer', name: string, lastName: string, lawyerLicense: string } }> }> } };

export type WatchesFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type WatchesFiltersQuery = { __typename?: 'Query', judicialDistricts: Array<{ __typename?: 'JudicialDistrict', name: string, id: number }>, alertsType: Array<{ __typename?: 'AlertType', name: string, id: number }>, lawyers: Array<{ __typename?: 'Lawyer', fullName: string, id: number }> };

export type ImportWatchesMutationVariables = Exact<{
  excel?: InputMaybe<Scalars['Upload']>;
}>;


export type ImportWatchesMutation = { __typename?: 'Mutation', importWatches?: Array<{ __typename?: 'Watch', id: number } | null> | null };

export type WatchChangeMutationVariables = Exact<{
  watch: Scalars['Int'];
  lawyer: Scalars['Int'];
}>;


export type WatchChangeMutation = { __typename?: 'Mutation', watchChange?: boolean | null };

export type FindWatchSubstituteMutationVariables = Exact<{
  watch: Scalars['Int'];
}>;


export type FindWatchSubstituteMutation = { __typename?: 'Mutation', findWatchSubstitute?: boolean | null };

export type CancelWatchMutationVariables = Exact<{
  watch: Scalars['Int'];
}>;


export type CancelWatchMutation = { __typename?: 'Mutation', cancelWatch?: boolean | null };

export type ExportWatchesToExcelQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type ExportWatchesToExcelQuery = { __typename?: 'Query', excel: any };

export type WatchQueryVariables = Exact<{ [key: string]: never; }>;


export type WatchQuery = { __typename?: 'Query', judicialDistricts: Array<{ __typename?: 'JudicialDistrict', id: number, name: string }>, alertTypes: Array<{ __typename?: 'AlertType', id: number, name: string }>, lawyers: { __typename?: 'PageLawyer', content: Array<{ __typename?: 'Lawyer', fullName: string, id: number }> } };

export type CreateWatchMutationVariables = Exact<{
  watch?: InputMaybe<WatchInput>;
}>;


export type CreateWatchMutation = { __typename?: 'Mutation', createWatch: { __typename?: 'Watch', id: number } };

export const AlertsTableDocument = gql`
    query AlertsTable($pagination: Pagination) {
  alerts: findAlerts(pagination: $pagination) {
    totalElements
    content {
      id
      status
      date
      numCalls
      replacementStatus
      incompatible
      alertType {
        name
      }
      alias {
        name
      }
      judicialDistrict {
        name
      }
      user {
        name
      }
      start
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AlertsTableGQL extends Apollo.Query<AlertsTableQuery, AlertsTableQueryVariables> {
    override document = AlertsTableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AlertComponentDocument = gql`
    query AlertComponent {
  alertTypes: getAlertTypes {
    id
    name
  }
  judicialDistricts: getJudicialDistricts {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AlertComponentGQL extends Apollo.Query<AlertComponentQuery, AlertComponentQueryVariables> {
    override document = AlertComponentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AlertDetailsDocument = gql`
    query AlertDetails($id: Int!) {
  alert: findAlert(id: $id) {
    id
    status
    date
    audio
    diligence
    start
    alertType {
      name
    }
    judicialDistrict {
      name
    }
    user {
      name
    }
    interruptedBy {
      name
    }
    detained
    allEvents {
      __typename
      ... on Call {
        id
        date
        duration
        phone
        result
        audio
        callType
        unattended
        lawyer {
          name
          lastName
          lawyerLicense
        }
      }
      ... on Event {
        id
        date
        type
        audio
        unattended
        lawyer {
          name
          lastName
          lawyerLicense
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AlertDetailsGQL extends Apollo.Query<AlertDetailsQuery, AlertDetailsQueryVariables> {
    override document = AlertDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AlertsFiltersDocument = gql`
    query AlertsFilters {
  judicialDistricts: getJudicialDistricts {
    id
    name
  }
  alertsType: getAlertTypes {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AlertsFiltersGQL extends Apollo.Query<AlertsFiltersQuery, AlertsFiltersQueryVariables> {
    override document = AlertsFiltersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExportAlertExcelDocument = gql`
    query ExportAlertExcel($pagination: Pagination!) {
  excel: exportAlertExcel(pagination: $pagination)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExportAlertExcelGQL extends Apollo.Query<ExportAlertExcelQuery, ExportAlertExcelQueryVariables> {
    override document = ExportAlertExcelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InterruptAlertDocument = gql`
    mutation InterruptAlert($id: Int) {
  interruptAlert(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InterruptAlertGQL extends Apollo.Mutation<InterruptAlertMutation, InterruptAlertMutationVariables> {
    override document = InterruptAlertDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindOptionsDocument = gql`
    query FindOptions {
  showIsUrgent
  options: findMenusOptions {
    id
    alertType {
      id
      name
    }
    incompatible
    judicialDistrict {
      id
      name
    }
    askDetained
    isRaid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindOptionsGQL extends Apollo.Query<FindOptionsQuery, FindOptionsQueryVariables> {
    override document = FindOptionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateNewAlertDocument = gql`
    mutation CreateNewAlert($alert: AlertInput!) {
  createAlert(alertInput: $alert) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateNewAlertGQL extends Apollo.Mutation<CreateNewAlertMutation, CreateNewAlertMutationVariables> {
    override document = CreateNewAlertDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContinueAlertDocument = gql`
    mutation ContinueAlert($id: Int) {
  continueAlert(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContinueAlertGQL extends Apollo.Mutation<ContinueAlertMutation, ContinueAlertMutationVariables> {
    override document = ContinueAlertDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssistsTableDocument = gql`
    query AssistsTable($pagination: Pagination!) {
  assists: findAssists(pagination: $pagination) {
    totalElements
    content {
      date
      caller
      alertType {
        name
      }
      lawyer {
        fullName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistsTableGQL extends Apollo.Query<AssistsTableQuery, AssistsTableQueryVariables> {
    override document = AssistsTableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssistsFiltersDocument = gql`
    query AssistsFilters {
  alertTypes: getAlertTypes {
    name
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistsFiltersGQL extends Apollo.Query<AssistsFiltersQuery, AssistsFiltersQueryVariables> {
    override document = AssistsFiltersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AudiosTableDocument = gql`
    query AudiosTable($pagination: Pagination) {
  audios: findAudios(pagination: $pagination) {
    totalElements
    content {
      id
      name
      code
      text
      file
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AudiosTableGQL extends Apollo.Query<AudiosTableQuery, AudiosTableQueryVariables> {
    override document = AudiosTableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAudioDocument = gql`
    query GetAudio($id: Int!) {
  audio: getAudio(id: $id) {
    id
    name
    code
    text
    file
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAudioGQL extends Apollo.Query<GetAudioQuery, GetAudioQueryVariables> {
    override document = GetAudioDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveAudioDocument = gql`
    mutation SaveAudio($audio: AudioInput!, $id: Int) {
  saveAudio(id: $id, audio: $audio) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveAudioGQL extends Apollo.Mutation<SaveAudioMutation, SaveAudioMutationVariables> {
    override document = SaveAudioDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAudioFileDocument = gql`
    mutation UpdateAudioFile($file: Upload!, $id: Int!) {
  updateAudioFile(id: $id, file: $file)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAudioFileGQL extends Apollo.Mutation<UpdateAudioFileMutation, UpdateAudioFileMutationVariables> {
    override document = UpdateAudioFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateAudioDocument = gql`
    mutation GenerateAudio($id: Int!) {
  generateAudio(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateAudioGQL extends Apollo.Mutation<GenerateAudioMutation, GenerateAudioMutationVariables> {
    override document = GenerateAudioDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAudioDocument = gql`
    mutation DeleteAudio($id: Int!) {
  deleteAudio(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAudioGQL extends Apollo.Mutation<DeleteAudioMutation, DeleteAudioMutationVariables> {
    override document = DeleteAudioDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListCourtsDocument = gql`
    query ListCourts($pagination: Pagination!) {
  courts: findCourts(pagination: $pagination) {
    totalElements
    content {
      court {
        name
        email
        phone
        profile {
          description
        }
        judicialDistricts {
          name
        }
      }
      start
      end
      profile {
        description
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListCourtsGQL extends Apollo.Query<ListCourtsQuery, ListCourtsQueryVariables> {
    override document = ListCourtsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DistrictsTableDocument = gql`
    query DistrictsTable($pagination: Pagination!) {
  districts: findJudicialDistricts(pagination: $pagination) {
    totalElements
    content {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DistrictsTableGQL extends Apollo.Query<DistrictsTableQuery, DistrictsTableQueryVariables> {
    override document = DistrictsTableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InformEmptyWatchesDocument = gql`
    query InformEmptyWatches($start: DateTime!, $end: DateTime!) {
  informEmptyWatches(start: $start, end: $end)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InformEmptyWatchesGQL extends Apollo.Query<InformEmptyWatchesQuery, InformEmptyWatchesQueryVariables> {
    override document = InformEmptyWatchesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LawyersTableDocument = gql`
    query LawyersTable($page: Pagination) {
  lawyers: findLawyers(pagination: $page) {
    totalElements
    content {
      id
      name
      lastName
      lawyerLicense
      email
      phoneNumber
      fullName
      judicialDistrict {
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LawyersTableGQL extends Apollo.Query<LawyersTableQuery, LawyersTableQueryVariables> {
    override document = LawyersTableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveLawyerDocument = gql`
    mutation SaveLawyer($lawyer: LawyerInput!, $id: Int) {
  saveLawyer(lawyer: $lawyer, id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveLawyerGQL extends Apollo.Mutation<SaveLawyerMutation, SaveLawyerMutationVariables> {
    override document = SaveLawyerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LawyerComponentDocument = gql`
    query LawyerComponent($id: Int!) {
  lawyer: findLawyer(id: $id) {
    id
    name
    email
    lastName
    telegram
    phoneNumber
    lawyerLicense
    identification
    blockUpdate
    judicialDistrict {
      id
    }
  }
  judicialDistricts: getJudicialDistricts {
    name
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LawyerComponentGQL extends Apollo.Query<LawyerComponentQuery, LawyerComponentQueryVariables> {
    override document = LawyerComponentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImportLawyersDocument = gql`
    mutation ImportLawyers($file: Upload) {
  importLawyers(excel: $file) {
    lawyerLicense
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ImportLawyersGQL extends Apollo.Mutation<ImportLawyersMutation, ImportLawyersMutationVariables> {
    override document = ImportLawyersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FilterLawyerDocument = gql`
    query FilterLawyer($pagination: Pagination) {
  lawyers: findLawyers(pagination: $pagination) {
    totalElements
    content {
      id
      fullName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FilterLawyerGQL extends Apollo.Query<FilterLawyerQuery, FilterLawyerQueryVariables> {
    override document = FilterLawyerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListsTableDocument = gql`
    query ListsTable($pagination: Pagination) {
  lists: findLawyersLists(pagination: $pagination) {
    totalElements
    content {
      name
      id
      alertType {
        name
      }
      judicialDistrict {
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListsTableGQL extends Apollo.Query<ListsTableQuery, ListsTableQueryVariables> {
    override document = ListsTableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LawyerListComponentDocument = gql`
    query LawyerListComponent($id: Int!) {
  lawyerList: getLawyersList(id: $id) {
    id
    name
    alertType: alertTypeId
    judicialDistrict: judicialDistrictId
  }
  judicialDistricts: getJudicialDistricts {
    id
    name
  }
  alertTypes: getAlertTypes {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LawyerListComponentGQL extends Apollo.Query<LawyerListComponentQuery, LawyerListComponentQueryVariables> {
    override document = LawyerListComponentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveListDocument = gql`
    mutation SaveList($lawyerList: LawyerListInput) {
  saveLawyersList(lawyerList: $lawyerList) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveListGQL extends Apollo.Mutation<SaveListMutation, SaveListMutationVariables> {
    override document = SaveListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteListDocument = gql`
    mutation DeleteList($id: Int!) {
  deleteLawyerList(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteListGQL extends Apollo.Mutation<DeleteListMutation, DeleteListMutationVariables> {
    override document = DeleteListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLawyerListDocument = gql`
    query GetLawyerList($id: Int!) {
  list: getLawyersList(id: $id) {
    alertType {
      name
    }
    judicialDistrict {
      name
    }
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLawyerListGQL extends Apollo.Query<GetLawyerListQuery, GetLawyerListQueryVariables> {
    override document = GetLawyerListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListEntriesTableDocument = gql`
    query ListEntriesTable($pagination: Pagination) {
  entries: findLawyersListEntry(pagination: $pagination) {
    totalElements
    content {
      lawyer {
        id
        name
        lastName
        lawyerLicense
      }
      rankSubstitutions
      rankAuxiliaries
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListEntriesTableGQL extends Apollo.Query<ListEntriesTableQuery, ListEntriesTableQueryVariables> {
    override document = ListEntriesTableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListEntryComponentDocument = gql`
    query ListEntryComponent($list: Int!) {
  list: getLawyersList(id: $list) {
    id
    name
  }
  lawyers: findAvailableLawyers(list: $list) {
    name
    lastName
    id
    lawyerLicense
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListEntryComponentGQL extends Apollo.Query<ListEntryComponentQuery, ListEntryComponentQueryVariables> {
    override document = ListEntryComponentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListEntrySaveDocument = gql`
    mutation ListEntrySave($lawyer: Int, $list: Int) {
  addLawyerListEntry(lawyer: $lawyer, list: $list) {
    lawyerId
    lawyerId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListEntrySaveGQL extends Apollo.Mutation<ListEntrySaveMutation, ListEntrySaveMutationVariables> {
    override document = ListEntrySaveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteListEntryDocument = gql`
    mutation DeleteListEntry($lawyer: Int!, $list: Int) {
  deleteLawyerListEntry(lawyer: $lawyer, list: $list)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteListEntryGQL extends Apollo.Mutation<DeleteListEntryMutation, DeleteListEntryMutationVariables> {
    override document = DeleteListEntryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImportListsDocument = gql`
    mutation ImportLists($file: Upload!) {
  importLists(excel: $file)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ImportListsGQL extends Apollo.Mutation<ImportListsMutation, ImportListsMutationVariables> {
    override document = ImportListsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImportListDocument = gql`
    mutation ImportList($file: Upload, $id: Int!) {
  importList(excel: $file, list: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ImportListGQL extends Apollo.Mutation<ImportListMutation, ImportListMutationVariables> {
    override document = ImportListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DownLawyerEntryValueDocument = gql`
    mutation DownLawyerEntryValue($lawyer: Int!, $list: Int!, $substitution: Int!, $assistance: Int!) {
  downLawyerEntryValue(
    lawyer: $lawyer
    list: $list
    substitution: $substitution
    assistance: $assistance
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DownLawyerEntryValueGQL extends Apollo.Mutation<DownLawyerEntryValueMutation, DownLawyerEntryValueMutationVariables> {
    override document = DownLawyerEntryValueDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExportListDocument = gql`
    query ExportList($list: Int!) {
  file: exportList(list: $list)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExportListGQL extends Apollo.Query<ExportListQuery, ExportListQueryVariables> {
    override document = ExportListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindListCallsDocument = gql`
    query FindListCalls($id: Int!, $type: CallType!, $pagination: Pagination!) {
  calls: findListCalls(type: $type, pagination: $pagination, list: $id) {
    totalElements
    content {
      date
      lawyer {
        fullName
      }
      phone
      result
      callType
      duration
      alert {
        id
      }
      watch {
        id
        start
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindListCallsGQL extends Apollo.Query<FindListCallsQuery, FindListCallsQueryVariables> {
    override document = FindListCallsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindMenusDocument = gql`
    query FindMenus($pagination: Pagination) {
  menus: findMenus(pagination: $pagination) {
    id
    order
    incompatible
    parent {
      id
    }
    alertType {
      name
    }
    judicialDistrict {
      id
      name
    }
    lawyerList {
      name
    }
    profile {
      id
      description
    }
    audio {
      id
      name
      file
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindMenusGQL extends Apollo.Query<FindMenusQuery, FindMenusQueryVariables> {
    override document = FindMenusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMenuDocument = gql`
    query GetMenu($id: Int!) {
  menu: getMenu(id: $id) {
    id
    order
    incompatible
    alertType {
      name
      id
    }
    judicialDistrict {
      name
      id
    }
    audio {
      id
      name
      code
    }
    parent {
      id
    }
    profile {
      id
      description
    }
    lawyerList {
      id
      name
    }
    alternativeWatch {
      id
      name
    }
    alias {
      id
      name
    }
    start
    end
    isRaid
    alertTypeMultiple {
      id
      name
    }
    duplicate {
      id
      name
    }
    askDetained
    detainedPerLawyer
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMenuGQL extends Apollo.Query<GetMenuQuery, GetMenuQueryVariables> {
    override document = GetMenuDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MenusFiltersDocument = gql`
    query MenusFilters {
  judicialDistricts: getJudicialDistricts {
    name
    id
  }
  profiles: getProfiles {
    id
    description
  }
  audios: getAudios {
    id
    code
    name
  }
  alertTypes: getAlertTypes {
    id
    name
  }
  lists: getLawyersLists {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MenusFiltersGQL extends Apollo.Query<MenusFiltersQuery, MenusFiltersQueryVariables> {
    override document = MenusFiltersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MenuPositionDocument = gql`
    mutation MenuPosition($id: Int!, $position: Int!) {
  menuPosition(id: $id, position: $position) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MenuPositionGQL extends Apollo.Mutation<MenuPositionMutation, MenuPositionMutationVariables> {
    override document = MenuPositionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MenuSaveDocument = gql`
    mutation MenuSave($menu: MenuInput!, $id: Int!) {
  saveMenu(menu: $menu, id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MenuSaveGQL extends Apollo.Mutation<MenuSaveMutation, MenuSaveMutationVariables> {
    override document = MenuSaveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MenuCreateDocument = gql`
    mutation MenuCreate($judicialDistrict: Int!, $profile: Int!, $parent: Int) {
  createMenu(
    judicialDistrict: $judicialDistrict
    profile: $profile
    parent: $parent
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MenuCreateGQL extends Apollo.Mutation<MenuCreateMutation, MenuCreateMutationVariables> {
    override document = MenuCreateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteMenuDocument = gql`
    mutation DeleteMenu($id: Int!) {
  deleteMenu(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteMenuGQL extends Apollo.Mutation<DeleteMenuMutation, DeleteMenuMutationVariables> {
    override document = DeleteMenuDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendNotificationCallNotAnsweredDocument = gql`
    query SendNotificationCallNotAnswered($id: Int!) {
  sendNotificationCallNotAnswered(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendNotificationCallNotAnsweredGQL extends Apollo.Query<SendNotificationCallNotAnsweredQuery, SendNotificationCallNotAnsweredQueryVariables> {
    override document = SendNotificationCallNotAnsweredDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendNotificationEventNotAnsweredDocument = gql`
    query SendNotificationEventNotAnswered($id: Int!) {
  sendNotificationEventNotAnswered(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendNotificationEventNotAnsweredGQL extends Apollo.Query<SendNotificationEventNotAnsweredQuery, SendNotificationEventNotAnsweredQueryVariables> {
    override document = SendNotificationEventNotAnsweredDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindSettingsDocument = gql`
    query FindSettings($pagination: Pagination!) {
  settings: findSettings(pagination: $pagination) {
    name
    settings {
      id
      category
      group
      name
      type
      position
      options {
        decimals
        list
        max
        min
        pattern
      }
      value
      default
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindSettingsGQL extends Apollo.Query<FindSettingsQuery, FindSettingsQueryVariables> {
    override document = FindSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSettingDocument = gql`
    query GetSetting($id: Int!) {
  setting: getSetting(id: $id) {
    id
    name
    value
    webKey
    serverKey
    group
    category
    options {
      decimals
      list
      max
      min
      pattern
    }
    editable
    type
    position
    default
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSettingGQL extends Apollo.Query<GetSettingQuery, GetSettingQueryVariables> {
    override document = GetSettingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveSettingsDocument = gql`
    mutation SaveSettings($setting: SettingInput!, $id: Int) {
  saveSetting(setting: $setting, id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveSettingsGQL extends Apollo.Mutation<SaveSettingsMutation, SaveSettingsMutationVariables> {
    override document = SaveSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveSettingValueDocument = gql`
    mutation SaveSettingValue($value: String!, $id: Int!) {
  saveSettingValue(value: $value, id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveSettingValueGQL extends Apollo.Mutation<SaveSettingValueMutation, SaveSettingValueMutationVariables> {
    override document = SaveSettingValueDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ApplySettingsDocument = gql`
    mutation ApplySettings {
  applySettings
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApplySettingsGQL extends Apollo.Mutation<ApplySettingsMutation, ApplySettingsMutationVariables> {
    override document = ApplySettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UiConfigDocument = gql`
    query UiConfig {
  uiConfig {
    importLawyers
    importList
    importWatches
    logo
    showWatchesOrder
    title
    updateLawyers
    demoMode
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UiConfigGQL extends Apollo.Query<UiConfigQuery, UiConfigQueryVariables> {
    override document = UiConfigDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindAlarmsDocument = gql`
    query FindAlarms($pagination: Pagination) {
  alarms: findAlarms(pagination: $pagination) {
    totalElements
    content {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindAlarmsGQL extends Apollo.Query<FindAlarmsQuery, FindAlarmsQueryVariables> {
    override document = FindAlarmsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindProfilesDocument = gql`
    query FindProfiles($pagination: Pagination!) {
  profiles: findProfiles(pagination: $pagination) {
    totalElements
    content {
      id
      name
      permissions
      description
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindProfilesGQL extends Apollo.Query<FindProfilesQuery, FindProfilesQueryVariables> {
    override document = FindProfilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveProfileDocument = gql`
    mutation SaveProfile($profile: ProfileInput!, $id: Int) {
  saveProfile(profile: $profile, id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveProfileGQL extends Apollo.Mutation<SaveProfileMutation, SaveProfileMutationVariables> {
    override document = SaveProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProfileDocument = gql`
    query GetProfile($id: Int!) {
  profile: getProfile(id: $id) {
    id
    name
    description
    permissions
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProfileGQL extends Apollo.Query<GetProfileQuery, GetProfileQueryVariables> {
    override document = GetProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersTableDocument = gql`
    query UsersTable($pagination: Pagination!) {
  users: findUsers(pagination: $pagination) {
    totalElements
    content {
      id
      name
      contactName
      contactLastName
      username
      email
      identification
      phone
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersTableGQL extends Apollo.Query<UsersTableQuery, UsersTableQueryVariables> {
    override document = UsersTableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SupplantDocument = gql`
    query Supplant($id: Int) {
  supplant(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplantGQL extends Apollo.Query<SupplantQuery, SupplantQueryVariables> {
    override document = SupplantDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    query Login($username: String!, $password: String!) {
  login: login(username: $username, password: $password)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Query<LoginQuery, LoginQueryVariables> {
    override document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RenovateTokenDocument = gql`
    query RenovateToken {
  token: renovateToken
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RenovateTokenGQL extends Apollo.Query<RenovateTokenQuery, RenovateTokenQueryVariables> {
    override document = RenovateTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMeDocument = gql`
    query GetMe {
  user: getMe {
    name
    email
    phone
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMeGQL extends Apollo.Query<GetMeQuery, GetMeQueryVariables> {
    override document = GetMeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangePasswordDocument = gql`
    mutation ChangePassword($old: String!, $password: String, $pin: String) {
  changeUserPassword(old: $old, password: $password, pin: $pin)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangePasswordGQL extends Apollo.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables> {
    override document = ChangePasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImportUsersDocument = gql`
    mutation ImportUsers($file: Upload!) {
  importUsers(file: $file)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ImportUsersGQL extends Apollo.Mutation<ImportUsersMutation, ImportUsersMutationVariables> {
    override document = ImportUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation CreateUser($user: UserInput!) {
  createUser(user: $user) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    override document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation UpdateUser($user: UserInput!, $id: Int!) {
  updateUser(user: $user, id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    override document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserDocument = gql`
    query GetUser($id: Int!) {
  user(id: $id) {
    name
    contactLastName
    contactName
    email
    phone
    telegram
    profile {
      id
    }
    judicialDistricts {
      id
    }
    alarms {
      telegram
      phone
      email
      sms
      alarm
    }
    username
    identification
  }
  profiles: getProfiles {
    id
    description
  }
  districts: getJudicialDistricts {
    id
    name
  }
  alarms: findAlarms(pagination: {columns: "name", direction: 1}) {
    totalElements
    content {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserGQL extends Apollo.Query<GetUserQuery, GetUserQueryVariables> {
    override document = GetUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WatchesTableDocument = gql`
    query WatchesTable($pagination: Pagination) {
  watches: findWatches(pagination: $pagination) {
    totalElements
    content {
      start
      id
      order
      status
      substitution
      alias {
        id
        name
      }
      judicialDistrict {
        name
        id
      }
      lawyer {
        fullName
      }
      alertType {
        id
        name
      }
      allEvents {
        __typename
        ... on Call {
          date
          duration
          phone
          result
          callType
          lawyer {
            name
            lastName
            lawyerLicense
          }
        }
        ... on Event {
          date
          type
          lawyer {
            name
            lastName
            lawyerLicense
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WatchesTableGQL extends Apollo.Query<WatchesTableQuery, WatchesTableQueryVariables> {
    override document = WatchesTableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WatchesFiltersDocument = gql`
    query WatchesFilters {
  judicialDistricts: getJudicialDistricts {
    name
    id
  }
  alertsType: getAlertTypes {
    name
    id
  }
  lawyers: getLawyers {
    fullName
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WatchesFiltersGQL extends Apollo.Query<WatchesFiltersQuery, WatchesFiltersQueryVariables> {
    override document = WatchesFiltersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImportWatchesDocument = gql`
    mutation ImportWatches($excel: Upload) {
  importWatches(excel: $excel) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ImportWatchesGQL extends Apollo.Mutation<ImportWatchesMutation, ImportWatchesMutationVariables> {
    override document = ImportWatchesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WatchChangeDocument = gql`
    mutation WatchChange($watch: Int!, $lawyer: Int!) {
  watchChange(lawyer: $lawyer, watch: $watch)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WatchChangeGQL extends Apollo.Mutation<WatchChangeMutation, WatchChangeMutationVariables> {
    override document = WatchChangeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindWatchSubstituteDocument = gql`
    mutation FindWatchSubstitute($watch: Int!) {
  findWatchSubstitute(watch: $watch)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindWatchSubstituteGQL extends Apollo.Mutation<FindWatchSubstituteMutation, FindWatchSubstituteMutationVariables> {
    override document = FindWatchSubstituteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CancelWatchDocument = gql`
    mutation CancelWatch($watch: Int!) {
  cancelWatch(watch: $watch)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelWatchGQL extends Apollo.Mutation<CancelWatchMutation, CancelWatchMutationVariables> {
    override document = CancelWatchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExportWatchesToExcelDocument = gql`
    query ExportWatchesToExcel($pagination: Pagination!) {
  excel: exportWatchesExcel(pagination: $pagination)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExportWatchesToExcelGQL extends Apollo.Query<ExportWatchesToExcelQuery, ExportWatchesToExcelQueryVariables> {
    override document = ExportWatchesToExcelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WatchDocument = gql`
    query Watch {
  judicialDistricts: getJudicialDistricts {
    id
    name
  }
  alertTypes: getAlertTypes {
    id
    name
  }
  lawyers: findLawyers(
    pagination: {size: 100000, page: 0, columns: "name,lastName", direction: 1}
  ) {
    content {
      fullName
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WatchGQL extends Apollo.Query<WatchQuery, WatchQueryVariables> {
    override document = WatchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateWatchDocument = gql`
    mutation CreateWatch($watch: WatchInput) {
  createWatch(watch: $watch) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateWatchGQL extends Apollo.Mutation<CreateWatchMutation, CreateWatchMutationVariables> {
    override document = CreateWatchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

    @Injectable({
      providedIn: "root"
    })
    export class AlertsService {
       constructor(
          private _alertsTable: AlertsTableGQL,
          private _alertComponent: AlertComponentGQL,
          private _alertDetails: AlertDetailsGQL,
          private _alertsFilters: AlertsFiltersGQL,
          private _exportAlertExcel: ExportAlertExcelGQL,
          private _interruptAlert: InterruptAlertGQL,
          private _findOptions: FindOptionsGQL,
          private _createNewAlert: CreateNewAlertGQL,
          private _continueAlert: ContinueAlertGQL
       ) {
       }
       

     alertsTable(variables: AlertsTableQueryVariables) {
        return this._alertsTable.fetch(variables);
     }

     alertComponent() {
        return this._alertComponent.fetch();
     }

     alertDetails(variables: AlertDetailsQueryVariables) {
        return this._alertDetails.fetch(variables);
     }

     alertsFilters() {
        return this._alertsFilters.fetch();
     }

     exportAlertExcel(variables: ExportAlertExcelQueryVariables) {
        return this._exportAlertExcel.fetch(variables);
     }

     findOptions() {
        return this._findOptions.fetch();
     }
     interruptAlert(variables: InterruptAlertMutationVariables) {
       return this._interruptAlert.mutate(variables);
     }

     createNewAlert(variables: CreateNewAlertMutationVariables) {
       return this._createNewAlert.mutate(variables);
     }

     continueAlert(variables: ContinueAlertMutationVariables) {
       return this._continueAlert.mutate(variables);
     }
}

    @Injectable({
      providedIn: "root"
    })
    export class AssistsService {
       constructor(
          private _assistsTable: AssistsTableGQL,
          private _assistsFilters: AssistsFiltersGQL
       ) {
       }
       

     assistsTable(variables: AssistsTableQueryVariables) {
        return this._assistsTable.fetch(variables);
     }

     assistsFilters() {
        return this._assistsFilters.fetch();
     }
}

    @Injectable({
      providedIn: "root"
    })
    export class AudiosService {
       constructor(
          private _audiosTable: AudiosTableGQL,
          private _getAudio: GetAudioGQL,
          private _saveAudio: SaveAudioGQL,
          private _updateAudioFile: UpdateAudioFileGQL,
          private _generateAudio: GenerateAudioGQL,
          private _deleteAudio: DeleteAudioGQL
       ) {
       }
       

     audiosTable(variables: AudiosTableQueryVariables) {
        return this._audiosTable.fetch(variables);
     }

     getAudio(variables: GetAudioQueryVariables) {
        return this._getAudio.fetch(variables);
     }
     saveAudio(variables: SaveAudioMutationVariables) {
       return this._saveAudio.mutate(variables);
     }

     updateAudioFile(variables: UpdateAudioFileMutationVariables) {
       return this._updateAudioFile.mutate(variables);
     }

     generateAudio(variables: GenerateAudioMutationVariables) {
       return this._generateAudio.mutate(variables);
     }

     deleteAudio(variables: DeleteAudioMutationVariables) {
       return this._deleteAudio.mutate(variables);
     }
}

    @Injectable({
      providedIn: "root"
    })
    export class CourtsService {
       constructor(
          private _listCourts: ListCourtsGQL
       ) {
       }
       

     listCourts(variables: ListCourtsQueryVariables) {
        return this._listCourts.fetch(variables);
     }
}

    @Injectable({
      providedIn: "root"
    })
    export class DistrictsService {
       constructor(
          private _districtsTable: DistrictsTableGQL
       ) {
       }
       

     districtsTable(variables: DistrictsTableQueryVariables) {
        return this._districtsTable.fetch(variables);
     }
}

    @Injectable({
      providedIn: "root"
    })
    export class InformsListService {
       constructor(
          private _informEmptyWatches: InformEmptyWatchesGQL
       ) {
       }
       

     informEmptyWatches(variables: InformEmptyWatchesQueryVariables) {
        return this._informEmptyWatches.fetch(variables);
     }
}

    @Injectable({
      providedIn: "root"
    })
    export class LawyersService {
       constructor(
          private _lawyersTable: LawyersTableGQL,
          private _saveLawyer: SaveLawyerGQL,
          private _lawyerComponent: LawyerComponentGQL,
          private _importLawyers: ImportLawyersGQL,
          private _filterLawyer: FilterLawyerGQL
       ) {
       }
       

     lawyersTable(variables: LawyersTableQueryVariables) {
        return this._lawyersTable.fetch(variables);
     }

     lawyerComponent(variables: LawyerComponentQueryVariables) {
        return this._lawyerComponent.fetch(variables);
     }

     filterLawyer(variables: FilterLawyerQueryVariables) {
        return this._filterLawyer.fetch(variables);
     }
     saveLawyer(variables: SaveLawyerMutationVariables) {
       return this._saveLawyer.mutate(variables);
     }

     importLawyers(variables: ImportLawyersMutationVariables) {
       return this._importLawyers.mutate(variables);
     }
}

    @Injectable({
      providedIn: "root"
    })
    export class LawyerListsService {
       constructor(
          private _listsTable: ListsTableGQL,
          private _lawyerListComponent: LawyerListComponentGQL,
          private _saveList: SaveListGQL,
          private _deleteList: DeleteListGQL,
          private _getLawyerList: GetLawyerListGQL,
          private _listEntriesTable: ListEntriesTableGQL,
          private _listEntryComponent: ListEntryComponentGQL,
          private _listEntrySave: ListEntrySaveGQL,
          private _deleteListEntry: DeleteListEntryGQL,
          private _importLists: ImportListsGQL,
          private _importList: ImportListGQL,
          private _downLawyerEntryValue: DownLawyerEntryValueGQL,
          private _exportList: ExportListGQL,
          private _findListCalls: FindListCallsGQL
       ) {
       }
       

     listsTable(variables: ListsTableQueryVariables) {
        return this._listsTable.fetch(variables);
     }

     lawyerListComponent(variables: LawyerListComponentQueryVariables) {
        return this._lawyerListComponent.fetch(variables);
     }

     getLawyerList(variables: GetLawyerListQueryVariables) {
        return this._getLawyerList.fetch(variables);
     }

     listEntriesTable(variables: ListEntriesTableQueryVariables) {
        return this._listEntriesTable.fetch(variables);
     }

     listEntryComponent(variables: ListEntryComponentQueryVariables) {
        return this._listEntryComponent.fetch(variables);
     }

     exportList(variables: ExportListQueryVariables) {
        return this._exportList.fetch(variables);
     }

     findListCalls(variables: FindListCallsQueryVariables) {
        return this._findListCalls.fetch(variables);
     }
     saveList(variables: SaveListMutationVariables) {
       return this._saveList.mutate(variables);
     }

     deleteList(variables: DeleteListMutationVariables) {
       return this._deleteList.mutate(variables);
     }

     listEntrySave(variables: ListEntrySaveMutationVariables) {
       return this._listEntrySave.mutate(variables);
     }

     deleteListEntry(variables: DeleteListEntryMutationVariables) {
       return this._deleteListEntry.mutate(variables);
     }

     importLists(variables: ImportListsMutationVariables) {
       return this._importLists.mutate(variables);
     }

     importList(variables: ImportListMutationVariables) {
       return this._importList.mutate(variables);
     }

     downLawyerEntryValue(variables: DownLawyerEntryValueMutationVariables) {
       return this._downLawyerEntryValue.mutate(variables);
     }
}

    @Injectable({
      providedIn: "root"
    })
    export class MenusService {
       constructor(
          private _findMenus: FindMenusGQL,
          private _getMenu: GetMenuGQL,
          private _menusFilters: MenusFiltersGQL,
          private _menuPosition: MenuPositionGQL,
          private _menuSave: MenuSaveGQL,
          private _menuCreate: MenuCreateGQL,
          private _deleteMenu: DeleteMenuGQL
       ) {
       }
       

     findMenus(variables: FindMenusQueryVariables) {
        return this._findMenus.fetch(variables);
     }

     getMenu(variables: GetMenuQueryVariables) {
        return this._getMenu.fetch(variables);
     }

     menusFilters() {
        return this._menusFilters.fetch();
     }
     menuPosition(variables: MenuPositionMutationVariables) {
       return this._menuPosition.mutate(variables);
     }

     menuSave(variables: MenuSaveMutationVariables) {
       return this._menuSave.mutate(variables);
     }

     menuCreate(variables: MenuCreateMutationVariables) {
       return this._menuCreate.mutate(variables);
     }

     deleteMenu(variables: DeleteMenuMutationVariables) {
       return this._deleteMenu.mutate(variables);
     }
}

    @Injectable({
      providedIn: "root"
    })
    export class NotificationsService {
       constructor(
          private _sendNotificationCallNotAnswered: SendNotificationCallNotAnsweredGQL,
          private _sendNotificationEventNotAnswered: SendNotificationEventNotAnsweredGQL
       ) {
       }
       

     sendNotificationCallNotAnswered(variables: SendNotificationCallNotAnsweredQueryVariables) {
        return this._sendNotificationCallNotAnswered.fetch(variables);
     }

     sendNotificationEventNotAnswered(variables: SendNotificationEventNotAnsweredQueryVariables) {
        return this._sendNotificationEventNotAnswered.fetch(variables);
     }
}

    @Injectable({
      providedIn: "root"
    })
    export class SettingsService {
       constructor(
          private _findSettings: FindSettingsGQL,
          private _getSetting: GetSettingGQL,
          private _saveSettings: SaveSettingsGQL,
          private _saveSettingValue: SaveSettingValueGQL,
          private _applySettings: ApplySettingsGQL,
          private _uiConfig: UiConfigGQL
       ) {
       }
       

     findSettings(variables: FindSettingsQueryVariables) {
        return this._findSettings.fetch(variables);
     }

     getSetting(variables: GetSettingQueryVariables) {
        return this._getSetting.fetch(variables);
     }

     uiConfig() {
        return this._uiConfig.fetch();
     }
     saveSettings(variables: SaveSettingsMutationVariables) {
       return this._saveSettings.mutate(variables);
     }

     saveSettingValue(variables: SaveSettingValueMutationVariables) {
       return this._saveSettingValue.mutate(variables);
     }

     applySettings(variables: ApplySettingsMutationVariables) {
       return this._applySettings.mutate(variables);
     }
}

    @Injectable({
      providedIn: "root"
    })
    export class AlarmsService {
       constructor(
          private _findAlarms: FindAlarmsGQL
       ) {
       }
       

     findAlarms(variables: FindAlarmsQueryVariables) {
        return this._findAlarms.fetch(variables);
     }
}

    @Injectable({
      providedIn: "root"
    })
    export class ProfilesService {
       constructor(
          private _findProfiles: FindProfilesGQL,
          private _saveProfile: SaveProfileGQL,
          private _getProfile: GetProfileGQL
       ) {
       }
       

     findProfiles(variables: FindProfilesQueryVariables) {
        return this._findProfiles.fetch(variables);
     }

     getProfile(variables: GetProfileQueryVariables) {
        return this._getProfile.fetch(variables);
     }
     saveProfile(variables: SaveProfileMutationVariables) {
       return this._saveProfile.mutate(variables);
     }
}

    @Injectable({
      providedIn: "root"
    })
    export class UsersService {
       constructor(
          private _usersTable: UsersTableGQL,
          private _supplant: SupplantGQL,
          private _login: LoginGQL,
          private _renovateToken: RenovateTokenGQL,
          private _getMe: GetMeGQL,
          private _changePassword: ChangePasswordGQL,
          private _importUsers: ImportUsersGQL
       ) {
       }
       

     usersTable(variables: UsersTableQueryVariables) {
        return this._usersTable.fetch(variables);
     }

     supplant(variables: SupplantQueryVariables) {
        return this._supplant.fetch(variables);
     }

     login(variables: LoginQueryVariables) {
        return this._login.fetch(variables);
     }

     renovateToken() {
        return this._renovateToken.fetch();
     }

     getMe() {
        return this._getMe.fetch();
     }
     changePassword(variables: ChangePasswordMutationVariables) {
       return this._changePassword.mutate(variables);
     }

     importUsers(variables: ImportUsersMutationVariables) {
       return this._importUsers.mutate(variables);
     }
}

    @Injectable({
      providedIn: "root"
    })
    export class UserService {
       constructor(
          private _createUser: CreateUserGQL,
          private _updateUser: UpdateUserGQL,
          private _getUser: GetUserGQL
       ) {
       }
       

     getUser(variables: GetUserQueryVariables) {
        return this._getUser.fetch(variables);
     }
     createUser(variables: CreateUserMutationVariables) {
       return this._createUser.mutate(variables);
     }

     updateUser(variables: UpdateUserMutationVariables) {
       return this._updateUser.mutate(variables);
     }
}

    @Injectable({
      providedIn: "root"
    })
    export class WatchesService {
       constructor(
          private _watchesTable: WatchesTableGQL,
          private _watchesFilters: WatchesFiltersGQL,
          private _importWatches: ImportWatchesGQL,
          private _watchChange: WatchChangeGQL,
          private _findWatchSubstitute: FindWatchSubstituteGQL,
          private _cancelWatch: CancelWatchGQL,
          private _exportWatchesToExcel: ExportWatchesToExcelGQL
       ) {
       }
       

     watchesTable(variables: WatchesTableQueryVariables) {
        return this._watchesTable.fetch(variables);
     }

     watchesFilters() {
        return this._watchesFilters.fetch();
     }

     exportWatchesToExcel(variables: ExportWatchesToExcelQueryVariables) {
        return this._exportWatchesToExcel.fetch(variables);
     }
     importWatches(variables: ImportWatchesMutationVariables) {
       return this._importWatches.mutate(variables);
     }

     watchChange(variables: WatchChangeMutationVariables) {
       return this._watchChange.mutate(variables);
     }

     findWatchSubstitute(variables: FindWatchSubstituteMutationVariables) {
       return this._findWatchSubstitute.mutate(variables);
     }

     cancelWatch(variables: CancelWatchMutationVariables) {
       return this._cancelWatch.mutate(variables);
     }
}

    @Injectable({
      providedIn: "root"
    })
    export class WatchService {
       constructor(
          private _watch: WatchGQL,
          private _createWatch: CreateWatchGQL
       ) {
       }
       

     watch() {
        return this._watch.fetch();
     }
     createWatch(variables: CreateWatchMutationVariables) {
       return this._createWatch.mutate(variables);
     }
}
