import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {TableList} from "../../utils/TableList";
import {SettingsService} from "../services/settings.service";
import {MatDialog} from "@angular/material/dialog";
import {SettingComponent} from "../setting/setting.component";
import {Filter, Pagination} from "../../utils/Pagination";
import {FindSettingsQuery} from "../../../generated/graphql";
import {SettingValueComponent} from "../setting-value/setting-value.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTabGroup} from "@angular/material/tabs";

@Component({
  selector: 'app-settings-table',
  templateUrl: './settings-table.component.html',
  styleUrls: ['./settings-table.component.scss']
})
export class SettingsTableComponent extends TableList implements AfterViewInit {



  filter: string = '';

  private callFilter = 0;

  categories: FindSettingsQuery['settings'] = [];
  category: number = 0;



  @ViewChild('tabs')
  tabs: MatTabGroup;

  constructor(
    private settingsService: SettingsService,
    private snackBar: MatSnackBar,
    dialog: MatDialog
  ) {
    super(SettingComponent, dialog);
  }

  ngAfterViewInit(): void {
    this.pagination = new Pagination({rows: 80000, first: 0, sortField: 'name', sortOrder: 1});
    this.updateElements();
  }



  delete(id: number): void {
  }

  updateElements(): void {

    this.tabs.animationDuration = '0';


    this.settingsService.list(this.pagination).subscribe( s => {
        this.categories = JSON.parse(JSON.stringify(s.data.settings));
        setTimeout(() => {
          this.tabs.animationDuration = '500'
        }, 100);
      }
    );



  }



  editValue(setting: any) {

    this.dialog.open(SettingValueComponent, {
      width: '90%',
      data: {setting}
    }).afterClosed().subscribe( _ => this.updateElements());

  }

  apply() {
    this.settingsService.apply().subscribe( s => this.snackBar.open('Reiniciando servidor'));
  }

  onFilter() {

    clearTimeout(this.callFilter);

    this.callFilter = setTimeout( () => {
      this.pagination.filters = [new Filter('global', this.filter, 'contains', '')];
      this.updateElements();
    }, 500);


  }
}
