import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-play-audio',
  templateUrl: './play-audio.component.html',
  styleUrls: ['./play-audio.component.scss']
})
export class PlayAudioComponent implements OnInit {


  @Input()
  file: string;

  paused = true;
  audio: HTMLAudioElement;


  @Input()
  name = 'Audio'

  @Input()
  text = '';


  constructor() {
  }

  ngOnInit(): void {
  }


  play() {


    if (!this.audio) {
      this.load();
    }

    if (this.paused)
      this.audio.play();
    else
      this.audio.pause();


  }


  load() {

    console.log(this.file);
    if (this.file.startsWith("data:audio/wav"))
      this.audio = new Audio(this.file);
    else
      this.audio = new Audio('data:audio/wav;base64,' + this.file);

    this.audio.onended = (_ => this.paused = true);
    this.audio.onpause = (_ => this.paused = true);
    this.audio.onplay = (_ => this.paused = false);

  }

  download() {
    const a = document.createElement('a');
    a.href = `data:audio/wav;base64,${this.file}`;
    a.download = `${this.name}.wav`;
    a.click();
  }
}
