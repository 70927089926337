import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CallType, FindListCallsQuery, PageCall} from "../../../generated/graphql";
import {ListService} from "../services/list.service";
import {TableList} from "../../utils/TableList";
import {Filter} from "../../utils/Pagination";

@Component({
  selector: 'app-calls-table',
  templateUrl: './calls-table.component.html',
  styleUrls: ['./calls-table.component.scss']
})
export class CallsTableComponent extends TableList implements OnChanges {

  @Input()
  callType: CallType;

  @Input()
  list: number;

  @Input()
  query = '';


  calls: FindListCallsQuery['calls'] = new PageCall();

  constructor(
    private listsService: ListService,
  ) {
    super(null, null);
  }

  ngOnChanges(changes: SimpleChanges): void {

    this.updateElements();
  }


  delete(id: number): void {
  }

  updateElements(): void {

    if (!this.pagination) return;


    if (this.query)
      this.pagination.setFilter(new Filter('global', this.query, 'contains', 'and'));
    else
      this.pagination.removeFilter('global');

    this.listsService
      .listCalls(this.pagination, this.list, this.callType)
      .subscribe(s => this.calls = s.data.calls);
  }


}
