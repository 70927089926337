import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[loading]'
})
export class LoadingDirective implements OnChanges {


    @Input()
    loading = false;


    constructor(private renderer: Renderer2, private element: ElementRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.loading) {
            this.renderer.addClass(this.element.nativeElement, 'spinner');
        } else {
            this.renderer.removeClass(this.element.nativeElement, 'spinner');
        }

        this.element.nativeElement.disabled = this.loading;

    }
}
