

<ng-container *ngIf="text == ''; else withText">
  <button mat-icon-button [ngClass]="uploading ? 'spinner': ''" [disabled]="uploading" (click)="click()">
    <mat-icon [svgIcon]="icon" [matTooltip]="label"></mat-icon>
  </button>
</ng-container>


<ng-template #withText>
  <button mat-raised-button [color]="color" [ngClass]="uploading ? 'spinner': ''" [disabled]="uploading" (click)="click()">
    <mat-icon [svgIcon]="icon" [matTooltip]="label"></mat-icon>
    {{text}}
  </button>
</ng-template>

<input type="file" #file (change)="change($event); file.value = ''" accept="{{accept}}" />
