import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {


  @Input()
  uploading = false;

  @Output()
  fileSelected = new EventEmitter<File>();


  @ViewChild('file')
  file: ElementRef;

  @Input()
  icon = "paperclip"


  @Input()
  label = 'Subir fichero'

  @Input()
  accept = "application/pdf"



  @Input()
  color = '';

  @Input()
  text = '';


  constructor() { }

  ngOnInit() {
  }



  change($event) {
    if ($event.target.files === undefined)
      return;

    this.fileSelected.emit($event.target.files[0]);

  }

  click() {

      this.file.nativeElement.click();


  }
}
