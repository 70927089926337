import {Component, Input, OnInit} from '@angular/core';
import {Call, CallType, Event, EventType, SimpleEvent} from "../../../generated/graphql";
import {AuthService} from "../../users/auth/auth.service";
import {NotificationsService} from "../../notifications/services/notifications.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";
import {filter} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-calls',
  templateUrl: './calls.component.html',
  styleUrls: ['./calls.component.scss']
})
export class CallsComponent implements OnInit {


  @Input()
  events: SimpleEvent[];

  audio: HTMLAudioElement;


  constructor(
    private auth: AuthService,
    private notificationsService: NotificationsService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {

  }


  notifyNotAnswered(event: SimpleEvent) {
    if (!this.auth.alertNotAnswered) return false;

    return event.unattended;
  }


  callTypeText(simpleEvent: SimpleEvent) {

    if (simpleEvent['result']) {
      const call = simpleEvent as Call;
      switch (call.callType) {
        case CallType.Substitute:
          return 'Buscar substituto';
        case CallType.Interruption:
          return 'Interrupción';
        case CallType.List:
          return 'Lista';
        case CallType.Normal:
          return 'Guardia';
        case CallType.Remember:
          return 'Recordatorio';
      }
    } else {
      const event = simpleEvent as Event;

      switch (event.type) {
        case EventType.Api:
          return 'API'
        case EventType.WebCancelled:
          return 'Web';
        case EventType.WebChange:
          return 'Web';
        case EventType.WebSubstitution:
          return 'Web';
        case EventType.TelegramAcceptAssistance:
          return 'Telegram';
        case EventType.TelegramRejectAssistance:
          return 'Telegram'
        case EventType.TelegramRejectShift:
          return 'Telegram'
        case EventType.TelegramConfirmShift:
          return 'Telegram';

      }
    }

  }

  callTypeIcon(simpleEvent: SimpleEvent) {

    if (simpleEvent['result']) {
      const call = simpleEvent as Call;
      switch (call.callType) {
        case CallType.Substitute:
          return 'account-search';
        case CallType.Interruption:
          return 'cancel';
        case CallType.List:
          return 'account-details';
        case CallType.Normal:
          return 'account-clock';
        case CallType.Remember:
          return 'reminder';
      }
    } else {
      const event = simpleEvent as Event;

      switch (event.type) {
        case EventType.Api:
          return 'api';
        case EventType.WebCancelled:
          return 'account-cancel';
        case EventType.WebChange:
          return 'account-convert';
        case EventType.WebSubstitution:
          return 'account-search';
        case EventType.TelegramAcceptAssistance:
        case EventType.TelegramRejectAssistance:
        case EventType.TelegramRejectShift:
        case EventType.TelegramConfirmShift:
          return 'message-text';

      }
    }

  }


  sendNotificationNotAnswered(event: SimpleEvent) {


    this.dialog.open(ConfirmDialogComponent, {
      width: '90%',
      data: {
        title: 'Enviar aviso',
        content: `¿Confirmas que deseas enviar un aviso a ${event.lawyer.name} ${event.lawyer.lastName}?`
      }
    }).afterClosed()
      .pipe(filter(r => r))
      .subscribe(_ => {
        if (event['result'] != undefined)
          this.notificationsService.callNotAnswered(event.id).subscribe(s => {
            this.snackBar.open("Notificación enviada", "Aceptar", {
              verticalPosition: "top",
              horizontalPosition: "right"
            });
          });
        else {
          this.notificationsService.eventNotAnswered(event.id).subscribe(s => {
            this.snackBar.open("Notificación enviada", "Aceptar", {
              verticalPosition: "top",
              horizontalPosition: "right"
            });
          });
        }

      });
  }


}
