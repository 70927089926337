<mat-toolbar class="mat-elevation-z4">
  Partidos Judiciales
</mat-toolbar>

<p-table
  responsiveLayout="scroll"

  [value]="districts.content"
  [lazy]="true"
  (onLazyLoad)="onLazyEvent($event)"
  [rows]="20"
  paginator="true"
  tableStyleClass="mat-elevation-z4"
  [totalRecords]="districts.totalElements">


  <ng-template pTemplate="header">

    <tr>
      <th>
        ID
      </th>

      <th>
        Nombre
      </th>


    </tr>


  </ng-template>


  <ng-template pTemplate="body" let-district>

    <tr>
      <td>{{district.id}}</td>

      <td>{{district.name | titlecase }}</td>
    </tr>

  </ng-template>


</p-table>
