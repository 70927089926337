import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LawyerListComponentGQL, LawyerListComponentQuery, LawyerListInput, SaveListGQL} from '../../../generated/graphql';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [MessageService]

})
export class ListComponent {


  lawyerList = new LawyerListInput();
  alertTypes: LawyerListComponentQuery['alertTypes'] = [];
  judicialDistricts: LawyerListComponentQuery['judicialDistricts'] = [];



  constructor(
    private dialog: MatDialogRef<ListComponent>,
    getService: LawyerListComponentGQL,
    private saveService: SaveListGQL,
    @Inject(MAT_DIALOG_DATA) data
  ) {


    this.lawyerList.id = data.id;

    getService.fetch({id: data.id || 0})
      .subscribe( s => {
        this.alertTypes = s.data.alertTypes;
        this.judicialDistricts = s.data.judicialDistricts;

        this.lawyerList.alertType = this.alertTypes[0].id;
        this.lawyerList.judicialDistrict = this.judicialDistricts[0].id;


        if (s.data.lawyerList)
          this.lawyerList = {...s.data.lawyerList};

      });

  }


  save() {

    this.saveService
      .mutate({lawyerList: this.lawyerList})
      .subscribe( _ => this.dialog.close());

  }
}
