import {Component, Inject, OnInit} from '@angular/core';
import {GetMenuQuery, Menu, MenuInput, MenusFiltersQuery} from "../../../generated/graphql";
import {MenusService} from "../services/menus.service";
import {AudioComponent} from "../../audios/audio/audio.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {


  loading = false;
  errors: any;
  menu: GetMenuQuery['menu'] = new Menu();

  filters: MenusFiltersQuery = {profiles: [], judicialDistricts: [], audios: [], lists: [], alertTypes: []};


  constructor(
    private dialog: MatDialogRef<MenuComponent>,
    private menusServices: MenusService,
    @Inject(MAT_DIALOG_DATA) data,
    private matDialog: MatDialog
  ) {


    this.menusServices.get(data.id).subscribe(s => this.menu = s.data.menu)

    this.updateFilters();

  }

  ngOnInit(): void {
  }


  async updateFilters() {
    const response = await this.menusServices.filters().toPromise();

    this.filters = {
      profiles: response.data.profiles,
      judicialDistricts: response.data.judicialDistricts,
      audios: [...response.data.audios, {code: null, name: 'Nuevo audio', id: 0}],
      alertTypes: [{id: null, name: ''}, ...response.data.alertTypes],
      lists: [{id: null, name: ''}, ...response.data.lists]
    };

  }

  async save(menu: MenuInput) {

    this.loading = true;

    const response = await this.menusServices.save(menu, this.menu.id).toPromise();

    this.loading = false;
    this.errors = response.errors;

    if (response.data)
      this.dialog.close();


  }

  async audioChange(audio) {

    if (audio.value) return;


    const newAudio = await this.matDialog.open(AudioComponent,
      {
        width: '90%',
        data: {},
      });

    if (!newAudio) return;

    await this.updateFilters();

    audio.value = 'hola'


  }
}
