import {Component, OnInit} from '@angular/core';
import {TableList} from '../../utils/TableList';
import {MatDialog} from "@angular/material/dialog";
import {MenuComponent} from '../menu/menu.component';
import {MenusService} from '../services/menus.service';
import {FindMenusQuery, Menu, MenusFiltersQuery,} from "../../../generated/graphql";

@Component({
  selector: 'app-menu-table',
  templateUrl: './menu-table.component.html',
  styleUrls: ['./menu-table.component.scss']
})
export class MenuTableComponent extends TableList implements OnInit {


  menus = [];
  totalElements = 0;

  expanded = new Map<any, boolean>()

  filters: MenusFiltersQuery = {profiles: [], judicialDistricts: [], alertTypes: [], lists: [], audios: []};

  constructor(
    dialog: MatDialog,
    private menusService: MenusService
  ) {

    super(MenuComponent, dialog);


  }

  ngOnInit(): void {

    this.menusService.filters().subscribe(s => {

      this.filters = s.data;

      this.updateElements();

    });

  }

  delete(id: number): void {

    this.menusService.delete(id).subscribe(s => this.updateElements());
  }

  updateElements(): void {


    this.menusService.list().subscribe(s => {


      this.menus = this.groupByJudicialDistrict(s.data.menus);


    });
  }


  groupByProfile(elements: FindMenusQuery['menus'], judicialDistrict: number) {

    return this.filters.profiles.map(m => {
      const subMenus = this.groupByLevel(elements.filter(e => e.profile.id == m.id), null);
      return {
        data: {order: m.description, id: null, expandable: `${judicialDistrict}-${m.description}`, profile: {id: m.id}, judicialDistrict: {id: judicialDistrict}},
        children: subMenus,
        leaf: subMenus.length == 0,
        expanded: this.wasExpanded(`${judicialDistrict}-${m.description}`)
      }
    });

  }

  groupByJudicialDistrict(elements: FindMenusQuery['menus']) {

    return this.filters.judicialDistricts.map((m,) => {
      const subMenus = this.groupByProfile(elements.filter(e => e.judicialDistrict.id == m.id), m.id);
      return {
        data: {order: m.name, id: null, expandable: `jd-${m.id}`},
        children: subMenus,
        leaf: subMenus.length == 0,
        expanded: this.wasExpanded(`jd-${m.id}`)
      }
    });

  }


  groupByLevel(elements: FindMenusQuery['menus'], parent: number) {

    const menus = elements.filter(m => m.parent?.id == parent).sort(m => m.order);

    return menus.map(m => {
      const subMenus = this.groupByLevel(elements, m.id);
      return {
        data: m,
        children: subMenus,
        leaf: subMenus.length == 0,
        expanded: this.wasExpanded(m.id)
      }
    });
  }

  addChild(menu: Menu) {


    this.menusService.create(menu).subscribe( s =>
      this.dialog.open(MenuComponent, {
        width: '90%',
        data: {id: s.data.createMenu.id}
      }).afterClosed().subscribe( s => this.updateElements()));


  }

  up(menu: Menu) {

    this.menusService.newPosition(menu.id, menu.order - 1).subscribe(s => this.updateElements());

  }

  down(menu: Menu) {
    this.menusService.newPosition(menu.id, menu.order + 1).subscribe(s => this.updateElements());

  }




  private wasExpanded(id: any) {
    return this.expanded.has(id);
  }


  onNodeExpand(event: any) {
    const id = event.node.data.id || event.node.data.expandable;

    this.expanded.set(id, true);

  }

  onNodeCollapse(event: any) {
    const id = event.node.data.id || event.node.data.expandable;

    this.expanded.delete(id);
  }
}
