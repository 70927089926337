import {Injectable} from '@angular/core';
import {CallType, DownLawyerEntryValueGQL, ExportListGQL, FindListCallsGQL, ImportListGQL, ListsTableGQL} from "../../../generated/graphql";
import {Pagination} from "../../utils/Pagination";

@Injectable({
  providedIn: 'root'
})
export class ListService {

  constructor(private importListService: ImportListGQL,
              private listService: ListsTableGQL,
              private down: DownLawyerEntryValueGQL,
              private listsCallsService: FindListCallsGQL,
              private exportService: ExportListGQL
              ) {

  }




  list(pagination?: Pagination) {
    return this.listService.fetch({pagination});
  }

  importList(file: File, id: number) {
    return this.importListService.mutate({id, file});
  }


  restoreSubstitution(lawyer: number, list :number) {
    return this.down.mutate({list, lawyer, substitution: -1, assistance: 0})
  }

  restoreAssistance(lawyer: number, list :number) {
    return this.down.mutate({list, lawyer, substitution: 0, assistance: -1})
  }


  listCalls(pagination: Pagination, list: number, type: CallType) {
    return this.listsCallsService.fetch({id: list, pagination, type});
  }


  export(list: number) {
    return this.exportService.fetch({list});
  }
}
