import {Component, OnInit} from '@angular/core';
import {UsersService} from "../services/users.service";
import {GraphQLError} from "graphql";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-change-passwords',
  templateUrl: './change-passwords.component.html',
  styleUrls: ['./change-passwords.component.scss']
})
export class ChangePasswordsComponent  implements OnInit {


  errors: ReadonlyArray<GraphQLError> = [];

  constructor(
    private usersService: UsersService,
    private dialog: MatDialogRef<ChangePasswordsComponent>
  ) { }

  ngOnInit(): void {
  }

  save(value: any) {

    const errors = [];

    if (value.password != value.passwordConfirm)
      errors.push({ message: "Las contraseñas nuevas no coinciden."});

    if (value.pin != value.pinConfirm)
      errors.push({ message: "Los pines no coinciden."});


    if (errors.length > 0) {
      this.errors = errors;
      return;
    }

    this.usersService
      .changePassword(value.old, value.password, value.pin)
      .subscribe( s => {
        this.errors = s.errors;

        if (!s.errors)
          this.dialog.close();

      })


  }
}
