


<h2 mat-dialog-title>Grabar audio para el aviso</h2>


<mat-dialog-content>


  <h1>
    <ng-container *ngIf="state == 'NOT_PERMISSION'" >
      Aceptar permiso micrófono
    </ng-container>


    <ng-container *ngIf="state != 'NOT_PERMISSION'">
      {{time}}
    </ng-container>

  </h1>


</mat-dialog-content>




<mat-dialog-actions>
  <ng-container *ngIf="state == 'RECORDED'">

    <span>
    <app-play-audio [file]="recorded" text="Escuchar"> </app-play-audio>

    </span>

    <button mat-raised-button (click)="onStart()">
      Regrabar audio
    </button>

    <button mat-raised-button (click)="confirm()" color="primary">
      Aceptar
    </button>

  </ng-container>

  <ng-container *ngIf="state == 'RECORDING'">

    <button  color="warn" mat-raised-button (click)="onStop()">
      Parar grabación
    </button>

  </ng-container>


  <button mat-button mat-dialog-close>Cerrar</button>

</mat-dialog-actions>




