import {Component, Inject, OnInit} from '@angular/core';
import {AudiosService} from "../services/audios.service";
import {Audio, AudioInput} from "../../../generated/graphql";
import {GraphQLError} from "graphql";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class AudioComponent implements OnInit {


  audio = new Audio();
  loading = false;
  errors: ReadonlyArray<GraphQLError> = [];


  constructor(
    private audiosService: AudiosService,
    private dialog: MatDialogRef<AudioComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {



    if (data.id)
      this.audiosService.get(data.id).subscribe( s => this.audio = s.data.audio);



  }

  ngOnInit(): void {
  }

  async save(audio: AudioInput) {
    this.loading = true;
    const s = await this.audiosService.save(audio, this.audio.id).toPromise();

    this.errors = s.errors;

    if (s.data)  this.dialog.close(s.data.saveAudio.id);


  }
}
