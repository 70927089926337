<h2 mat-dialog-title>Abogado/a</h2>


<form #form="ngForm" #formElement  >

  <mat-dialog-content>

    <div class="p-grid p-fluid">

      <div class="p-md-4 p-float-label">
        <input pInputText name="name" required [ngModel]="lawyer.name">
        <label>Nombre</label>
      </div>


      <div class="p-md-4 p-float-label">
        <input pInputText name="lastName" required [ngModel]="lawyer.lastName">
        <label>Apellidos</label>
      </div>

      <div class="p-md-4 p-float-label">
        <input pInputText name="identification" [ngModel]="lawyer.identification">
        <label>DNI/NIE</label>
      </div>


      <div class="p-md-8 p-float-label">
        <input pInputText name="email" type="email" required [ngModel]="lawyer.email">
        <label>Email</label>
      </div>

      <div class="p-md-4 p-float-label">
        <input pInputText name="lawyerLicense" required [ngModel]="lawyer.lawyerLicense">
        <label>Número abogado/a</label>
      </div>

      <div class="p-md-3 p-float-label">
        <input pInputText name="phoneNumber" [ngModel]="lawyer.phoneNumber">
        <label>Teléfono</label>
      </div>

      <div class="p-md-3 p-float-label">
        <input pInputText name="telegram" [ngModel]="lawyer.telegram">
        <label>ID Telegram</label>
      </div>



      <div class="p-md-3 p-float-label">
        <p-dropdown
          [disabled]="!auth.lawyers"

          name="judicialDistrict" appendTo="body"
          optionValue="id"
          optionLabel="name"
          placeholder="Selecciona un/a abogado/a"
          [options]="judicialDistricts"
          [ngModel]="lawyer.judicialDistrict?.id">

        </p-dropdown>

        <label>Partido judicial</label>

      </div>


      <div class="p-md-3 p-float-label">
        <p-dropdown name="blockUpdate" optionValue="value" optionLabel="label"
                    [ngModel]="lawyer.blockUpdate"
                    [options]="[{value: false, label: 'No'}, {value: true, label: 'Sí'}]">

        </p-dropdown>
        <label>Bloquear actualización</label>
      </div>



    </div>

    <app-graphql-errors [errors]="errors" [form]="formElement"></app-graphql-errors>

  </mat-dialog-content>


  <mat-dialog-actions>

    <button mat-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button *ngIf="auth.lawyers" color="primary" [disabled]="!form.valid" (click)="save(form.value)">
      Guardar
    </button>

  </mat-dialog-actions>


</form>
