import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {

    const first = value.substr(0, 1);
    const end = value.substr(1);

    return first.toUpperCase() + end.toLowerCase();
  }

}
