import {Component, Input, OnInit} from '@angular/core';
import {Call, CallResult, Event, EventType, SimpleEvent} from "../../../generated/graphql";

@Component({
  selector: 'app-call-state',
  templateUrl: './call-state.component.html',
  styleUrls: ['./call-state.component.scss']
})
export class CallStateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }



  @Input()
  event: Event;


  stateToText(simpleEvent: SimpleEvent) {

    if (simpleEvent['result']) {

      const call = simpleEvent as Call;

      switch (call.result) {
        case CallResult.Accepted:
          return 'Aceptada';
        case CallResult.Denied:
          return 'Denegada';
        case CallResult.Off:
          return 'Apagado';
        case CallResult.Rejected:
          return 'Rechazado';
        case CallResult.HungUp:
          return 'Colgado';
        case CallResult.HungUpBeforeConfirm:
          return 'Colgado sin confirmar';
        case CallResult.NotAnswer:
          return 'Sin responder';
        case CallResult.Communicates:
          return 'Comunicando';
        case CallResult.CallError:
          return 'Error en la llamada';
        case CallResult.NotEstablishing:
          return 'Sin establecer';
        case CallResult.RejectedAfterAccepted:
          return 'Rechazada tras aceptar';
        case CallResult.Absence:
          return 'Incomparecencia'


      }

    } else {
      const event = simpleEvent as Event;

      switch (event.type) {
        case EventType.WebCancelled:
          return 'Cancelada desde web';
        case EventType.WebChange:
          return 'Cambio desde web';
        case EventType.WebSubstitution:
          return 'Substitución desde web';
        case EventType.TelegramAcceptAssistance:
          return 'Aceptado';
        case EventType.TelegramRejectAssistance:
          return 'Asistencia rechazada'
        case EventType.TelegramRejectShift:
          return 'Turno rechazado'
        case EventType.TelegramConfirmShift:
          return 'Turno confirmado';
        case EventType.Api:
          return 'Cambio desde API'


      }

    }


  }


  stateToColor(simpleEvent: SimpleEvent) {


    if (simpleEvent['result']) {

      const call = simpleEvent as Call;
      switch (call.result) {
        case CallResult.Accepted:
          return 'success-text';
        case CallResult.Denied:
        case CallResult.Absence:
        case CallResult.RejectedAfterAccepted:
          return 'danger-text';

      }

    } else {

      const event = simpleEvent as Event;
      switch (event.type) {
        case EventType.Api:
        case EventType.WebChange:
        case EventType.WebSubstitution:
          return 'info-text';
        case EventType.TelegramAcceptAssistance:
        case EventType.TelegramConfirmShift:
          return 'success-text';
        case EventType.TelegramRejectAssistance:
        case EventType.TelegramRejectShift:
        case EventType.WebCancelled:
          return 'danger-text';

      }
    }

  }



  stateToIcon(simpleEvent: SimpleEvent) {


    if (simpleEvent['result']) {

      const call = simpleEvent as Call;
      switch (call.result) {
        case CallResult.Accepted:
          return 'phone-check';
        case CallResult.Denied:
          return 'phone-cancel';
        case CallResult.Off:
          return 'phone-off';
        case CallResult.Rejected:
          return 'phone-remove';
        case CallResult.HungUp:
          return 'phone-hangup';
        case CallResult.HungUpBeforeConfirm:
          return 'phone-hangup-outline';
        case CallResult.NotAnswer:
          return 'phone-missed';
        case CallResult.Communicates:
          return 'phone-alert';
        case CallResult.CallError:
          return 'phone-alert';
        case CallResult.NotEstablishing:
          return 'phone-alert';
        case CallResult.RejectedAfterAccepted:
          return 'phone-return';
        case CallResult.Absence:
          return 'account-clock';

      }

    } else {
      const event = simpleEvent as Event;

      switch (event.type) {
        case EventType.Api:
          return 'api';
        case EventType.WebCancelled:
          return 'account-cancel';
        case EventType.WebChange:
          return 'account-convert';
        case EventType.WebSubstitution:
          return 'account-search';
        case EventType.TelegramAcceptAssistance:
          return 'message';
        case EventType.TelegramRejectAssistance:
          return 'message-bulleted-off'
        case EventType.TelegramRejectShift:
          return 'message-off'
        case EventType.TelegramConfirmShift:
          return 'check';

      }

    }

  }


}
