<p-table
  responsiveLayout="scroll"

  [value]="entries.content"
  (onLazyLoad)="onLazyEvent($event)"
  [lazy]="true"
  [paginator]="true"
  [rows]="20"
  [totalRecords]="entries.totalElements"
  sortField="rankSubstitutions"
  [sortOrder]="1"
  #table
  styleClass="mat-elevation-z4">

  <ng-template pTemplate="header">


    <tr>

      <th>#</th>
      <th>Nombre</th>

      <th class="p-text-center" pSortableColumn="rankSubstitutions">
        <p-sortIcon field="rankSubstitutions"></p-sortIcon>
        Guardias
      </th>
      <th class="p-text-center" pSortableColumn="rankAuxiliaries">
        <p-sortIcon field="rankAuxiliaries"></p-sortIcon>
        Asistencias
      </th>

      <th style="width: 60px"></th>

    </tr>

  </ng-template>


  <ng-template pTemplate="body" let-entry>

    <tr>

      <td>
        {{ entry.lawyer.lawyerLicense }}
      </td>

      <td>
        {{ entry.lawyer.name }}
        {{ entry.lawyer.lastName}}
      </td>

      <td class="p-text-center">

        {{ query.length > 0 ? '---' : entry.rankSubstitutions }}
      </td>

      <td class="p-text-center">
        {{query.length > 0 ? '---' : entry.rankAuxiliaries }}
      </td>

      <td>


        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon svgIcon="dots-vertical"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="restoreAssistance(entry.lawyer.id)">
            <mat-icon svgIcon="account-details"></mat-icon>
            <span>Restaurar Asistencia</span>
          </button>


          <button mat-menu-item (click)="restoreSubstitution(entry.lawyer.id)">
            <mat-icon svgIcon="account-search"></mat-icon>
            <span>Restaurar Substitucion</span>
          </button>


          <button mat-menu-item (click)="confirmDelete(entry.lawyer.id)">
            <mat-icon svgIcon="delete"></mat-icon>
            <span>Eliminar</span>
          </button>

        </mat-menu>

      </td>


    </tr>


  </ng-template>


</p-table>
