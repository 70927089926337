import {Component, OnInit} from '@angular/core';
import {Audio, AudiosTableQuery, PageAudios} from "../../../generated/graphql";
import {AudiosService} from "../services/audios.service";
import {TableList} from "../../utils/TableList";
import {AudioComponent} from "../audio/audio.component";
import {MessageService} from "primeng/api";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-audios-table',
  templateUrl: './audios-table.component.html',
  styleUrls: ['./audios-table.component.scss'],
  providers: [MessageService]
})
export class AudiosTableComponent extends TableList implements OnInit {


  uploading = false;
  audios: AudiosTableQuery['audios'] = new PageAudios();


  constructor(
    private audiosService: AudiosService,
    private messageService: MessageService,
    dialog: MatDialog
  ) {
    super(AudioComponent, dialog);

  }

  ngOnInit(): void {


  }

  delete(id: number): void {
    this.audiosService.delete(id).subscribe(s => this.updateElements());
  }

  updateElements(): void {
    this.audiosService.list(this.pagination).subscribe( s => this.audios = s.data.audios);
  }


  downloadAudio(audio: Audio) {

    const a = document.createElement('a');
    a.href = `data:audio/wav;base64,${audio.file}`;
    a.download = audio.name+'.wav';
    a.click();
  }


  async upload(id: number, file: File) {
    this.uploading = true;

    const response = await this.audiosService.updateFile(id, file).toPromise();

    this.uploading = false;


    if (response.data.updateAudioFile) {
      this.messageService.add({severity: 'success', summary: 'Audio subido', detail: 'Se ha subido y convenrtido al formato correcto.'});
    } else {
      this.messageService.add({severity: 'warning', summary: 'Audio no subido', detail: 'No se ha podido subir el audio.'});
    }




    this.updateElements();

  }


  async generateAudio(id: number) {
    this.uploading = true;

    const response = await this.audiosService.generateAudio(id).toPromise();

    this.uploading = false;


    if (response.data.generateAudio) {
      this.messageService.add({severity: 'success', summary: 'Audio generado', detail: 'Se ha generado un audio a partir del texto.'});
    } else {
      this.messageService.add({severity: 'warning', summary: 'Audio no generado', detail: 'No se ha podido generar el audio.'});
    }



    this.updateElements();
  }
}
