import {Component, Inject} from '@angular/core';
import {Crud} from "../../utils/crud";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {WatchInput, WatchQuery, WatchService} from "../../../generated/graphql";

@Component({
  selector: 'app-watch',
  templateUrl: './watch.component.html',
  styleUrls: ['./watch.component.scss']
})
export class WatchComponent extends Crud {

  alertType: WatchQuery['alertTypes'] = [];
  lawyers: WatchQuery['lawyers']['content'] = [];
  judicialDistricts: WatchQuery['judicialDistricts'] = [];


  start = new Date();
  end = new Date();


  constructor(
    private service: WatchService,
    dialog: MatDialogRef<WatchComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
    ) {
    super(dialog, data);


    this.start.setDate(this.start.getDate() + 1);
    this.start.setHours(0, 0, 0, 0);

    this.end.setDate(this.end.getDate() + 1);
    this.end.setHours(23, 59, 59, 999);



    this.service.watch()
      .subscribe( s => {
        this.alertType = s.data.alertTypes;
        this.lawyers = s.data.lawyers.content;
        this.judicialDistricts = s.data.judicialDistricts;
      })

  }


  save(watch: WatchInput) {

    watch.end = new Date(watch.end.getTime() - watch.end.getTimezoneOffset() * 60000);
    watch.start =new Date(watch.start.getTime() - watch.start.getTimezoneOffset() * 60000);

    console.log(watch);

    this.onSave(this.service.createWatch({watch}));

  }

}
