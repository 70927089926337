<h2 mat-dialog-title>{{setting.name}}</h2>

<mat-dialog-content>

  <form #form="ngForm">


  <div class="p-grid p-fluid">

    <ng-container *ngIf="setting.type == 'STRING'">

      <div class="p-md-12 p-float-label">
        <input pInputText type="text" name="value" [ngModel]="setting.value" [pattern]="setting.options.pattern">
        <label>Valor</label>
      </div>


    </ng-container>


    <ng-container *ngIf="setting.type == 'SELECT'">

      <div class="p-md-12 p-float-label">
        <p-dropdown name="value" [ngModel]="setting.value" required [options]="setting.options.list"></p-dropdown>
        <label>Valor</label>
      </div>

    </ng-container>


    <ng-container *ngIf="setting.type == 'NUMERIC'">

      <div class="p-md-12 p-float-label">
        <p-inputNumber name="value" [ngModel]="setting.value"  required [min]="setting.options.min" [max]="setting.options.max"
                       [maxFractionDigits]="setting.options.decimals"></p-inputNumber>
        <label>Valor</label>
      </div>


    </ng-container>


    <ng-container *ngIf="setting.type == 'BOOLEAN'">

      <div class="p-md-12 p-float-label">
        <p-inputSwitch name="value" [ngModel]="setting.value"></p-inputSwitch>
        <label>Valor</label>
      </div>

    </ng-container>



    <ng-container *ngIf="setting.type == 'TEXTAREA'">

      <div class="p-md-12 p-float-label">
        <textarea pInputTextarea name="value" [ngModel]="setting.value" rows="20"></textarea>
        <label>Valor</label>
      </div>

    </ng-container>


    <ng-container *ngIf="setting.type == 'HTML'">

      <div class="p-md-12 p-float-label">
        <angular-editor [ngModel]="setting.value"  [config]="htmlEditorConfig" name="value"></angular-editor>
      </div>

    </ng-container>


  </div>
  </form>

</mat-dialog-content>

<mat-dialog-actions>

  <button mat-raised-button mat-dialog-close>
    Cerrar
  </button>

  <button mat-raised-button (click)="form.setValue({value: setting.default})">
    Por defecto
  </button>


  <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="save(form.value)">
    Guardar
  </button>

</mat-dialog-actions>

