<mat-toolbar class="mat-elevation-z4">
  <span fxFlex>Guardias</span>


  <app-upload-file [uploading]="uploading" *ngIf="config.data.importWatches" icon="database-import" (fileSelected)="importWatches($event)" accept="*.xls, *.xlsx"></app-upload-file>


  <button mat-icon-button (click)="exportExcel()">
    <mat-icon svgIcon="file-excel"></mat-icon>

  </button>


  <button mat-icon-button (click)="new()">
    <mat-icon svgIcon="plus"></mat-icon>
  </button>


</mat-toolbar>


<p-table
  #table
  responsiveLayout="scroll"
  [value]="watches.content"
  [paginator]="true"
  [totalRecords]="watches.totalElements"
  [rows]="20"
  [lazy]="true"
  tableStyleClass="mat-elevation-z4"
  (onLazyLoad)="onLazyEvent($event)"
  sortField="start,alertType.name,order,id"
  [sortOrder]="1"
  dataKey="id"
>


  <ng-template pTemplate="header">

    <tr>
      <th style="width:60px"></th>

      <th style="width: 60px;">
        <p-columnFilter field="status" matchMode="contains" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">

          <ng-template pTemplate="filter" let-value let-filter="filterCallback">

            <p-multiSelect [ngModel]="value" [options]="status" (onChange)="filter($event.value)" optionValue="value" optionLabel="label" [filter]="false">
            </p-multiSelect>

          </ng-template>

        </p-columnFilter>

      </th>

      <th>ID

        <p-columnFilter type="numeric" display="menu" field="id"></p-columnFilter>

      </th>

      <th pSortableColumn="start,alertType.name,order,id">
        <p-sortIcon field="start,alertType.name,order,id"></p-sortIcon>
        Fecha
        <p-columnFilter type="date" display="menu" field="start"></p-columnFilter>
      </th>


      <th pSortableColumn="lawyer.name">
        <p-sortIcon field="lawyer.name"></p-sortIcon>
        Abogado/a

        <p-columnFilter field="lawyer.id" matchMode="contains" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">

          <ng-template pTemplate="filter" let-value let-filter="filterCallback">

            <p-multiSelect
              [maxSelectedLabels]="1"
              virtualScroll="true" itemSize="30"
              [ngModel]="value" [options]="filters.lawyers"
              (onChange)="filter($event.value)" optionValue="id" optionLabel="fullName"></p-multiSelect>

          </ng-template>

        </p-columnFilter>


      </th>


      <th pSortableColumn="judicialDistrict.name">
        <p-sortIcon field="judicialDistrict.name"></p-sortIcon>
        Partido Judicial


        <p-columnFilter field="judicialDistrict.id" matchMode="contains" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">

          <ng-template pTemplate="filter" let-value let-filter="filterCallback">

            <p-multiSelect [ngModel]="value" [options]="filters.judicialDistricts" (onChange)="filter($event.value)" optionValue="id" optionLabel="name"></p-multiSelect>

          </ng-template>

        </p-columnFilter>

      </th>


      <th pSortableColumn="alias.name,order">
        <p-sortIcon field="alias.name,order"></p-sortIcon>
        Materia

        <p-columnFilter field="alias.id" matchMode="contains" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">

          <ng-template pTemplate="filter" let-value let-filter="filterCallback">

            <p-multiSelect [ngModel]="value" [options]="filters.alertsType" (onChange)="filter($event.value)" optionValue="id" optionLabel="name"></p-multiSelect>

          </ng-template>

        </p-columnFilter>

      </th>

      <th *ngIf="config.data.showWatchesOrder">
        Orden
      </th>


      <th style="width: 64px"></th>
    </tr>

  </ng-template>

  <ng-template pTemplate="body" let-watch let-expanded="expanded">

    <tr>
      <td>
        <button *ngIf="watch.allEvents.length > 0" mat-icon-button [pRowToggler]="watch">
          <mat-icon [svgIcon]="expanded ? 'chevron-down' : 'chevron-right'"></mat-icon>
        </button>
      </td>
      <td class="p-text-center">
        <mat-icon  class="{{statusColor(watch)}}-text" [svgIcon]="statusIcon(watch)" [matTooltip]="statusText(watch)"></mat-icon>
      </td>

      <td>{{watch.id}}</td>
      <td class="sort-padding">{{ watch.start | date:'dd/MM/YYYY' }}</td>
      <td class="sort-padding"> {{ watch.lawyer.fullName | titlecase }}</td>
      <td class="sort-padding">{{watch.judicialDistrict.name | titlecase}}</td>
      <td class="sort-padding">
        {{watch.alias.name | titlecase }}
      </td>
      <td *ngIf="config.data.showWatchesOrder"> {{ watch.order }}</td>

      <td>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon svgIcon="dots-vertical"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="changeWatch(watch)" [disabled]="!canChange(watch)">
            <mat-icon svgIcon="account-convert"></mat-icon>
            <span>Cambiar guardia</span>
          </button>

          <button mat-menu-item (click)="findSubstitute(watch)" [disabled]="!canChange(watch)">
            <mat-icon svgIcon="account-search"></mat-icon>
            <span>Buscar substituto/a</span>
          </button>


          <button mat-menu-item (click)="cancelWatch(watch)" [disabled]="!canChange(watch)">
            <mat-icon svgIcon="account-cancel"></mat-icon>
            <span>Cancelar guardia</span>
          </button>


        </mat-menu>


      </td>
    </tr>


  </ng-template>


  <ng-template pTemplate="rowexpansion" let-watch>

    <td colspan="1"></td>
    <td colspan="8">

      <app-calls [events]="$any(watch.allEvents)"></app-calls>

    </td>

  </ng-template>

</p-table>
