import {Component} from '@angular/core';
import {CourtsService} from "../services/courts.service";
import {MatDialog} from "@angular/material/dialog";
import {TableList} from "../../utils/TableList";
import {ListCourtsQuery, PageCourtOnDuty} from "../../../generated/graphql";
import {Filter} from "../../utils/Pagination";

@Component({
  selector: 'app-courts-table',
  templateUrl: './courts-table.component.html',
  styleUrls: ['./courts-table.component.scss']
})
export class CourtsTableComponent extends TableList {


  courts: ListCourtsQuery['courts'] = new PageCourtOnDuty();


  constructor(
    private courtsService: CourtsService,
    dialog: MatDialog
  ) {

    super(null, dialog)

  }

  ngOnInit(): void {
  }

  delete(id: number) {
  }

  updateElements() {

    const tomorrow = new Date(Date.now() - 24*60*60*1000);


    this.pagination.addDefaultFilter(new Filter('end', tomorrow, 'dateAfter', 'and'));

    this.courtsService
      .list(this.pagination)
      .subscribe( s => this.courts = s.data.courts);
  }

}
