import {Component, Inject, OnInit} from '@angular/core';
import {Setting} from "../../../generated/graphql";
import {SettingsService} from "../services/settings.service";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-setting-value',
  templateUrl: './setting-value.component.html',
  styleUrls: ['./setting-value.component.scss']
})
export class SettingValueComponent implements OnInit {


  setting: Setting;

  constructor(
    private settingsService: SettingsService,
    private dialog: MatDialogRef<SettingValueComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {

    this.setting = data.setting;
    console.log(this.setting);
  }

  ngOnInit(): void {


  }

  save(form: any) {

    this.settingsService.saveValue(form.value, this.setting.id)
      .subscribe(s => this.dialog.close());


  }


  htmlEditorConfig : AngularEditorConfig = {
    sanitize: false,
    editable: true,

    toolbarHiddenButtons: [
     [], [ 'image', 'insertVideo']
    ]

  }
}
