import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TableList} from '../../utils/TableList';
import {MatDialog} from "@angular/material/dialog";
import {ListEntryComponent} from '../list-entry/list-entry.component';
import {DeleteListEntryGQL, GetLawyerListGQL, GetLawyerListQuery, ListEntriesTableGQL, ListEntriesTableQuery, PageLawyerListEntry} from '../../../generated/graphql';
import {ActivatedRoute} from '@angular/router';
import {Filter} from '../../utils/Pagination';
import {ConfigService} from "../../settings/services/config.service";
import {ListService} from "../services/list.service";
import {PopupErrorComponent} from "../../utils/popup-error/popup-error.component";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-lists-entries-table',
  templateUrl: './lists-entries-table.component.html',
  styleUrls: ['./lists-entries-table.component.scss']
})
export class ListsEntriesTableComponent extends TableList implements OnChanges {


  @Input()
  query: string;

  id: number;
  list: GetLawyerListQuery['list'];
  entries: ListEntriesTableQuery['entries'] = new PageLawyerListEntry();
  uploading = false;


  constructor(dialog: MatDialog,
              getList: GetLawyerListGQL,
              route: ActivatedRoute,
              public config: ConfigService,
              private listsService: ListService,
              private snackBar: MatSnackBar,
              private listService: ListService,
              private getService: ListEntriesTableGQL,
              private deleteService: DeleteListEntryGQL,
  ) {

    super(ListEntryComponent, dialog);


    this.id = parseInt(route.snapshot.paramMap.get('id'));


    getList.fetch({id: this.id})
      .subscribe(s => this.list = s.data.list);


  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateElements();
  }

  ngOnInit(): void {
  }

  delete(id: number) {

    this.deleteService
      .mutate({list: this.id, lawyer: id})
      .subscribe(s => this.updateElements());
  }

  updateElements() {
    if (!this.pagination) return;

    this.pagination.setFilter(new Filter("list.id", this.id, "equals", null))

    if (this.query)
      this.pagination.setFilter(new Filter('global', this.query, 'contains', 'and'));
    else
      this.pagination.removeFilter('global');

    this.getService
      .fetch({pagination: this.pagination})
      .subscribe(s => this.entries = s.data.entries);
  }

  edit(id: number) {
    super.edit(this.list.id);
  }

  import(file: File) {

    this.uploading = true;

    this.listsService
      .importList(file, this.id)
      .subscribe(s => {

        if (s.errors) {
          this.dialog.open(PopupErrorComponent, {
            width: '90%',
            data: {
              errors: s.errors,
              title: 'Error al importar listado.'
            }
          });
        } else {
          this.snackBar.open('Listados actualizados.', 'Aceptar', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
          });

        }

        this.uploading = false;
        this.updateElements();
      })

  }


  restoreSubstitution(lawyer: number) {
    this.listsService.restoreSubstitution(lawyer, this.id).subscribe(s => this.updateElements());
  }

  restoreAssistance(lawyer: number) {
    this.listsService.restoreAssistance(lawyer, this.id).subscribe(s => this.updateElements());
  }


}
