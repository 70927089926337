<mat-toolbar class="mat-elevation-z4">
  <span fxFlex>
    Asistencias
  </span>


</mat-toolbar>


<p-table
  responsiveLayout="scroll"
  lazy="true"
  rows="20"
  [totalRecords]="assists.totalElements"
  (onLazyLoad)="onLazyEvent($event)"
  paginator="true"
  [value]="assists.content"
  dataKey="id"
  sortField="date"
  [sortOrder]="-1"
  tableStyleClass="mat-elevation-z4 size-s"
>


  <ng-template pTemplate="header">

    <tr>

      <th pSortableColumn="date">
        <p-sortIcon field="date"></p-sortIcon>
        Fecha

        <p-columnFilter type="date" display="menu" field="date"></p-columnFilter>
      </th>


      <th>
        Abogado
      </th>

      <th>
        Teléfono
      </th>

      <th pSortableColumn="alertType.name">
        <p-sortIcon field="alertType.name"></p-sortIcon>
        Materia

        <p-columnFilter field="alertType.id" matchMode="contains" display="menu" [showMatchModes]="false"
                        [showOperator]="false" [showAddButton]="false">

          <ng-template pTemplate="filter" let-value let-filter="filterCallback">

            <p-multiSelect [ngModel]="value" [options]="filters.alertTypes" (onChange)="filter($event.value)"
                           optionValue="id" optionLabel="name"></p-multiSelect>

          </ng-template>

        </p-columnFilter>

      </th>
    </tr>


  </ng-template>


  <ng-template pTemplate="body" let-assistance>


    <tr>
      <td>
        {{ assistance.date | date: 'HH:mm dd/MM/yyyy' }}
      </td>

      <td>
        {{ assistance.lawyer.fullName }}
      </td>

      <td>
        {{ assistance.caller }}
      </td>

      <td>
        {{assistance.alertType.name}}
      </td>
    </tr>

  </ng-template>
</p-table>


