import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {Watch, WatchesFiltersGQL, WatchesFiltersQuery, WatchesTableQuery, WatchStatus,} from '../../../generated/graphql';
import {Pagination} from '../../utils/Pagination';
import {TableList} from '../../utils/TableList';
import {MatDialog} from "@angular/material/dialog";
import {WatchChangeComponent} from '../watch-change/watch-change.component';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import {PopupErrorComponent} from '../../utils/popup-error/popup-error.component';
import {MatSnackBar} from "@angular/material/snack-bar";
import {WatchesService} from "../services/watches.service";
import {ConfigService} from "../../settings/services/config.service";
import {Table} from "primeng/table";
import {ActivatedRoute} from "@angular/router";
import {WatchComponent} from "../watch/watch.component";

@Component({
  selector: 'app-watches-table',
  templateUrl: './watches-table.component.html',
  styleUrls: ['./watches-table.component.scss']
})
export class WatchesTableComponent extends TableList implements AfterViewInit {


  @ViewChild('table')
  table: Table;

  pagination: Pagination;
  filters: WatchesFiltersQuery;
  watches: WatchesTableQuery['watches'] = {totalElements: 0, content: []};


  uploading = false;

  status = [];

  private ready = false;


  constructor(
    getFilters: WatchesFiltersGQL,
    dialog: MatDialog,
    public config: ConfigService,
    private snackBar: MatSnackBar,
    private watchesService: WatchesService,
    private route: ActivatedRoute
  ) {

    super(WatchComponent, dialog);

    getFilters.fetch().subscribe(s => this.filters = s.data);

    this.createStatus();
  }

  ngAfterViewInit(): void {

    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.table.filters['id'] = [{value: id, matchMode: 'equals', operator: 'and'}];
    } else {
      const start = new Date();
      start.setHours(0, 0, 0, 0);
      this.table.filters['start'] = [{value: start, matchMode: 'dateIs', operator: 'and'}];
    }


    this.ready = true;

    this.onLazyEvent(this.table.createLazyLoadMetadata());


  }


  updateElements(): void {

    if (this.ready)
      this.watchesService.list(this.pagination).subscribe(s => this.watches = s.data.watches);
  }


  statusIcon(watch: Watch): string {

    switch (watch.status) {
      case WatchStatus.Ok:
        return watch.substitution ? 'account-star' : 'account';
      case WatchStatus.Confirmed:
        return 'account-check';
      case WatchStatus.SearchingSubstitute:
        return 'account-search';
      case WatchStatus.Cancelled:
        return watch.substitution ? 'account-remove' : 'account-cancel';
    }
  }


  statusColor(watch: Watch): string {

    switch (watch.status) {
      case WatchStatus.Ok:
      case WatchStatus.Confirmed:
        return 'success';
      case WatchStatus.SearchingSubstitute:
        return 'warning';
      case WatchStatus.Cancelled:
        return 'danger';

    }
  }

  importWatches(file: File): void {

    this.uploading = true;

    this.watchesService.import(file)
      .subscribe(s => {
        this.uploading = false;


        if (s.errors) {
          this.dialog.open(PopupErrorComponent, {
            width: '90%',
            data: {
              errors: s.errors,
              title: 'Error al importar listado.'
            }
          });
        } else {
          this.snackBar.open('Guardias actualizadas.', 'Aceptar', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
          });

        }
        this.updateElements();

      });
  }

  changeWatch(watch: Watch): void {

    this.dialog.open(WatchChangeComponent, {
      width: '90%',
      data: {watch}
    }).afterClosed().subscribe(s => {

      if (s.errors) {
        this.dialog.open(PopupErrorComponent, {
          width: '90%',
          data: {
            errors: s.errors,
            title: 'Error al cambiar guardia.'
          }
        });

      } else {
        this.snackBar.open('Cambio realizado.',  'Aceptar', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
        })
        this.updateElements()
      }


    });


  }


  statusText(watch: Watch): string {

    switch (watch.status) {
      case WatchStatus.Ok:
        return watch.substitution ? 'Sustitucion' : 'Activo';
      case WatchStatus.SearchingSubstitute:
        return 'Buscando substituto';
      case WatchStatus.Cancelled:
        return watch.substitution ? 'Substitucón perdida' : 'Perdido';
      case WatchStatus.Confirmed:
        return 'Confirmado';
    }


  }


  delete(id: number): void {
  }

  findSubstitute(watch: Watch): void {
    this.dialog.open(ConfirmDialogComponent, {
      width: '90%',
      data: {
        title: '¿Seguro?',
        content: `Se buscará a alguien que substituya a ${watch.lawyer.fullName}. ¿Estás de acuerdo?`
      }
    }).afterClosed()
      .subscribe(s => {
        if (s) {
          this.watchesService.findSubstitute(watch.id).subscribe(e => {

            if (e.errors) {
              this.dialog.open(PopupErrorComponent, {
                width: '90%',
                data: {
                  errors: e.errors,
                  title: 'Error al buscar substituto.'
                }
              });
            } else {
              this.snackBar.open('Buscando substituto.',  'Aceptar', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
              });
              this.updateElements();

            }
          });
        }
      });
  }


  cancelWatch(watch: Watch) {
    this.dialog.open(ConfirmDialogComponent, {
      width: '90%',
      data: {
        title: '¿Seguro?',
        content: `La guardia de ${watch.lawyer.fullName} quedará DESIERTA. No se buscará substituto para esta guardia.`
      }
    }).afterClosed()
      .subscribe(s => {
        if (s) {
          this.watchesService.cancelWatch(watch.id).subscribe(e => {

            if (e.errors) {
              this.dialog.open(PopupErrorComponent, {
                width: '90%',
                data: {
                  errors: e.errors,
                  title: 'Error al cancelar guardia'
                }
              });
            } else {
              this.snackBar.open('Guardia cancelada.',  'Aceptar', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
              });
              this.updateElements();

            }
          });
        }
      });
  }

  private createStatus() {

    this.status = Object.keys(WatchStatus)
      .map(s => WatchStatus[s])
      .map(s => Object({label: this.statusText({status: s, confirmed: false} as any), value: s}));


  }

  exportExcel() {

    this.watchesService.exportToExcel(this.pagination).subscribe(s => {
      const a = document.createElement('a');
      a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${s.data.excel}`;
      a.download = 'Guardias.xlsx';
      a.click();

    });

  }

  canChange(watch: Watch) {

    if (watch.status == WatchStatus.Ok)
      return true;

    return watch.status == WatchStatus.Confirmed;


  }
}
