import {Injectable} from '@angular/core';
import {ChangePasswordGQL, GetMeGQL, ImportUsersGQL, SupplantGQL} from "../../../generated/graphql";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(

    private meService: GetMeGQL,
    private changeService: ChangePasswordGQL,
    private importService: ImportUsersGQL,
    private supplantService: SupplantGQL

    ) { }





  getMe() {
    return this.meService.fetch();
  }


  changePassword(old: string, password: string, pin: string) {
    return this.changeService.mutate({old, pin, password});
  }

  import(file: File) {
    return this.importService.mutate({file});
  }


  supplant(id: number) {
    return this.supplantService.fetch({id});
  }
}
