


<form #form="ngForm" >

<mat-card>

  <mat-card-title>Identificación</mat-card-title>

  <mat-card-content>


    <div fxLayout="row wrap" fxLayoutGap="8px grid">

      <mat-form-field fxFlex="100">
        <input matInput name="username" [(ngModel)]="username" placeholder="Nombre de usuario">
      </mat-form-field>


      <mat-form-field fxFlex="100">
        <input type="password" matInput name="password" [(ngModel)]="password" placeholder="Contraseña" />
      </mat-form-field>


      <mat-error fxFlex="100" *ngIf="error">
        Usuario y contraseña incorrectos
      </mat-error>




    </div>



  </mat-card-content>

  <mat-card-actions align="right">

    <button type="submit" mat-raised-button color="primary" (click)="login()">Acceder</button>

  </mat-card-actions>


</mat-card>
</form>
