<h2 mat-dialog-title>Audio/a</h2>


<form #form="ngForm" #formElement  >

  <mat-dialog-content>

    <div class="p-grid p-fluid">


      <div class="p-md-4 p-float-label">
        <input pInputText name="id" [ngModel]="audio.id" disabled>
        <label>ID</label>
      </div>

      <div class="p-md-4 p-float-label">
        <input pInputText name="name" required [ngModel]="audio.name">
        <label>Nombre</label>
      </div>


      <div class="p-md-4 p-float-label">
        <input pInputText name="code" required [ngModel]="audio.code" [readOnly]="audio.id">
        <label>Código</label>
      </div>




      <div class="p-md-12 p-float-label">
        <input pInputText name="text" type="text" required [ngModel]="audio.text">
        <label>Texto</label>
      </div>





    </div>

    <app-graphql-errors [errors]="errors" [form]="formElement"></app-graphql-errors>

  </mat-dialog-content>


  <mat-dialog-actions>

    <button mat-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button color="primary" [disabled]="!form.valid || loading" (click)="save(form.value)" [class.spinner]="loading">
      Guardar
    </button>

  </mat-dialog-actions>


</form>
