import {Component} from '@angular/core';
import {PageUsers, UsersTableGQL, UsersTableQuery} from '../../../generated/graphql';
import {MatDialog} from "@angular/material/dialog";
import {UserComponent} from '../user/user.component';
import {TableList} from "../../utils/TableList";
import {PopupErrorComponent} from "../../utils/popup-error/popup-error.component";
import {UsersService} from "../services/users.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthService} from "../auth/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent extends TableList {


  users: UsersTableQuery['users'] = new PageUsers();
  uploading = false;


  constructor(private service: UsersTableGQL,
              private usersService: UsersService,
              private snackBar: MatSnackBar,
              public auth: AuthService,
              private router: Router,
              dialog: MatDialog) {

    super(UserComponent, dialog)

  }


  updateElements() {
    this.service
      .fetch({pagination: this.pagination})
      .subscribe(s => this.users = s.data.users);

  }


  delete(id: number): void {
    throw new Error('Method not implemented.');
  }

  import(file: File) {

    this.uploading = true;

    this.usersService
      .import(file)
      .subscribe(s => {

        if (s.errors) {
          this.dialog.open(PopupErrorComponent, {
            width: '90%',
            data: {
              errors: s.errors,
              title: 'Error al importar usuarios.'
            }
          });
        } else {
          this.snackBar.open('Usuarios importados.', 'Aceptar', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
          });

        }

        this.uploading = false;
        this.updateElements();
      })

  }


  supplant(id: number) {


    this.usersService.supplant(id).subscribe(s => {
      this.auth.newToken(s.data.supplant);
      this.router.navigate(['/avisos']);

    });


  }
}
