<h2 mat-dialog-title>Usuario</h2>


<mat-dialog-content>

  <form #form="ngForm" autocomplete="off" #formElement>


    <mat-tab-group>

      <mat-tab label="General">

        <div class="test">

          <div class="p-grid p-fluid">


            <div class="p-md-6 p-float-label">
              <input pInputText name="name" [ngModel]="user?.name" required>
              <label>Nombre</label>
            </div>


            <div class="p-md-6 p-float-label">
              <input pInputText type="text" name="phone" [ngModel]="user?.phone" required>
              <label>Teléfono</label>
            </div>


            <div class="p-md-6 p-float-label">
              <p-dropdown [autoDisplayFirst]="false" [options]="profiles" optionLabel="description" optionValue="id" appendTo="body" name="profile" [ngModel]="user?.profile | ids">

              </p-dropdown>
              <label>Perfil</label>
            </div>


            <div class="p-md-6 p-float-label">
              <p-multiSelect [options]="districts" optionLabel="name" optionValue="id" appendTo="body" name="judicialDistricts" [ngModel]="user?.judicialDistricts | ids">
              </p-multiSelect>
              <label>Partidos judiciales</label>
            </div>


            <p-fieldset legend="Persona de contacto" class="p-col-12">

              <div class="p-grid">

                <div class="p-md-6 p-float-label">
                  <input pInputText name="contactName" [ngModel]="user?.contactName" required>
                  <label>Nombre</label>
                </div>

                <div class="p-md-6 p-float-label">
                  <input pInputText name="contactLastName" [ngModel]="user?.contactLastName" required>
                  <label>Apellidos</label>
                </div>


                <div class="p-md-6 p-float-label">
                  <input pInputText type="email" name="email" [ngModel]="user?.email" required>
                  <label>Email</label>
                </div>


                <div class="p-md-6 p-float-label">
                  <input pInputText type="telegram" name="telegram" [ngModel]="user?.telegram">
                  <label>Telegram ID</label>
                </div>

              </div>
            </p-fieldset>


            <p-fieldset legend="Acceso web" class="p-col-12">

              <div class="p-grid">

                <div class="p-md-6 p-float-label">
                  <input pInputText name="username" [ngModel]="user?.username" minlength="3" required>
                  <label>Nombre de usuario</label>
                </div>

                <div class="p-md-6">

                  <div class="p-inputgroup" *ngIf="id">


                    <div class="p-inputgroup-addon">
                      <p-checkbox #changePassword binary="true" [value]="false" required></p-checkbox>
                    </div>

                    <div class="p-float-label">
                      <input pInputText type="password" name="password" ngModel minlength="3" [disabled]="!changePassword.checked()" required autocomplete="new-password">
                      <label>Contraseña</label>
                    </div>

                  </div>


                  <div class="p-inputgroup" *ngIf="!id">

                    <div class="p-float-label">
                      <input pInputText type="password" name="password" ngModel minlength="3"  required autocomplete="new-password">
                      <label>Contraseña</label>
                    </div>

                  </div>


                </div>

              </div>


            </p-fieldset>


            <p-fieldset legend="Acceso telefónico" class="p-col-12">

              <div class="p-grid">

                <div class="p-md-6 p-float-label">
                  <input pInputText name="identification" pattern="[0-9]{4}"  [ngModel]="user?.identification" required>
                  <label>Código de identificación</label>
                </div>


                <div class="p-md-6" *ngIf="id">
                  <div class="p-inputgroup">

                    <div class="p-inputgroup-addon">
                      <p-checkbox binary="true" #changePin required></p-checkbox>
                    </div>

                    <div class="p-float-label">
                      <input pInputText type="password" name="pin" ngModel minlength="3" [disabled]="!changePin.checked()" required autocomplete="new-password">
                      <label>Código pin</label>
                    </div>
                  </div>


                </div>

                  <div class="p-md-6" *ngIf="!id">
                    <div class="p-inputgroup">

                      <div class="p-float-label">
                        <input pInputText type="password" name="pin" ngModel minlength="3"  required autocomplete="new-password">
                        <label>Código pin</label>
                      </div>
                    </div>


                </div>

              </div>


            </p-fieldset>


          </div>


        </div>


      </mat-tab>

      <mat-tab label="Notificaciones">


        <p-table
          responsiveLayout="scroll"
          [value]="alarms.content">


          <ng-template pTemplate="header">

            <tr>
              <th>Notificación</th>
              <th>Email</th>
              <th>Telegram</th>
              <th>Teléfono</th>
            </tr>

          </ng-template>


          <ng-template pTemplate="body" let-alarm>

            <tr ngModelGroup="alarms[{{alarm.id}}]">
              <td>
                {{alarm.name}}
                <input type="hidden" [ngModel]="alarm.id" name="alarm">
              </td>
              <td>
                <p-checkbox binary="true"  name="email" [ngModel]="checkAlarm('email', alarm.id)"></p-checkbox>
              </td>
              <td>
                <p-checkbox binary="true" name="telegram" [ngModel]="checkAlarm('telegram', alarm.id)"></p-checkbox>
              </td>

              <td>
                <p-checkbox binary="true" name="phone" [ngModel]="checkAlarm('phone', alarm.id)"></p-checkbox>
              </td>
            </tr>

          </ng-template>

        </p-table>


      </mat-tab>


    </mat-tab-group>


  </form>


</mat-dialog-content>

<mat-dialog-actions>


  <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="save(form.value)">
    Guardar
  </button>

  <button mat-dialog-close mat-button>Cerrar</button>


</mat-dialog-actions>


<p-toast></p-toast>
