

export function convertArray(value: any) {


  Object.keys(value)
    .filter( key => key.endsWith("]"))
    .forEach( key => {
      const name = key.substr(0, key.indexOf("["));
      const array = value[name] || [];

      array.push(value[key]);

      value[name] = array;

      delete value[key];
    });



}


export function download(url: string) {
  window.open(url, '_blank');
}
