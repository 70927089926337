<h2 mat-dialog-title> Perfil </h2>

<form #form="ngForm" #formElement>

  <mat-dialog-content>

    <div class="p-grid p-fluid">


      <div class="p-md-6 p-float-label">
        <input pInputText name="description" required [ngModel]="profile.description">
        <label>Nombre</label>
      </div>


      <div class="p-md-6 p-float-label">
        <input pInputText name="name" required [ngModel]="profile.name">
        <label>Código</label>
      </div>



      <div class="p-md-12 p-float-label">
        <p-multiSelect name="permissions"
                       optionValue="value"
                       optionLabel="label"
                       appendTo="body"
                       [ngModel]="profile.permissions | split"
                       [options]="permissions"
                       [group]="true"
        >

        </p-multiSelect>
        <label>Permisos</label>
      </div>


    </div>

    <app-graphql-errors [errors]="errors" [form]="formElement"></app-graphql-errors>

  </mat-dialog-content>


  <mat-dialog-actions>

    <button mat-button mat-dialog-close>Cerrar</button>

    <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="save(form.value)">
      Guardar
    </button>

  </mat-dialog-actions>


</form>
