import {Injectable} from '@angular/core';
import {RenovateTokenGQL} from '../../../generated/graphql';
import {Router} from '@angular/router';
import jwtDecode from 'jwt-decode';
import {Auth} from './Auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  auth = new Auth();
  roles = new Array<string>();



  constructor(private renovateToken: RenovateTokenGQL,
              private router: Router
              ) {


    this.load();
    this.init();

  }


  init(): void {

    this.renovateToken.fetch()
      .subscribe(value => {
        if (value.errors || value.data.token == '')
          this.logout();
        else
          this.newToken(value.data.token);
      });


  }


  private load(): void {
    try {
      this.auth = jwtDecode<Auth>(AuthService.getToken(), {header: false});
      this.roles = this.auth.roles.split(",");
    } catch (e) {
      this.clear();
    }
  }


  newToken(token: string) {
    AuthService.saveToken(token);
    this.load();
  }


  logout() {
    this.clear();
    this.router.navigate(['/']);
  }


  private clear() {
    AuthService.removeToken();
    this.auth = new Auth();
    this.roles = [];
  }

  get logged(): boolean {
    return this.auth.id > 0;
  }


  get admin(): boolean {
    return this.hasRole(Auth.ADMIN);
  }


  get shifts(): boolean {
    return this.hasRole(Auth.WATCHES);
  }


  get alerts(): boolean {
    return this.hasRole(Auth.ALERTS);
  }


  get ownAlerts(): boolean {
    return this.hasRole(Auth.OWN_ALERTS);
  }


  get users(): boolean {
    return this.hasRole(Auth.USERS);
  }

  get lawyers(): boolean {
    return this.hasRole(Auth.LAWYERS);
  }

  get assists(): boolean {
    return this.hasRole(Auth.ASSISTS);
  }

  get readLawyer(): boolean {
    return this.hasRole(Auth.READ_LAWYERS) || this.hasRole(Auth.LAWYERS);
  }

  get courts(): boolean {
    return this.hasRole(Auth.COURTS);
  }

  get superAdmin(): boolean {
    return this.hasRole(Auth.SUPER_ADMIN);
  }

  get createAlert(): boolean  {
    return this.hasRole(Auth.CREATE_ALERT);
  }

  private hasRole(role: string): boolean {
    return this.roles.indexOf(role) > -1;
  }

  get exportAlerts(): boolean {
    return this.hasRole(Auth.EXPORT_ALERTS);
  }

  get supplant(): boolean {
    return this.hasRole(Auth.SUPPLANT);
  }


  get alertNotAnswered(): boolean {
    return this.hasRole(Auth.ALERT_NOT_ANSWERED);
  }


  get informs() : boolean {
    return this.hasRole(Auth.INFORMS);
  }

  private static getToken(): string {
    return localStorage.getItem('token');
  }

  private static removeToken(): void {
    return localStorage.removeItem('token');
  }



  private static saveToken(token: string): void {
    localStorage.setItem('token', token);
  }


}
