import {Component} from '@angular/core';
import {TableList} from "../../utils/TableList";
import {ProfileComponent} from "../profile/profile.component";
import {ProfilesService} from "../services/profiles.service";
import {FindProfilesQuery} from "../../../generated/graphql";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-profiles-table',
  templateUrl: './profiles-table.component.html',
  styleUrls: ['./profiles-table.component.scss']
})
export class ProfilesTableComponent extends TableList {


  profiles: FindProfilesQuery['profiles'] = {content: [], totalElements: 0};


  constructor(
    matDialog: MatDialog,
    private profilesService: ProfilesService
  ) {
    super(ProfileComponent, matDialog);
  }


  delete(id: number): void {
  }

  updateElements(): void {
    this.profilesService.find(this.pagination).subscribe( s => this.profiles = s.data.profiles);
  }

}
