<mat-toolbar class="mat-elevation-z4">

  <span fxFlex>
    Configuración
  </span>


  <input pInputText class="p-inputtext-sm" type="text" [(ngModel)]="filter" (keyup)="onFilter()" placeholder="Filtrar"/>

  <span>

    <button mat-icon-button (click)="apply()" matTooltip="Aplicar configuración">
      <mat-icon svgIcon="send-check"></mat-icon>
    </button>

    <button mat-icon-button (click)="new()">
      <mat-icon svgIcon="plus"></mat-icon>
    </button>

  </span>


</mat-toolbar>

<mat-tab-group #tabs [selectedIndex]="category" (selectedIndexChange)="category = $event">

  <mat-tab *ngFor="let c of categories" [label]="c.name">


    <p-table
      [value]="c.settings"
      groupRowsBy="group"
      rowGroupMode="rowspan"
      dataKey="id"
      sortMode="single"
      sortField="position"
    >



      <ng-template pTemplate="header">

        <tr>
          <th>Grupo</th>
          <th>Nombre</th>
          <th>Valor</th>
          <th style="width: 140px"></th>
        </tr>

      </ng-template>




      <ng-template pTemplate="body" let-setting  let-rowIndex="rowIndex" let-rowgroup="rowgroup" let-rowspan="rowspan">

        <tr>

          <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
            {{setting.group}}
          </td>

          <td>{{setting.name}}</td>
          <td>
            {{setting.value | slice:0:50}}

          </td>

          <td>

            <button mat-icon-button (click)="editValue(setting)">
              <mat-icon svgIcon="form-textbox"></mat-icon>

            </button>

            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(setting.id)">
                <mat-icon svgIcon="pencil"></mat-icon>
                <span>Editar</span>
              </button>
            </mat-menu>
          </td>

        </tr>


      </ng-template>

    </p-table>







  </mat-tab>
</mat-tab-group>


