import {Injectable} from '@angular/core';
import {CancelWatchGQL, ExportWatchesToExcelGQL, FindWatchSubstituteGQL, ImportWatchesGQL, WatchesTableGQL} from "../../../generated/graphql";
import {Pagination} from "../../utils/Pagination";

@Injectable({
  providedIn: 'root'
})
export class WatchesService {

  constructor(
    private excelService: ExportWatchesToExcelGQL,
    private importService: ImportWatchesGQL,
    private listService: WatchesTableGQL,
    private findSubstituteService: FindWatchSubstituteGQL,
    private cancelWatchService: CancelWatchGQL,

  ) { }


  exportToExcel(pagination: Pagination) {
    return this.excelService.fetch({pagination});
  }


  import(excel: File) {
    return this.importService.mutate({excel});
  }

  list(pagination: Pagination) {
    return this.listService.fetch({pagination});
  }


  findSubstitute(watch: number) {
    return this.findSubstituteService.mutate({watch});
  }


  cancelWatch(watch: number) {
    return this.cancelWatchService.mutate({watch});
  }

}
