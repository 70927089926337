import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ids'
})
export class IdsPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): any {

    if (value == null)
      return null;

    if (Array.isArray(value))
      return value.map( v => v.id);

    if (typeof value == 'object')
      return value.id;

    return undefined;
  }

}
