import {Injectable} from '@angular/core';
import {DeleteMenuGQL, FindMenusGQL, GetMenuGQL, Menu, MenuCreateGQL, MenuInput, MenuPositionGQL, MenuSaveGQL, MenusFiltersGQL,} from "../../../generated/graphql";
import {Pagination} from "../../utils/Pagination";

@Injectable({
  providedIn: 'root'
})
export class MenusService {

  constructor(
    private listService: FindMenusGQL,
    private filtersService: MenusFiltersGQL,
    private getService: GetMenuGQL,
    private newPositionService: MenuPositionGQL,
    private saveService: MenuSaveGQL,
    private createService: MenuCreateGQL,
    private deleteService: DeleteMenuGQL,
  ) {

  }

  list(pagination?: Pagination) {
    return this.listService.fetch({pagination});
  }



  filters() {
    return this.filtersService.fetch();
  }


  get(id: number) {
    return this.getService.fetch({id});
  }


  newPosition(id: number, position: number) {
    return this.newPositionService.mutate({id, position});
  }


  save(menu: MenuInput, id: number) {
    return this.saveService.mutate({id, menu});
  }



  create(parent: Menu) {


    return this.createService.mutate({
      judicialDistrict: parent.judicialDistrict.id,
      profile: parent.profile.id,
      parent: parent.id
    });
  }


  delete(id: number) {

    return this.deleteService.mutate({id});

  }
}
