import {Injectable} from '@angular/core';
import {ListCourtsGQL} from "../../../generated/graphql";
import {Pagination} from "../../utils/Pagination";

@Injectable({
  providedIn: 'root'
})
export class CourtsService {

  constructor(private listService: ListCourtsGQL) {

  }

  list(pagination: Pagination) {
    return this.listService.fetch({pagination});
  }
}
