<mat-toolbar class="mat-elevation-z4">

  Informes

</mat-toolbar>


<form #form="ngForm" autocomplete="off" #formElement>

  <mat-card>


    <mat-card-content>

      <div class="p-grid p-fluid">


        <div class="p-md-6 p-float-label">
          <p-calendar name="start" dataType="date"
                      appendTo="body" dateFormat="dd/mm/yy"
                      ngModel [required]="true">
          </p-calendar>
          <label>Inicio</label>
        </div>


        <div class="p-md-6 p-float-label">
          <p-calendar name="end" dataType="date"
                      appendTo="body" dateFormat="dd/mm/yy"
                      ngModel [required]="true">
          </p-calendar>
          <label>Fin</label>
        </div>


      </div>


    </mat-card-content>

    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="emptyWatches(form.value)" [disabled]="form.invalid">
        <mat-icon svgIcon="account-clock"></mat-icon>
        Guardias vacías
      </button>


    </mat-card-actions>


  </mat-card>
</form>
