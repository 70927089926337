

<button mat-raised-button *ngIf="text != ''" (click)="play()" ngxLongPress2 (onLongPress)="download()">
  {{text}}
</button>

<button mat-icon-button ngxLongPress2 [disabled]="!file"  (click)="play()" *ngIf="text == ''"  (onLongPress)="download()" [maxTime]="">
  <mat-icon [svgIcon]="file ? 'microphone-message': 'microphone-message-off'"></mat-icon>
</button>


