<mat-toolbar class="mat-elevation-z4">
  <span fxFlex>Abogados/as</span>

  <input pInputText class="p-inputtext-sm" type="text" (input)="table.filterGlobal($event.target.value, 'contains')" placeholder="Filtrar">


  <app-upload-file [uploading]="uploading" *ngIf="config.data.importLawyers" icon="database-import" (fileSelected)="importLawyers($event)" accept="*.xls, *.xlsx"></app-upload-file>


  <button mat-icon-button (click)="new()" *ngIf="config.data.updateLawyers">
    <mat-icon svgIcon="plus"></mat-icon>
  </button>


</mat-toolbar>


<p-table
  responsiveLayout="scroll"

  #table
  [value]="lawyers.content"
  (onLazyLoad)="onLazyEvent($event)"
  [lazy]="true"
  [paginator]="true"
  [rows]="20"
  [totalRecords]="lawyers.totalElements"
  sortField="name,lastName"
  [sortOrder]="1"

  styleClass="mat-elevation-z4"

>

  <ng-template pTemplate="header">


    <tr>
      <th>Número</th>
      <th>Nombre</th>
      <th>Teléfono</th>
      <th>Email</th>
      <th>Partido</th>

      <th *ngIf="config.data.updateLawyers" style="width: 65px" ></th>
    </tr>

  </ng-template>


  <ng-template pTemplate="body" let-element >

    <tr>

      <td>
        {{ element.lawyerLicense }}
      </td>

      <td>
        {{element.name + ' ' + element.lastName | titlecase}}
      </td>

      <td>
        {{ element.phoneNumber | phone }}
      </td>

      <td>
        {{ element.email | email }}
      </td>



      <td>
        {{ element.judicialDistrict.name | titlecase }}
      </td>

      <td *ngIf="config.data.updateLawyers">

        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon svgIcon="dots-vertical"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="edit(element.id)">
            <mat-icon svgIcon="pencil"></mat-icon>
            <span *ngIf="auth.lawyers">Editar</span>
            <span *ngIf="!auth.lawyers">Ver</span>
          </button>
          <button mat-menu-item>
            <mat-icon svgIcon="delete"></mat-icon>
            <span>Eliminar</span>
          </button>
        </mat-menu>


      </td>
    </tr>


  </ng-template>


</p-table>
