import {Pipe, PipeTransform} from '@angular/core';
import {ConfigService} from "../settings/services/config.service";

@Pipe({
  name: 'email'
})
export class EmailPipe implements PipeTransform {

  constructor(private config: ConfigService) {
  }

  transform(value: string, ...args: unknown[]): unknown {

    if (!this.config.data.demoMode)
      return value;


    const middle = value.indexOf("@");


    return value.substr(0, 3) + 'xxxxxx' + value.substr(middle) ;
  }

}
