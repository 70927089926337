<mat-toolbar class="mat-elevation-z4">

  <span fxFlex>
    Usuarios/as
  </span>

  <input pInputText class="p-inputtext-sm" type="text" (input)="table.filterGlobal($event.target.value, 'contains')"
         placeholder="Filtrar"/>


  <span>
    <button mat-icon-button (click)="new()">
      <mat-icon svgIcon="plus"></mat-icon>
    </button>


    <app-upload-file [uploading]="uploading" icon="database-import" (fileSelected)="import($event)" accept=".xls, *.xlsx"></app-upload-file>


    <button mat-icon-button [matMenuTriggerFor]="options">
          <mat-icon svgIcon="dots-vertical"></mat-icon>
    </button>

  </span>
  <mat-menu #options="matMenu">
    <a mat-menu-item target="_blank" href="/assets/files/excels/users-template.xlsx">
      <mat-icon svgIcon="file-excel"></mat-icon>
      <span>Plantilla de importación</span>
    </a>
  </mat-menu>

</mat-toolbar>


<p-table
  responsiveLayout="scroll"
  [value]="users.content" #table
  [lazy]="true"
  [totalRecords]="users.totalElements"
  (onLazyLoad)="onLazyEvent($event)"
  rows="20"
  paginator="true"
  tableStyleClass="users mat-elevation-z4"
  sortField="name"
  [sortOrder]="1"
  [globalFilterFields]="['name', 'username', 'email', 'identification', 'phone']"
>


  <ng-template pTemplate="header">

    <tr>

      <th pSortableColumn="name">
        <p-sortIcon field="name"></p-sortIcon>

        Nombre
      </th>

      <th pSortableColumn="contactName">
        <p-sortIcon field="contactName"></p-sortIcon>
        Contacto
      </th>


      <th pSortableColumn="username">
        <p-sortIcon field="username"></p-sortIcon>

        Acceso web
      </th>
      <th>
        Acceso teléfonico

      </th>

      <th>Email</th>
      <th>Teléfono</th>

      <th style="width: 70px"></th>
    </tr>


  </ng-template>


  <ng-template pTemplate="body" let-user>


    <tr>
      <td class="sort-padding">{{user.name}}</td>

      <td class="sort-padding">{{user.contactName}} {{user.contactLastName}}</td>

      <td class="sort-padding">{{user.username}}</td>

      <td>{{user.identification}}</td>

      <td>{{user.email | email }}</td>
      <td>{{user.phone | phone }}</td>

      <td>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon svgIcon="dots-vertical"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="edit(user.id)">
            <mat-icon svgIcon="pencil"></mat-icon>
            <span>Editar</span>
          </button>

          <button *ngIf="auth.supplant" mat-menu-item (click)="supplant(user.id)">
            <mat-icon svgIcon="account-switch"></mat-icon>
            <span>Suplantar</span>
          </button>
        </mat-menu>
      </td>
    </tr>
  </ng-template>


</p-table>
