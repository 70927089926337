import {Component} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {AuthService} from '../users/auth/auth.service';
import {ConfigService} from "../settings/services/config.service";
import {MatDialog} from "@angular/material/dialog";
import {ChangePasswordsComponent} from "../users/change-passwords/change-passwords.component";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {



  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );


  constructor(private breakpointObserver: BreakpointObserver,
              public loginService: AuthService,
              public config: ConfigService,
              public dialog: MatDialog
  ) {

  }

  logout() {
    this.loginService.logout();
  }

  changePassword() {

    this.dialog.open(ChangePasswordsComponent, {
      width: '400px',
      maxWidth: '90%'
    });

  }
}
