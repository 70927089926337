import {NgModule} from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {LawyersTableComponent} from './lawyers/lawyers-table/lawyers-table.component';
import {TableModule} from 'primeng/table';
import {WatchesTableComponent} from './watches/watches-table/watches-table.component';
import {NavigationComponent} from './navigation/navigation.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app.routing.module';
import {GraphqlModule} from './graphql.module';
import {DistrictsTableComponent} from './districts/districts-table/districts-table.component';
import {AlertsTableComponent} from './alerts/alerts-table/alerts-table.component';
import {TagModule} from 'primeng/tag';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule} from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {AlertDetailsComponent} from './alerts/alert-details/alert-details.component';
import {CapitalizePipe} from './pipes/capitalize.pipe';
import {UsersTableComponent} from './users/users-table/users-table.component';
import {LoginComponent} from './users/login/login.component';
import {InputTextModule} from 'primeng/inputtext';
import {UserComponent} from './users/user/user.component';
import {FieldsetModule} from 'primeng/fieldset';
import {CheckboxModule} from 'primeng/checkbox';
import {ToastModule} from 'primeng/toast';
import {MultiSelectModule} from 'primeng/multiselect';
import {PrimeNGConfig} from 'primeng/api';
import {ListsTableComponent} from './lists/lists-table/lists-table.component';
import {ListComponent} from './lists/list/list.component';
import {ListsEntriesTableComponent} from './lists/lists-entries-table/lists-entries-table.component';
import {ListEntryComponent} from './lists/list-entry/list-entry.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {CalendarModule} from 'primeng/calendar';
import {LawyerComponent} from './lawyers/lawyer/lawyer.component';
import {GraphqlErrorsComponent} from './utils/graphql-errors/graphql-errors.component';
import {UploadFileComponent} from './utils/upload-file/upload-file.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {AssistsTableComponent} from './assists/assists-table/assists-table.component';
import {WatchChangeComponent} from './watches/watch-change/watch-change.component';
import {PopupErrorComponent} from './utils/popup-error/popup-error.component';
import {CourtsTableComponent} from './courts/courts-table/courts-table.component';
import {CallsComponent} from './alerts/calls/calls.component';
import {PhonePipe} from './pipes/phone.pipe';
import {AlertPipe} from "./alerts/pipes/alert.pipe";
import {InputNumberModule} from 'primeng/inputnumber';
import {MenuTableComponent} from './menus/menu-table/menu-table.component';
import {MenuComponent} from './menus/menu/menu.component';
import {TreeTableModule} from 'primeng/treetable';
import {AudiosTableComponent} from './audios/audios-table/audios-table.component';
import {AudioComponent} from './audios/audio/audio.component';
import {PlayAudioComponent} from './utils/play-audio/play-audio.component';
import {InputSwitchModule} from "primeng/inputswitch";
import {SettingComponent} from './settings/setting/setting.component';
import {SettingsTableComponent} from './settings/settings-table/settings-table.component';
import {ChipsModule} from "primeng/chips";
import {SettingValueComponent} from './settings/setting-value/setting-value.component';
import {CreateAlertComponent} from './alerts/create-alert/create-alert.component';
import {InputTextareaModule} from "primeng/inputtextarea";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {AudioRecordingComponent} from './utils/audio-recording/audio-recording.component';
import {IdsPipe} from './pipes/ids.pipe';
import {LoadingDirective} from "./utils/directives/LoadingDirective";
import {EmailPipe} from './pipes/email.pipe';
import {ProfileComponent} from './users/profile/profile.component';
import {ProfilesTableComponent} from './users/profiles-table/profiles-table.component';
import {SplitPipe} from './pipes/split.pipe';
import {NgxLongPress2Module} from 'ngx-long-press2';

import {ListViewComponent} from './lists/list-view/list-view.component';
import {CallsTableComponent} from './lists/calls-table/calls-table.component';
import {CallStateComponent} from './utils/call-state/call-state.component';
import {ChangePasswordsComponent} from './users/change-passwords/change-passwords.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatCardModule} from "@angular/material/card";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDialogModule} from "@angular/material/dialog";
import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatInputModule} from "@angular/material/input";
import {MatTabsModule} from "@angular/material/tabs";
import {InputMaskModule} from "primeng/inputmask";
import {InformsListComponent} from './informs/informs-list/informs-list.component';
import {WatchComponent} from './watches/watch/watch.component';

@NgModule({
  declarations: [
    AppComponent,
    LawyersTableComponent,
    WatchesTableComponent,
    NavigationComponent,
    DistrictsTableComponent,
    AlertsTableComponent,
    AlertDetailsComponent,
    CapitalizePipe,
    UsersTableComponent,
    LoginComponent,
    UserComponent,
    ListsTableComponent,
    ListComponent,
    ListsEntriesTableComponent,
    ListEntryComponent,
    LawyerComponent,
    GraphqlErrorsComponent,
    UploadFileComponent,
    ConfirmDialogComponent,
    AssistsTableComponent,
    WatchChangeComponent,
    PopupErrorComponent,
    CourtsTableComponent,
    CallsComponent,
    PhonePipe,
    AlertPipe,
    CallsComponent,
    MenuTableComponent,
    MenuComponent,
    AudiosTableComponent,
    AudioComponent,
    PlayAudioComponent,
    SettingComponent,
    SettingsTableComponent,
    SettingValueComponent,
    CreateAlertComponent,
    AudioRecordingComponent,
    IdsPipe,
    LoadingDirective,
    EmailPipe,
    ProfileComponent,
    ProfilesTableComponent,
    SplitPipe,
    ListViewComponent,
    CallsTableComponent,
    CallStateComponent,
    ChangePasswordsComponent,
    InformsListComponent,
    WatchComponent,
  ],
  imports: [
//    NgxLongPress2Module,
    FlexLayoutModule,
    FormsModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatIconModule,
    TableModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    RouterModule,
    GraphqlModule,
    TagModule,
    ButtonModule,
    RippleModule,
    DropdownModule,
    MatDialogModule,
    MatTooltipModule,
    MatCardModule,
    InputTextModule,
    MatInputModule,
    FieldsetModule,
    CheckboxModule,
    MatMenuModule,
    ToastModule,
    MultiSelectModule,
    MatExpansionModule,
    CalendarModule,
    MatSnackBarModule,
    InputNumberModule,
    TreeTableModule,
    InputSwitchModule,
    MatTabsModule,
    ChipsModule,
    InputTextareaModule,
    AngularEditorModule,
    InputMaskModule,
    NgxLongPress2Module
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {


  constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, private config: PrimeNGConfig) {
    iconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );

    this.translation();
  }

  translation() {
    this.config.setTranslation({
      startsWith: 'Empieza con',
      contains: 'Contiene',
      notContains: 'No contiene',
      endsWith: 'Termina en',
      equals: 'Igual',
      notEquals: 'Distinto',
      noFilter: 'No filtrar',
      lt: 'Menor',
      lte: 'Menor o igual',
      gt: 'Mayor',
      gte: 'Mayor o igual',
      is: 'Es',
      dateIs: 'Igual',
      dateIsNot: 'Distinta',
      dateBefore: 'Antes',
      dateAfter: 'Después',

      isNot: 'No es',
      before: 'Antes',
      after: 'Después',
      clear: 'Limpiar',
      apply: 'Aplicar',
      matchAll: 'Todos',
      matchAny: 'Alguno',
      addRule: 'Añadir regla',
      removeRule: 'Eliminar regla',
      accept: 'Aceptar',
      reject: 'Rechazar',
      choose: 'Seleccionar',
      upload: 'Subir',
      cancel: 'Cancelar',
      dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
      monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'],
      today: 'Hoy',
      weekHeader: 'Semana'
    });
  }


}
