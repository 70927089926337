import {Component} from '@angular/core';
import {LawyersTableGQL, LawyersTableQuery} from '../../../generated/graphql';
import {TableList} from '../../utils/TableList';
import {MatDialog} from "@angular/material/dialog";
import {LawyerComponent} from '../lawyer/lawyer.component';
import {AuthService} from '../../users/auth/auth.service';
import {PopupErrorComponent} from "../../utils/popup-error/popup-error.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LawyersService} from "../services/lawyers.service";
import {ConfigService} from "../../settings/services/config.service";

@Component({
  selector: 'app-lawyers-table',
  templateUrl: './lawyers-table.component.html',
  styleUrls: ['./lawyers-table.component.scss']
})
export class LawyersTableComponent extends TableList {

  constructor(
    private lawyersTableGQL: LawyersTableGQL,
    public auth: AuthService,
    private snackBar: MatSnackBar,
    private lawyersService: LawyersService,
    public config: ConfigService,
    matDialog: MatDialog) {
    super(LawyerComponent, matDialog);
  }



  lawyers: LawyersTableQuery['lawyers'] = {totalElements: 0, content: []};
  uploading = false;


  delete(id: number) {

  }

  updateElements() {
    this.lawyersTableGQL
      .fetch({page: this.pagination })
      .subscribe(result => this.lawyers = result.data.lawyers);

  }



  importLawyers(file: File): void {

    this.uploading = true;

    this.lawyersService.import(file)
      .subscribe(s => {
        this.uploading = false;


        if (s.errors) {
          this.dialog.open(PopupErrorComponent, {
            width: '90%',
            data: {
              errors: s.errors,
              title: 'Error al importar listado.'
            }
          });
        } else {
          this.snackBar.open('Abogados actualizados.', 'Aceptar', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
          });

        }
        this.updateElements();

      });
  }


}
