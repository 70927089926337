import {Injectable} from '@angular/core';
import {AlertInput, ContinueAlertGQL, CreateNewAlertGQL, FindOptionsGQL} from "../../../generated/graphql";

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(
    private optionsService: FindOptionsGQL,
    private createService: CreateNewAlertGQL,
    private continueService: ContinueAlertGQL
  ) {

  }


  createOptions() {
    return this.optionsService.fetch();
  }


  createAlert(alert: AlertInput) {
    return this.createService.mutate({alert});
  }


  continueAlert(id: number) {
    return this.continueService.mutate({id});
  }

}
