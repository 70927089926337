import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CallType} from "../../../generated/graphql";
import {ListsEntriesTableComponent} from "../lists-entries-table/lists-entries-table.component";
import {ListService} from "../services/list.service";

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit {


  list: number = 0;


  debounce;
  query: string = ""

  generatingExcel = false;

  ASSISTANCE = CallType.List;
  SUBSTITUTION = CallType.Substitute;


  @ViewChild('table')
  table: ListsEntriesTableComponent;


  constructor(
    private listsService: ListService,
    route: ActivatedRoute) {
    this.list = parseInt(route.snapshot.paramMap.get('id'));
  }

  ngOnInit(): void {
  }

  async export() {

    this.generatingExcel = true;


    const s = await this.listsService.export(this.list).toPromise();

    const a = document.createElement('a');
    a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${s.data.file}`;
    a.download = `Lista ${this.table.list?.name}.xlsx`;
    a.click();

    this.generatingExcel = false;


  }

  updateQuery(event: any) {

    clearTimeout(this.debounce);

    this.debounce = setTimeout(() => {
      this.query  = event.target.value;
    }, 500);

  }
}
