<mat-toolbar class="mat-elevation-z4">


  <span fxFlex>
    Lista {{ table.list?.name }}
  </span>


  <span>

    <input pInputText class="p-inputtext-sm" type="text" (keyup)="updateQuery($event)" placeholder="Filtrar"/>


    <app-upload-file [uploading]="table.uploading" *ngIf="table.config.data.importList" icon="database-import" (fileSelected)="table.import($event)" accept=".xls, *.xlsx"></app-upload-file>


    <button mat-icon-button (click)="export()" [class.spinner]="generatingExcel" [disabled]="generatingExcel" spinner>
      <mat-icon svgIcon="file-excel"></mat-icon>
    </button>

    <button mat-icon-button (click)="table.new()">
      <mat-icon svgIcon="plus"></mat-icon>
    </button>

    <button mat-icon-button [matMenuTriggerFor]="options">
          <mat-icon svgIcon="dots-vertical"></mat-icon>
    </button>


  </span>

  <mat-menu #options="matMenu">
    <a mat-menu-item target="_blank" href="/assets/files/excels/list-template.xlsx">
      <mat-icon svgIcon="file-excel"></mat-icon>
      <span>Plantilla de importación</span>
    </a>
  </mat-menu>


</mat-toolbar>


<mat-tab-group>


  <mat-tab label="Abogados/as">

    <app-lists-entries-table #table [id]="list" [query]="query"></app-lists-entries-table>


  </mat-tab>


  <mat-tab label="Llamadas guardias">


    <app-calls-table [callType]="SUBSTITUTION" [list]="list" [query]="query"></app-calls-table>

  </mat-tab>

  <mat-tab label="Llamadas asistencias">
    <app-calls-table [callType]="ASSISTANCE" [list]="list" [query]="query"></app-calls-table>

  </mat-tab>

</mat-tab-group>
