import {Component} from '@angular/core';
import {InformsListService} from "../../../generated/graphql";
import {download} from "../../utils/forms-utils";

@Component({
  selector: 'app-informs-list',
  templateUrl: './informs-list.component.html',
  styleUrls: ['./informs-list.component.scss']
})
export class InformsListComponent {


  constructor(
    private service: InformsListService
  ) {
  }




  emptyWatches(data: any) {

    this.service.informEmptyWatches(data)
      .subscribe( s => {


        download(s.data.informEmptyWatches);
      });

  }


}
