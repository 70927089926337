import {Injectable} from '@angular/core';
import {AudioInput, AudiosTableGQL, DeleteAudioGQL, GenerateAudioGQL, GetAudioGQL, SaveAudioGQL, UpdateAudioFileGQL} from "../../../generated/graphql";
import {Pagination} from "../../utils/Pagination";

@Injectable({
  providedIn: 'root'
})
export class AudiosService {

  constructor(
    private listService: AudiosTableGQL,
    private getService: GetAudioGQL,
    private saveService: SaveAudioGQL,
    private deleteService: DeleteAudioGQL,
    private updateService: UpdateAudioFileGQL,
    private generateService: GenerateAudioGQL,
  ) {

  }


  list(pagination?: Pagination) {
    return this.listService.fetch({pagination});
  }

  get(id: number) {
    return this.getService.fetch({id});
  }

  save(audio: AudioInput, id?: number) {
    return this.saveService.mutate({id, audio});
  }

  delete(id: number) {
    return this.deleteService.mutate({id});
  }

  updateFile(id: number, file: File) {
    return this.updateService.mutate({id, file});
  }


  generateAudio(id: number) {
    return this.generateService.mutate({id});
  }


}
