import {Component, OnInit} from '@angular/core';
import {DistrictsTableGQL, DistrictsTableQuery} from '../../../generated/graphql';
import {LazyLoadEvent} from 'primeng/api';
import {Pagination} from "../../utils/Pagination";

@Component({
  selector: 'app-districts-table',
  templateUrl: './districts-table.component.html',
  styleUrls: ['./districts-table.component.scss']
})
export class DistrictsTableComponent implements OnInit {


  districts: DistrictsTableQuery['districts'] = {totalElements: 0, content: []};

  constructor(private service: DistrictsTableGQL) { }

  ngOnInit(): void {


  }


  onLazyEvent(event: LazyLoadEvent) {

    const pagination = new Pagination(event);

    this.service
      .fetch({pagination})
      .subscribe( s => this.districts = s.data.districts);


  }

}
