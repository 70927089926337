<h2 mat-dialog-title> {{title}} </h2>


<mat-dialog-content>


  <mat-error>

    <ul>
      <li *ngFor="let error of errors">
        {{error.message}}
      </li>
    </ul>
  </mat-error>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
