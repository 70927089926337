import {Component, Inject} from '@angular/core';
import {LawyersTableGQL, LawyersTableQuery, Watch, WatchChangeGQL} from '../../../generated/graphql';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {GraphQLError} from 'graphql';

@Component({
  selector: 'app-watch-change',
  templateUrl: './watch-change.component.html',
  styleUrls: ['./watch-change.component.scss']
})
export class WatchChangeComponent {

  watch: Watch;
  errors: readonly GraphQLError[] = [];
  lawyers: LawyersTableQuery['lawyers'] = {totalElements: 0, content: []};

  constructor(
    lawyersService: LawyersTableGQL,
    private watchChange: WatchChangeGQL,
    private dialog: MatDialogRef<WatchChangeComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {

    this.watch = data.watch;

    lawyersService.fetch({
      page: {
        page: 0,
        size: 100000,
        columns: 'name,lastName',
        direction: 1,
        filters: []
      }
    }).subscribe( s => this.lawyers = s.data.lawyers);


  }


  save(value) {

    this.watchChange
      .mutate({lawyer: value.lawyer, watch: this.watch.id})
      .subscribe( s => {
        this.errors = s.errors;

        if (s.data.watchChange) this.dialog.close();

      });

  }
}
