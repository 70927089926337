import {Component, OnInit} from '@angular/core';
import {TableList} from '../../utils/TableList';
import {ListComponent} from '../list/list.component';
import {DeleteListGQL, ImportListsGQL, ListsTableQuery} from '../../../generated/graphql';
import {PopupErrorComponent} from "../../utils/popup-error/popup-error.component";
import {ConfigService} from "../../settings/services/config.service";
import {ListService} from "../services/list.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-lists-table',
  templateUrl: './lists-table.component.html',
  styleUrls: ['./lists-table.component.scss']
})
export class ListsTableComponent extends TableList implements OnInit {

  uploading: boolean = false;
  lists: ListsTableQuery['lists'] = { totalElements: 0, content: []};

  constructor(dialog: MatDialog,
              private snackBar: MatSnackBar,
              private importList: ImportListsGQL,
              private deleteService: DeleteListGQL,
              private listService: ListService,
              public config: ConfigService
  ) {
    super(ListComponent, dialog);

  }


  delete(id: number) {
    this.deleteService.mutate({id})
      .subscribe(_ => this.updateElements());
  }

  updateElements() {

    this.listService.list().subscribe(s => this.lists = JSON.parse(JSON.stringify(s.data.lists)));


  }

  ngOnInit() {
    this.updateElements();

  }


  import(file: File) {

    this.uploading = true;

    this.importList.mutate({file})
      .subscribe(s => {

        if (s.errors) {
          this.dialog.open(PopupErrorComponent, {
            width: '90%',
            data: {
              errors: s.errors,
              title: 'Error al importar listado.'
            }
          });
        } else {
          this.snackBar.open('Listados actualizados.', 'Aceptar', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 1000*20
          });

        }

        this.uploading = false;
        this.updateElements();
      });
  }

}
