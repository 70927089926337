import {Component, Inject} from '@angular/core';
import {GetUserQuery, UserInput, UserService} from '../../../generated/graphql';
import {MessageService} from 'primeng/api';
import {convertArray} from "../../utils/forms-utils";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Crud} from "../../utils/crud";
import {Page} from "../../utils/page";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [MessageService]
})
export class UserComponent extends Crud {

  user: GetUserQuery['user'];
  profiles: GetUserQuery['profiles'] = [];
  districts: GetUserQuery['districts'] = [];
  alarms: GetUserQuery['alarms'] = new Page();


  constructor(
    private userService: UserService,
    private messageService: MessageService,
    dialog: MatDialogRef<UserComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    super(dialog, data);


    this.userService.getUser({id: this.id})
      .subscribe( s => {
        this.user = s.data.user;
        this.alarms = s.data.alarms;
        this.profiles = s.data.profiles;
        this.districts = s.data.districts;
      });

  }


  save(user: UserInput) {

    convertArray(user);

    if (this.isNew)
      this.onSave(this.userService.createUser({user}))
    else
      this.onSave(this.userService.updateUser({user, id: this.id}));

  }


  result(result: any) {

    for (const errorsKey in result.errors) {
      const error = result.errors[errorsKey];
      this.messageService.add({severity: 'error', summary: 'Error', detail: error.message});
    }


    if (result.errors == undefined) {
      this.messageService.add({severity: 'success', summary: 'Guardado', detail: 'Se ha guardado el usuario.'});
      this.dialog.close();
    }
  }

  checkAlarm(notification: string, id: number) {

    const alarm = this.user?.alarms?.find(a => a.alarm == id) || {};
    return alarm[notification];
  }
}
