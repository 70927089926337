<p-toast></p-toast>


<mat-toolbar class="mat-elevation-z4">
  <span fxFlex>Audios</span>

  <input pInputText class="p-inputtext-sm" type="text" (input)="table.filterGlobal($event.target.value, 'contains')" placeholder="Filtrar"/>


  <button mat-icon-button (click)="new()">
    <mat-icon svgIcon="plus"></mat-icon>
  </button>


</mat-toolbar>


<p-table
  responsiveLayout="scroll"

  #table
  [value]="audios.content"
  (onLazyLoad)="onLazyEvent($event)"
  [lazy]="true"
  [paginator]="true"
  [rows]="20"
  [totalRecords]="audios.totalElements"
  styleClass="mat-elevation-z4"
>

  <ng-template pTemplate="header">


    <tr>
      <th>Nombre</th>
      <th>Código</th>
      <th>Texto</th>
      <th style="width: 120px" ></th>
    </tr>

  </ng-template>


  <ng-template pTemplate="body" let-element >

    <tr>

      <td>
        {{ element.name }}
      </td>

      <td>
        {{ element.code }}
      </td>

      <td>
        {{ element.text }}
      </td>


      <td>

        <app-play-audio [file]="element.file"></app-play-audio>




        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon svgIcon="dots-vertical"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">

          <button mat-menu-item (click)="file.click()">
            <app-upload-file #file [uploading]="uploading" icon="file-upload"  label="" accept="audio/*" (fileSelected)="upload(element.id, $event)"></app-upload-file>
            Subir audio
          </button>

          <button mat-menu-item (click)="generateAudio(element.id)">
            <mat-icon svgIcon="text-to-speech"></mat-icon> Generar audio
          </button>

          <button mat-menu-item (click)="downloadAudio(element)">
            <mat-icon svgIcon="file-download"></mat-icon> Descargar
          </button>

          <button mat-menu-item (click)="edit(element.id)">
            <mat-icon svgIcon="pencil"></mat-icon>
            <span>Editar</span>
          </button>
          <button mat-menu-item (click)="delete(element.id)">
            <mat-icon svgIcon="delete"></mat-icon>
            <span>Eliminar</span>
          </button>
        </mat-menu>


      </td>
    </tr>


  </ng-template>


</p-table>
