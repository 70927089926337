import {Component, Inject, OnInit} from '@angular/core';
import {ProfilesService} from "../services/profiles.service";
import {Profile, ProfileInput} from "../../../generated/graphql";
import {GraphQLError} from "graphql";
import {SelectItemGroup} from "primeng/api";
import {Auth} from "../auth/Auth";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {


  profile = new Profile();
  errors: ReadonlyArray<GraphQLError>;


  constructor(
    private profilesServices: ProfilesService,
    private dialog: MatDialogRef<ProfileComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {


    if (data.id) {
      this.profilesServices.get(data.id).subscribe(s => this.profile = s.data.profile);
    }


  }

  ngOnInit(): void {
  }


  async save(profile: ProfileInput) {

    profile.permissions = (profile.permissions as any).join(",");

    const response = await this.profilesServices.save(profile, this.profile.id).toPromise()
    this.errors = response.errors;

    if (response.data) this.dialog.close();

  }


  permissions: SelectItemGroup[] = [
    {
      label: 'Centralita',
      items: [
        {label: 'Autoencolado de avisos', value: 'AUTOENQUEUE'},
        {label: 'Creación de avisos restringidos', value: 'ALERT_CREATION_RESTRICTED'},
        {label: 'Crear avisos seguidos', value: 'MULTIPLE_ALERT_CREATION'},
        {label: 'Seleccionar fecha', value: 'CUSTOM_DATE'},
        {label: 'Seleccionar fecha desactivado', value: 'CUSTOM_DISABLED'},
        {label: 'Preguntar si es urgente', value: 'ASK_IF_URGENT'}
      ]
    },

    {
      label: 'Web',
      items: [
        {label: 'Abogados (solo lectura)', value: Auth.READ_LAWYERS},
        {label: 'Abogados', value: Auth.LAWYERS},
        {label: 'Administrar', value: Auth.ADMIN},
        {label: 'Alertas', value: Auth.ALERTS},
        {label: 'Alertas propias', value: Auth.OWN_ALERTS},
        {label: 'Informes', value: Auth.INFORMS},
        {label: 'Asistencias', value: Auth.ASSISTS},
        {label: 'Crear alertas', value: Auth.CREATE_ALERTS},
        {label: 'Exportar alertas', value: Auth.EXPORT_ALERTS},
        {label: 'Recordar justificar alerta no atendida', value: Auth.ALERT_NOT_ANSWERED},
        {label: 'Guardias', value: Auth.WATCHES},
        {label: 'Juzgados', value: Auth.COURTS},
        {label: 'Super Administración', value: Auth.SUPER_ADMIN},
        {label: 'Usuarios', value: Auth.USERS},
        {label: 'Suplantar', value: Auth.SUPPLANT}
      ]
    }


  ];

}


