import {Component, Inject} from '@angular/core';
import {LawyerList, ListEntryComponentGQL, ListEntryComponentQuery, ListEntrySaveGQL} from '../../../generated/graphql';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-list-entry',
  templateUrl: './list-entry.component.html',
  styleUrls: ['./list-entry.component.scss'],
  providers: [MessageService]
})
export class ListEntryComponent {


  lawyer: number = 0;
  lawyers: ListEntryComponentQuery['lawyers'] = [];
  list: ListEntryComponentQuery['list'] = new LawyerList();

  constructor(
    getService: ListEntryComponentGQL,
    private dialog: MatDialogRef<ListEntryComponent>,
    private saveService: ListEntrySaveGQL,
    @Inject(MAT_DIALOG_DATA) data,
  ) {

    getService.fetch({list: data.id})
      .subscribe( s => {
        this.list = s.data.list;
        this.lawyers = s.data.lawyers;
      })

  }


  save() {
    this.saveService
      .mutate({list: this.list.id, lawyer: this.lawyer})
      .subscribe( s => this.dialog.close());
  }
}
