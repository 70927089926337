<mat-toolbar class="mat-elevation-z4">
  <span>Menus</span>
</mat-toolbar>


<p-treeTable
  [value]="menus"
  sortField="order"
  styleClass="mat-elevation-z4"
  (onNodeExpand)="onNodeExpand($event)"
  (onNodeCollapse)="onNodeCollapse($event)"
>



  <ng-template pTemplate="header">
    <tr>
      <th>
        Menú
      </th>

      <th>
        Audio
      </th>


      <th>
        Materia
      </th>
      <th>
        Lista
      </th>
      <th>
        Incompatible
      </th>


      <th style="width: 60px">

      </th>

    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-node let-element="rowData">
    <tr>
      <td>
        <p-treeTableToggler [rowNode]="node"></p-treeTableToggler>
        {{element.order}}
      </td>

      <td>
        <app-play-audio *ngIf="element.audio" [file]="element.audio?.file"></app-play-audio>

        {{ element.audio?.name }}

      </td>

      <td>
        {{element.alertType?.name}}
      </td>
      <td>
        {{element.lawyerList?.name}}
      </td>
      <td>
        {{element.incompatible ? 'Sí':''}}
      </td>



      <td>

        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu" *ngIf="element.profile">
          <mat-icon svgIcon="dots-vertical"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">

          <ng-container *ngIf="element.id">


            <button mat-menu-item (click)="edit(element.id)">
              <mat-icon svgIcon="pencil"></mat-icon> Editar
            </button>

            <button mat-menu-item (click)="addChild(element)">
              <mat-icon svgIcon="plus"></mat-icon>
              Añadir hijo

            </button>

            <button mat-menu-item (click)="up(element)">
              <mat-icon svgIcon="arrow-up"></mat-icon>
              <span>Subir posición</span>
            </button>

            <button mat-menu-item (click)="down(element)">
              <mat-icon svgIcon="arrow-down"></mat-icon>
              <span>Bajar posición</span>
            </button>


            <button mat-menu-item (click)="delete(element.id)">
              <mat-icon svgIcon="delete"></mat-icon>
              Eliminar
            </button>


          </ng-container>


          <ng-container *ngIf="!element.id">
            <button mat-menu-item (click)="addChild(element)">
              <mat-icon svgIcon="plus"></mat-icon>
              Añadir hijo
            </button>

          </ng-container>


        </mat-menu>


      </td>


    </tr>

  </ng-template>
</p-treeTable>
