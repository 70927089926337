import {Component, OnInit} from '@angular/core';
import {IMediaRecorder, MediaRecorder, register} from 'extendable-media-recorder';
import {connect} from "extendable-media-recorder-wav-encoder";
import {MatDialogRef} from "@angular/material/dialog";


@Component({
  selector: 'app-audio-recording',
  templateUrl: './audio-recording.component.html',
  styleUrls: ['./audio-recording.component.scss']
})
export class AudioRecordingComponent implements OnInit {

  recorder: IMediaRecorder;
  stream: MediaStream;

  state = 'NOT_PERMISSION';


  public recorded = '';

  time = '00:00';
  private start = 0;
  private interval = 0;


  private chunks = [];
  private file: Blob;

  constructor(private dialog: MatDialogRef<AudioRecordingComponent>) {

    dialog.afterClosed().subscribe( _ => this.close());

  }

  async ngOnInit() {


    try {
      await register(await connect());
    } catch (e) {}


    this.stream = await navigator.mediaDevices.getUserMedia({audio: true});
    this.recorder = new MediaRecorder(this.stream, {mimeType: 'audio/wav'});


    this.state = 'NO_RECORD';

    this.onStart();

  }


  onStart() {

    this.state = 'RECORDING';
    this.start = Date.now();
    this.chunks = [];

    this.recorder.start();
    this.recorder.ondataavailable = e => {
      this.chunks.push(e.data);
      if (this.recorder.state == "inactive") {
        this.file = new Blob(this.chunks, {type: 'audio/wav'});
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onloadend = () => {
          this.recorded = reader.result.toString();
          console.log(this.recorded);
        }
      }
    };

    this.interval = setInterval(_ => {
      const seconds = (Date.now() - this.start) / 1000;
      this.time = `${Math.floor(seconds / 60)}`.padStart(2, '0') + ":" + `${Math.floor(seconds % 60)}`.padStart(2, '0')
    }, 100);


  }


  onStop() {
    this.recorder.stop();

    this.state = 'RECORDED';
    clearInterval(this.interval);
  }

  confirm() {
    this.dialog.close(this.file);
  }

  close() {

    if (this.stream)
      this.stream.getAudioTracks().forEach( t => t.stop());

  }

}
