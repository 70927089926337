import {Injectable} from '@angular/core';
import {ApplySettingsGQL, FindSettingsGQL, GetSettingGQL, SaveSettingsGQL, SaveSettingValueGQL, SettingInput} from "../../../generated/graphql";
import {Pagination} from "../../utils/Pagination";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private listService: FindSettingsGQL,
    private getService: GetSettingGQL,
    private saveService: SaveSettingsGQL,
    private saveValueService: SaveSettingValueGQL,
    private applyService: ApplySettingsGQL
  ) {

  }



  list(pagination: Pagination) {
    return this.listService.fetch({pagination});
  }

  get(id: number) {
    return this.getService.fetch({id});
  }

  save(setting: SettingInput, id: number) {
    return this.saveService.mutate({setting, id});
  }

  saveValue(value: string, id: number) {
    return this.saveValueService.mutate({value, id});
  }

  apply() {
    return this.applyService.mutate();
  }



}
