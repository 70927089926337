import {Component, OnInit, ViewChild} from '@angular/core';
import {AlertsService} from "../services/alerts.service";
import {FindOptionsQuery, GetMeQuery} from "../../../generated/graphql";
import {UsersService} from "../../users/services/users.service";
import {GraphQLError} from "graphql";
import {AudioRecordingComponent} from "../../utils/audio-recording/audio-recording.component";
import {filter} from "rxjs/operators";
import {NgForm} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-create-alert',
  templateUrl: './create-alert.component.html',
  styleUrls: ['./create-alert.component.scss']
})
export class CreateAlertComponent implements OnInit {

  loading = false;

  options: FindOptionsQuery['options'];

  judicialDistricts = [];
  alertsType = [];

  hour = '00:00';
  audio = '';
  diligence = ''
  persons = 1;
  isRaid = false;

  user: GetMeQuery['user'];
  errors: ReadonlyArray<GraphQLError> = [];

  urgent: boolean = false;



  @ViewChild('form')
  form: NgForm;

  date = -1;

  constructor(
    private alertsService: AlertsService,
    private userService: UsersService,
    private dialog: MatDialogRef<CreateAlertComponent>,
    private dialogService: MatDialog,
    private snackbar: MatSnackBar
  ) {


  }

  ngOnInit(): void {


    this.alertsService.createOptions().subscribe(s => {
      this.urgent = s.data.showIsUrgent;
      this.options = s.data.options;
      this.getJudicialDistricts();
    });


    this.userService.getMe().subscribe(s => this.user = s.data.user);

  }


  getJudicialDistricts() {

    this.judicialDistricts = this.options
      .map(o => o.judicialDistrict)
      .filter((value, index, self) => self.findIndex(j => j.id == value.id) == index)

  }

  getAlertsType(judicialDistrict) {

    this.alertsType = this.options
      .filter(o => o.judicialDistrict.id == judicialDistrict)
      .map(o => ({id: o.id, name: o.alertType.name + (o.incompatible ? ' D. Incompatible' : '')}));

  }


  updateAudio() {

    if (this.form.invalid) {
      this.audio = '';
      return;
    }

    this.audio = `Hola, le llamamos desde ${this.user.name}.\n`
    + `El número de atestado es ${this.diligence}.${this.calculateDate()} Hora aproximada ${this.hour}.\n`
    + `${this.persons == 1 ? '': `Hay que prestar asistencia a varias personas.\n`}`
    + `Nuestro teléfono es ${this.user.phone}. Gracias.`;
  }

  async save(value: any, urgent: boolean) {

    this.loading = true;

    if (!this.isRaid)
      value.detained = 1;


    value.urgent = urgent;
    delete value.hour;

    const response = await this.alertsService.createAlert(value).toPromise();

    this.loading = false;
    this.errors = response.errors;


    if (!response.data)
      return;


    this.dialog.close();

    this.snackbar.open(`Aviso creado con el ID ${response.data.createAlert.id}`, 'Aceptar', {
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });

  }

  async record(value: any) {


    this.dialogService.open(AudioRecordingComponent, {
      width: '600px',
    })
      .afterClosed()
      .pipe(filter(s => s))
      .subscribe(s => this.save(value, s));

  }


  updateAlertType(event) {
    const option = this.options.find( o => o.id == event.value);

    this.isRaid = option.isRaid || option.askDetained;

    if (!this.isRaid) this.persons = 1;


    this.updateAudio();
  }


  calculateDate() {
    if (this.date < 0) return '';


    const date = new Date();
    date.setHours(0, 0, 0, 0);

    do {
      date.setDate(date.getDate() + 1)
    } while (date.getDay() != this.date);


    const week = this.dates.find( s => this.date == s.value).label.toLowerCase();

    return ` Debe presentarse el próximo ${week}, día ${date.getDate()}.`
  }


  dates = [
    { value: -1, label: 'Hoy'},
    { value: 1, label: 'Lunes' },
    { value: 2, label: 'Martes' },
    { value: 3, label: 'Miércoles' },
    { value: 4, label: 'Jueves' },
    { value: 5, label: 'Viernes' },
    { value: 6, label: 'Sábado' },
    { value: 0, label: 'Domingo' },
  ];
}
