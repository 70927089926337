import {Component, Inject, OnInit} from '@angular/core';
import {GetSettingQuery, Setting, SettingInput, SettingsOptions, SettingsType} from "../../../generated/graphql";
import {SettingsService} from "../services/settings.service";
import {GraphQLError} from "graphql";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {


  errors: ReadonlyArray<GraphQLError>;
  setting: GetSettingQuery['setting'] = new Setting();
  types = [
    {
      value: SettingsType.Select,
      label: 'Selección',
    },
    {
      value: SettingsType.String,
      label: 'Texto'

    },
    {
      value: SettingsType.Boolean,
      label: 'Booleano'
    },

    {
      value: SettingsType.Numeric,
      label: 'Numérico'
    },

    {
      value: SettingsType.Textarea,
      label: 'Texto grande'
    },

    {
      value: SettingsType.Html,
      label: 'HTML'
    }


  ]


  constructor(
    private settingsService: SettingsService,
    private dialog: MatDialogRef<SettingComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {

    this.setting.type = SettingsType.String;
    this.setting.options = new SettingsOptions();

    if (data.id) this.settingsService.get(data.id).subscribe(s => {
      this.setting = s.data.setting;

    });

  }

  ngOnInit(): void {
  }

  save(setting: SettingInput) {
    this.settingsService.save(setting, this.setting.id)
      .subscribe( s => {

        this.errors = s.errors;

        if (s.data)
          this.dialog.close()
      });

  }
}
