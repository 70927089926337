import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WatchesTableComponent} from './watches/watches-table/watches-table.component';
import {LawyersTableComponent} from './lawyers/lawyers-table/lawyers-table.component';
import {DistrictsTableComponent} from './districts/districts-table/districts-table.component';
import {AlertsTableComponent} from './alerts/alerts-table/alerts-table.component';
import {UsersTableComponent} from './users/users-table/users-table.component';
import {LoginComponent} from './users/login/login.component';
import {UserGuard} from './guards/user.guard';
import {GuestGuard} from './guards/guest.guard';
import {ListsTableComponent} from './lists/lists-table/lists-table.component';
import {AssistsTableComponent} from './assists/assists-table/assists-table.component';
import {CourtsTableComponent} from "./courts/courts-table/courts-table.component";
import {MenuTableComponent} from "./menus/menu-table/menu-table.component";
import {AudiosTableComponent} from "./audios/audios-table/audios-table.component";
import {SettingsTableComponent} from "./settings/settings-table/settings-table.component";
import {ProfilesTableComponent} from "./users/profiles-table/profiles-table.component";
import {ListViewComponent} from "./lists/list-view/list-view.component";
import {InformsListComponent} from "./informs/informs-list/informs-list.component";

const routes: Routes = [

  {
    path: '', component: LoginComponent, canActivate: [GuestGuard]
  },

  {
    path: 'turnos', component: WatchesTableComponent, canActivate: [UserGuard]

  },

  {
    path: 'guardias', component: WatchesTableComponent, canActivate: [UserGuard]

  },

  {
    path: 'guardias/:id', component: WatchesTableComponent, canActivate: [UserGuard],
  },

  {
    path: 'abogados', component: LawyersTableComponent, canActivate: [UserGuard]
  },

  {

    path: 'partidos-judiciales', component: DistrictsTableComponent, canActivate: [UserGuard]
  },

  {

    path: 'avisos', component: AlertsTableComponent, canActivate: [UserGuard]

  },

  {
    path: 'avisos/:id', component: AlertsTableComponent, canActivate: [UserGuard]
  },


  {
    path: 'usuarios', component: UsersTableComponent, canActivate: [UserGuard]
  },

  {
    path: 'listas', component: ListsTableComponent, canActivate: [UserGuard]
  },

  {
    path: 'listas/:id', component: ListViewComponent, canActivate: [UserGuard]
  },

  {
    path: 'asistencias', component: AssistsTableComponent, canActivate: [UserGuard]
  },


  {
    path: 'juzgados', component: CourtsTableComponent, canActivate: [UserGuard]
  },


  {
    path: 'menus', component: MenuTableComponent, canActivate: [UserGuard]
  },

  {
    path: 'audios', component: AudiosTableComponent, canActivate: [UserGuard]
  },

  {
    path: 'configuracion', component: SettingsTableComponent
  },

  {
    path: 'perfiles', component: ProfilesTableComponent
  },


  {
    path: 'informes', component: InformsListComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
