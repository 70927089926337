import {GraphQLError} from "graphql";
import {MatDialogRef} from "@angular/material/dialog";
import {MutationResult} from "apollo-angular";
import {Observable} from "rxjs";


export abstract class Crud {

  public errors: ReadonlyArray<GraphQLError> | undefined;


  protected constructor(
    protected dialog: MatDialogRef<any>,
    private data: any
  ) {


  }


  protected onSave(result: Observable<MutationResult>) {

    result.subscribe(s => {
      this.errors = s.errors;
      if (s.data) this.dialog.close();
    });

  }


  get id() {
    return this.data.id || 0;
  }

  get isNew() {
    return this.id == 0;
  }

}
