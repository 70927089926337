<h2 mat-dialog-title>Cambiar contraseñas</h2>


<mat-dialog-content>

  <form #form="ngForm" autocomplete="off" #formElement  >


          <div class="p-grid p-fluid">


            <div class="p-md-12 p-float-label">
              <input pInputText type="password" name="old" ngModel required>
              <label>Contraseña actual</label>
            </div>


            <div class="p-md-6 p-float-label">
              <input pInputText type="password" name="password" minlength="3" ngModel >
              <label>Nueva contraseña</label>
            </div>

            <div class="p-md-6 p-float-label">
              <input pInputText type="password" name="passwordConfirm" minlength="3" ngModel>
              <label>Confirma contraseña</label>
            </div>

            <div class="p-md-6 p-float-label">
              <input pInputText type="password" name="pin" minlength="3" maxlength="4" ngModel>
              <label>Pin</label>
            </div>

            <div class="p-md-6 p-float-label">
              <input pInputText type="password" name="pinConfirm" minlength="3" ngModel>
              <label>Confirma pin</label>
            </div>



            <app-graphql-errors  [errors]="errors" [form]="formElement"></app-graphql-errors>

          </div>
  </form>


</mat-dialog-content>

<mat-dialog-actions>


  <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="save(form.value)">
    Guardar
  </button>

  <button mat-dialog-close mat-button>Cerrar</button>


</mat-dialog-actions>
