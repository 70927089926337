import {Injectable} from '@angular/core';
import {SendNotificationCallNotAnsweredGQL, SendNotificationEventNotAnsweredGQL} from "../../../generated/graphql";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private eventNotAnsweredService: SendNotificationEventNotAnsweredGQL,
    private callNotAnsweredService: SendNotificationCallNotAnsweredGQL
  ) {


  }


  eventNotAnswered(id: number) {
    return this.eventNotAnsweredService.fetch({id});
  }

  callNotAnswered(id: number) {
    return this.callNotAnsweredService.fetch({id});
  }


}
