import {Injectable} from '@angular/core';
import {FindProfilesGQL, GetProfileGQL, ProfileInput, SaveProfileGQL} from "../../../generated/graphql";
import {Pagination} from "../../utils/Pagination";

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  constructor(
    private getService: GetProfileGQL,
    private findService: FindProfilesGQL,
    private saveService: SaveProfileGQL
  ) {


  }


  find(pagination: Pagination) {
    return this.findService.fetch({pagination});
  }

  save(profile: ProfileInput, id: number) {
    return this.saveService.mutate({id, profile});
  }

  get(id: number) {
    return this.getService.fetch({id});
  }
}
