import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Lawyer, LawyerComponentGQL, LawyerComponentQuery, LawyerInput, SaveLawyerGQL} from '../../../generated/graphql';
import {GraphQLError} from 'graphql';
import {AuthService} from '../../users/auth/auth.service';
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-lawyer',
  templateUrl: './lawyer.component.html',
  styleUrls: ['./lawyer.component.scss']
})
export class LawyerComponent implements AfterViewInit {

  errors: ReadonlyArray<GraphQLError>;
  lawyer: LawyerComponentQuery['lawyer'] = new Lawyer();
  judicialDistricts: LawyerComponentQuery['judicialDistricts'] = [];


  @ViewChild('form')
  form: NgForm;


  constructor(
    lawyerComponentService: LawyerComponentGQL,
    private saveLawyer: SaveLawyerGQL,
    private dialog: MatDialogRef<LawyerComponent>,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {


    lawyerComponentService
      .fetch({id: data.id ?? 0})
      .subscribe(s => {
        this.lawyer = s.data.lawyer ?? new Lawyer();
        this.judicialDistricts = s.data.judicialDistricts;
      });


  }


  save(lawyer: LawyerInput) {

    this.saveLawyer
      .mutate({id: this.lawyer.id, lawyer})
      .subscribe(s => {
        this.errors = s.errors;

        if (s.data.saveLawyer)
          this.dialog.close();

      })
  }

  ngAfterViewInit() {

    setTimeout(() => {
      if (!this.auth.lawyers)  this.form.form.disable();
    }, 100);

  }
}
